* {
    margin: 0px;
    padding: 0px;
    border: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

img {
    border: none;
    display: block;
    outline: none;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    outline: none;
    border: none;
}

body:not(.page):not(.single):not(.archive), nav {
    ul, li {
        list-style-type: none;
    } 
}

textarea {
    resize: vertical;
}

header, footer, nav, section, article {
    display: block;
    position: relative;
}

html {
    font-size: 10px;
    overflow-y: auto;
    font-family: 'IBM Plex Sans', sans-serif;
    color: cc_getColor( content, texte );
    font-weight: 300;
    overflow-x: hidden;
    &.modal-open {
        overflow-y: hidden;
    }
}

body {
    line-height: normal;
    width: 100%;
    height: auto;
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

input, textarea, select, option {
    outline: none !important;
}