@media only screen and (max-width: 992px) {
  .text-container {
    &.is-style-intro {
      column-count: 1;
    }
  }
  nav {
    &.row {
      &.small {
        .logo {
          a {
            height: auto;
            width: 180px;
            img {
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }
  }

  .breadcrumb {
    margin: 10px 0 15px 0;
  }
  section {
    &.row h1:not(.wp-block-heading) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  .page:not(.home),
  .blog,
  .category,
  .archive,
  .single-post,
  .single-job_offer {
    h1.main-title:not(.wp-block-heading),
    h1:not(.wp-block-heading) {
      font-size: 38px;
      // small {}
    }
  }
  .page header {
    &.with-figure {
      &::before {
        padding-top: 35%;
      }
    }
  }
  .page header.with-figure + section,
  header.with-figure + #menu-menu-rejoindre + section {
    &.row {
      padding-top: 50px;
    }
  }

  .recherche {
    .col-6.xs-col-12 {
      @media (min-width: 650px) {
        width: 70%;
      }
    }
    form {
      .reset {
        position: relative;
        right: auto;
        &.cta {
          margin: 20px auto 0 auto;
          max-width: fit-content;
          font-size: 14px;
        }
      }
    }
  }

  .cta-service {
    font-size: 17px;
    padding: 20px 70px 20px 28px;
    &:after {
      font-size: 42px;
      right: 14px;
      height: 20px;
      width: 20px;
      padding: 7px 10px 12px;
    }
    &.service--2 {
      max-width: 260px;
      padding: 20px 70px 20px 28px;
    }
  }

  .home,
  .category-actualites,
  .category-news,
  .page-template-expertise,
  .page-template-contact {
    .prllx-v {
      display: none;
    }
  }

  // nav.row .nav {
  // 	width: 500px;
  // 	transform: translate(500px, 0);
  // }

  .single-job_offer {
    section {
      &.row {
        .text-container {
          .bloc-postuler {
            padding: 30px 15px;
          }
          a.is-style-bouton-outline {
            padding: 12px 20px;
          }
        }
      }
    }
  }

  .page-template-expertise {
    #quick-nav {
      margin-bottom: 50px;
      ul {
        margin: 0;
        padding-left: 0 $i;
        justify-content: space-between;
        li {
          margin: 0 0 10px 0;
          text-align: center;
          a {
            font-size: 15px;
            padding: 0;
          }
        }
      }
    }
    &.page section.row {
      .text-container {
        h2:not(.chapeau):not(.is-style-chapeau) {
          font-size: 32px;
          margin-top: 0;
        }
      }
    }
  }
  
	.page-template-rejoindre,
  .post-type-archive-job_offer {
    .main-content {
      padding-top: 0;
      h1 {
        font-size: 55px;
      }
    }
		.join-us {
      .content {
        border-radius: 20px 20px 250px 20px;
        width: 100%;
        .titre {
          font-size: 50px;
        }
        .sous-titre {
          font-size: 28px;
        }
      }
    }
	}

  .post-type-archive-job_offer {
    section {
      &.row {
        &.row-1200 {
          max-width: 80%;
          margin: 0 auto;
        }
        h1 {
          margin-top: 50px;
          margin-bottom: 50px;
        }

        .zone-intro-jobs {
          flex-direction: column;
          aside {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            h2 {
              font-size: 18px;
            }
            @media (min-width: 580px) {
              margin-left: 0;
              max-width: 100%;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              h2 {
                margin: 0 30px 0 0;
              }
              .cta {
                font-size: 13px;
                margin: 0;
              }
            }
          }
        }

        .jobs {
          display: flex;
          flex-wrap: wrap;
          margin-top: 50px;
          margin-bottom: 80px;
        }

        .job {
          margin: 0 0 80px 0;
          // max-width: 350px;
          flex: 1 1 33%;
          gap: 30px;
          margin: 0 22px 30px 22px;

          .text-container {
            width: 100%; //calc(100% / 12 * 7);
            height: 100%;
            padding: 40px; //calc(100% / 12);

            h2 {
              margin: 0 0 15px 0;
              font-size: 2.2rem;
            }

            .cta {
              font-size: 1.4rem;
            }
          }

          .infos {
            .wrapper {
              margin-top: 15px;

              & > div {
                padding: 15px 25px 15px 55px;
                font-size: $sub;

                &::before {
                  top: 50%;
                  transform: translate(0, -50%);
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
          .time {
            time {
              font-size: $sub;
            }
          }
        }
      }
    }
  }

  .footer {
    &.row {
      padding-top: 10px;
      & > div {
        margin-top: 30px !important;
      }

      .nav {
        ul {
          li {
            a {
              padding: 10px 25px;
            }
            &:last-child {
              a {
                padding: 10px 0 10px 25px;
              }
            }
          }
        }
      }
    }
    .prefooter {
      padding-bottom: 80px;
      .intro {
        font-size: 18px;
        padding-left: 30px;
      }
    }
    .social {
      margin-top: 30px;
    }
  }

  .home {
    .bandeau-principal {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .zone-titre {
        height: auto;
        padding: 15px 20px 70px;
        & > div[class^="col-"] {
          margin: 0 auto;
        }
        .titre-principal {
          font-size: 31px;
          line-height: normal;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          br:not(:first-of-type) {
            display: none;
          }
          &:after {
            bottom: -30px;
            width: 80px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 1;
            animation: unset;
          }
          @media (max-width: 550px) {
            font-size: 23px;
          }
        }
      }
      #layers-bp-hp {
        position: relative;
        order: -1;
        width: 100%;
        flex-wrap: wrap;
        .masks-layers {
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          &:after {
            content: $rounded-mask-mobile-allinone;
            position: absolute;
            top: unset;
            bottom: -3px;
            left: 0;
            right: 0;
            height: unset;
            width: 100%;
          }
          &:before {
            display: none;
          }
          #blue-circle-svg,
          #tirets-decoratifs {
            display: none;
          }
        }
        .visuel-bp-hp {
          flex: 1;
          max-height: 45vh;
          overflow: hidden;
          img {
            width: 150%;
            transform: translate(0, -25%);
          }
          &:before {
            background-repeat: no-repeat;
            max-width: 100%;
            right: 50px;
            width: 629px;
            height: 393px;
          }
          &:after {
            content: none;
          }
        }
      }
    }
    section {
      &.services {
        .wrapper {
          bottom: 30px;
          max-width: 290px;
        }
        [class$="-shape"] {
          height: 407px;
          width: 204px;
        }
        .left-shape {
          border-radius: 204px 0 0 204px;
        }
        .right-shape {
          border-radius: 0 204px 204px 0;
        }
        .cta-service.service--1 {
          box-shadow: 3px 20px 20px rgba(0, 0, 0, 0.2);
        }
        .cta-service.service--2 {
          box-shadow: 3px 15px 20px rgba(0, 0, 0, 0.2);
        }
      }
      &.chiffres-cles {
        &::after {
          background-position-y: 50px;
          background-size: 150%;
        }
        h2.titre {
          font-size: 3.3rem;
          margin-bottom: 80px;
        }
        iframe {
          display: block;
          margin: 50px auto 0;
          // max-height: 312px; //!! A ajuster
        }
        .liste-chiffres-cles {
          padding-left: 10px;
          padding-right: 10px;
          p {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.25rem;
          }
        }
      }
      &.actualites {
        // &:after {
        // 	bottom: -76px;
        // 	width: 150px;
        // 	height: 389px;
        // 	background-size: contain;
        //  }
        .slider-actualites {
          .swiper-wrapper {
            justify-content: unset;
            gap: unset;
          }
          .controls {
            .prev,
            .next {
              display: block;
            }
          }
          .swiper-slide {
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            .wrapper {
              margin: 0;
            }
          }
        }
      }
      &.row {
        &.certifications-wrapper {
          padding-top: 65px;
          & > div {
            .title {
              width: 100%;
              margin: 0;
            }
            .text-container {
              width: 100%;
              margin: 40px 0 0 0;
            }
            .full {
              margin: 30px 0 0 0;
              padding: 0 12px 20px;
              display: block;
              .acertification-item {
                width: 45%;
                margin: 0 5% 30px 0;
                &:nth-child(even) {
                  margin: 0 0 30px 5%;
                }
              }
            }
          }
        }
      }
    }
  }

  .post-type-archive-coworker {
    section {
      &.row {
        & > div,
        & > div:not([class*="text-styles"]) {
          margin-bottom: 0;
          text-align: center;

          figure {
            width: calc(100% / 12 * 4);
            max-width: 240px;
            margin: 0 8% 50px !important;
          }
        }
      }
    }
  }

  .block--convictions {
    position: relative;
    padding: 50px 0;
    margin-top: 80px;
    display: block;
    .visuel-container {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      .visuel {
        height: auto;
      }
    }
    .convictions {
      width: 100%;
      .conviction {
        padding: 25px 30px;
        border-radius: 20px;
        .titre {
          font-size: 40px;
        }
      }
    }
    &.position-left {
      .visuel-container {
        border-radius: 250px 30px 30px 30px;
      }
    }
    &.position-right {
      .visuel-container {
        border-radius: 30px 250px 30px 30px;
      }
    }
  }

  .bloc-avantages {
    .avantage {
      width: calc(50% - 15px);
    }
  }

  .block--cards {
    margin-top: 0;
    margin-bottom: 100px;
    .card {
      margin-top: 50px;
      flex-wrap: wrap;
      .slider {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 70px;
        .slider-card {
          border-radius: 250px 50px 50px 50px;
        }
      }
      .content-container {
        width: 100%;
        padding-left: 0;
        .titre {
          text-align: left;
        }
      }
      &:nth-child(even) {
        .slider-card {
          border-radius: 50px 250px 50px 50px;
        }
        .content-container {
          text-align: left;
          padding-right: 0;
          .titre {
            text-align: left;
            &:after {
              left: 0;
              right: inherit;
            }
          }
        }
    
      }
    }
  }
  
  .bloc-mediaTextQuotes {
    .mediaTextQuote {
      flex-wrap: wrap;
      .visuel-container {
        width: 100%;
        margin-bottom: 30px;
      }
      .content-container {
        left: 0;
        width: 100%;
        position: relative;
        transform: inherit$i;
        .content {
          padding: 35px;
          .titre {
            font-size: 45px;
            line-height: 45px;
          }
          .sous-titre {
            font-size: 25px;
          }
          p:not(.titre, .sous-titre) {
            font-size: 18px;
          }
        }
        .quote {
          padding: 30px 30px 30px 100px;
          p {
            font-size: 20px;
          }
        }
      }

      &:nth-child(odd) {
        justify-content: center;
        .visuel-container {
          border-radius: 350px 50px 50px 50px;
        }
        .content-container {
          left: 0;
        }
      }
    }
  }
  
  .block--mosaique {
    .mosaique {
      .tile {
        width: calc(50% - 6px);
        max-width: 350px;
      }
    }
  }
  
  .block--services {
    gap: 40px;
    justify-content: center;
    .services {
      width: calc(50% - 20px);
      border-radius: 20px;
      .title {
        font-size: 22px;
        img {
          max-width: 45px;
        }
      }
      ul li {
        font-size: 20px;
      }
    }
  }

  .block--metier {
    margin-bottom: 50px;
    padding: 0;
    .metier {
      .media-container {
        width: 55%;
        padding: 40px;
        p {
          font-size: 20px;
        }
      }
      .texte-container {
        padding: 50px;
      }
      &.position-right {
        .texte-container {
          padding: 50px;
        }
      } 
    }
  }

  .block--steps {
    display: block;
    .step {
      width: 100%;
      max-width: 400px;
      padding: 70px 30px 30px;
      margin: 0 auto 100px;
      &.full-size {
        width: 100%;
        max-width: 100%;
        padding: 70px 30px 30px;
        margin-bottom: 0;
        &:after {
          content: inherit;
        }
        .content-container {
          display: flex;
          gap: 40px;
          .content {
            width: calc(50% - 20px);
            text-align: left;
          }
        }
      }
      &:before {
        font-size: 45px;
        width: 90px;
        height: 90px;
      }
      .title {
        min-height: inherit;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .titre {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    &.spaced {
      letter-spacing: 5px;
    }
  }
  .text-container {
    &.is-style-intro {
      column-count: 1;
    }
  }

  .breadcrumb {
    margin: 10px 0 15px 0;
  }

  .page:not(.home),
  .blog,
  .category,
  .archive,
  .single-post,
  .single-job_offer {
    h1.main-title:not(.wp-block-heading),
    h1:not(.wp-block-heading) {
      font-size: 38px;
      margin-top: 50px;
      // small {}
    }
  }
  .page,
  .single,
  .post-type-archive-coworker,
  .post-type-archive-job_offer,
  .blog,
  .category,
  .page-template-contact {
    header {
      &.with-figure {
        h1 {
          margin: 0;
        }
        &::before {
          padding-top: 35%;
        }
        .row-breadcrumb {
          display: none;
        }
      }
    }
    header.with-figure + section,
    header.with-figure + #menu-menu-rejoindre + section {
      &.row {
        padding-top: 50px;
      }
    }
    header + section.row h1 {
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }

	.page-template-rejoindre,
  .post-type-archive-job_offer {
		.join-us {
      .content {
        border-radius: 20px 20px 250px 20px;
        width: 100%;
        .titre {
          font-size: 40px;
        }
        .sous-titre {
          font-size: 24px;
        }
      }
    }
    .main-content {
      h1 {
        margin-top: 0;
      }
    }
  
	}

  .cta-service {
    font-size: 17px;
    padding: 20px 70px 20px 28px;
    &:after {
      font-size: 42px;
      right: 14px;
      height: 20px;
      width: 20px;
      padding: 7px 10px 12px;
    }
    &.service--2 {
      max-width: 260px;
      padding: 20px 70px 20px 28px;
    }
  }

  nav {
    &.row {
      .logo {
        margin-left: 0;
        padding: 0;
        a {
          width: 200px;
        }
      }

      .nav {
        width: 100%;
        transform: translate(100%, 0);
        .header-main {
          width: 90%;
        }
        ul {
          margin: 20px 0 0 0;
          & > li {
            a {
              padding: 15px calc(100% / 12 + 12px);
            }

            ul {
              margin: 0 calc(100% / 12 + 12px);
              padding: 0 0 0 30px;
              z-index: 1;
            }
            // & > .conteneur {}
            &.pll-parent-menu-item {
              ul {
                top: 45px;
              }
            }
          }
        }
      }

      .capabilites {
        p {
          font-size: $sub;

          span {
            margin: 0 0 0 25px;
          }
        }
      }

      &.small {
        & + aside.recherche {
          top: 121px;
          height: calc(100vh - 121px);
        }
      }
    }
  }

  .recherche {
    padding-bottom: 50px;

    form {
      margin: 60px 0 50px;

      fieldset {
        input {
          background-position: -12px -143px;
          padding: 15px 15px 15px 45px;
          width: calc(100% - 82px);
        }

        button {
          padding: 18px 15px;
        }

        .autocomplete {
          padding: 20px 20px 0;
          max-height: 180px;

          ul {
            margin: 0 0 20px;

            li {
              margin: 10px 0 0 0;
            }
          }
        }
      }
    }
  }

  aside {
    &.recherche {
      top: 121px;
      height: calc(100vh - 121px);
      padding-top: 0;
      overflow-y: auto;
      background: cc_getColor(background, fonce);

      & > div {
        overflow: hidden;
        padding-top: 40px;
        height: 100%;

        &.close {
          padding-top: 0;
        }
      }
    }
  }

  // ?? Texte styles
  // Commun Ã  tous les styles de colonnes
  .wp-block-columns {
    flex-wrap: wrap $i;
    .wp-block-column {
      flex-basis: 100% !important;
      margin: 0 auto;
      &:first-of-type {
        margin-top: 15px;
      }
    }
  }
  // bloc-contenu (Gutenberg + ACF)
  .block--bloc-contenu {
    margin-bottom: 30px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  // bloc accordeon (Gutenberg + ACF)
  .block--accordeon {
    margin-top: 30px;
    margin-bottom: 30px;
    .zone-titre {
      margin-top: 20px;
      //   border-bottom: 1px solid lightgray;
    }
    .collapse-button {
      padding: 15px 25px;
      .navbar-toggler {
        svg {
          transform: translate(3px, 0) rotate(-90deg);
        }
      }
      &.active {
        & > .navbar-toggler svg {
          transform: translate(2px, -2px) rotate(0deg);
        }
      }
    }
    .collapse-content {
      flex-wrap: wrap;
      flex-direction: column;
      .colmun-text {
        width: calc(100% - 60px);
        padding: 15px 0 20px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 20px;
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        &:last-of-type {
          border-right: none;
          border-bottom: none;
        }
      }
    }

    .accordion-body {
      h3 {
        font-size: 16px $i;
      }
    }
  }

  .block--convictions {
    margin-top: 50px;
    padding: 0;
    &.position-left {
      .visuel-container {
        border-radius: 200px 30px 30px 30px;
      }
    }
    &.position-right {
      .visuel-container {
        border-radius: 30px 200px 30px 30px;
      }
    }
    .visuel-container {
      .prllx-v {
        width: 200px;
      }
    }
  }

  .block--esprits {
    gap: 20px;
    .esprit {
      width: 100%;
    }
  }

  .bloc-avantages {
    justify-content: center;
    .avantage {
      width: 100%;
      max-width: 350px;
    }
  }

  .block--mosaique {
    .mosaique {
      .tile {
        width: 100%;
        max-width: 350px;
        .titre p {
          font-size: 20px;
        }
      }
    }
  }

  .block--metier {
    margin-bottom: 50px;
    padding: 0;
    .metier {
      flex-wrap: wrap;
      .media-container {
        width: 100%;
        padding: 40px;
        border-radius: 20px 20px 0 0;
        p {
          font-size: 20px;
        }
      }
      .texte-container {
        width: 100%;
        padding: 40px;
        border-radius: 0 0 20px 20px;
        border-left: 1px solid cc_getColor(gris_300);
        border-top: 0;
      }
      &.position-right {
        .media-container {
          border-radius: 20px 20px 0 0;
        }
        .texte-container {
          border-radius: 0 0 20px 20px;
          padding: 40px;
          border-right: 1px solid cc_getColor(gris_300);
        }
      } 
    }
  }
  
  .block--steps {
    display: block;
    .step {
      padding: 80px 25px 30px;
      &.full-size {
        padding: 80px 25px 30px;
        .title {
          text-align: center;
        }
        .content-container {
          display: block;
          .content {
            width: 100%;
            text-align: center;
            font-size: 18px;
          }
        }
      }
      .title {
        font-size: 30px;
        line-height: 32px;
      }
      .content-container {
        .content {
          font-size: 18px;
        }
      }
    }
  }

  .footer {
    &.row {
      padding-top: 10px;

      & > div {
        margin-top: 30px !important;
      }

      .nav {
        ul {
          li {
            a {
              padding: 10px 25px;
            }

            &:last-child {
              a {
                padding: 10px 0 10px 25px;
              }
            }
          }
        }
      }
    }
    .prefooter {
      padding-bottom: 80px;
      .intro {
        display: block;
        width: 80%;
        margin: 0 auto;
        p {
          font-size: 16px;
        }
      }
    }
    .social {
      margin-top: 30px;
    }
  }

  .home {
    .bandeau-principal {
      .zone-titre {
        height: auto;
        padding: 15px 20px 70px;
        .titre-principal {
          font-size: 31px;
          &:after {
            bottom: -30px;
            width: 80px;
            left: 50%;
            transform: translateX(-50%);
          }
          @media (max-width: 550px) {
            font-size: 23px;
          }
        }
      }
      #layers-bp-hp {
        .visuel-bp-hp {
          max-height: 35vh;
        }
        .masks-layers:after {
          bottom: -5px;
        }
      }
    }
    section {
      &.services {
        .wrapper {
          bottom: 30px;
          max-width: 250px; //!! 12345
          &.left-cta {
            left: 25px;
            transform: translateX(0);
          }
          &.right-cta {
            right: 25px;
            transform: translateX(0);
          }
        }
        [class$="-shape"] {
          height: 407px;
          width: 204px;
        }
        .left-shape {
          border-radius: 204px 0 0 204px;
        }
        .right-shape {
          border-radius: 0 204px 204px 0;
        }
        .cta-service.service--1 {
          box-shadow: 3px 20px 20px rgba(0, 0, 0, 0.2);
        }
        .cta-service.service--2 {
          box-shadow: 3px 15px 20px rgba(0, 0, 0, 0.2);
        }
      }
      &.chiffres-cles {
        padding-top: 50px;
        padding-bottom: 50px;
        &::after {
          background-position-y: 50px;
        }
        h2.titre {
          font-size: 3.3rem;
          margin-bottom: 50px;
        }
        iframe {
          display: block;
          margin: 50px auto 0;
          max-height: 312px; //!! A ajuster
        }
        .liste-chiffres-cles {
          @media (max-width: 580px) {
            p {
              width: 100%;
              span.chiffre {
                font-size: 32px;
              }
              span.texte {
                font-size: 26px;
                line-height: 3rem;
              }
            }
          }
        }
      }
      &.actualites {
        // &:after {
        // 	bottom: -76px;
        // 	width: 150px;
        // 	height: 389px;
        // 	background-size: contain;
        //  }
        .slider-actualites {
          .controls {
            .prev {
              left: 25%;
            }
            .next {
              left: 75%;
            }
          }
          .wrapper .content .text-container h3 {
            font-size: 1.8rem;
            line-height: normal;
          }
          .swiper-wrapper {
            justify-content: unset;
            gap: unset;
          }
          .swiper-slide {
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            .wrapper {
              margin: 0;
            }
          }
        }
      }
      &.row {
        &.certifications-wrapper {
          padding-top: 65px;
          & > div {
            .title {
              width: 100%;
              margin: 0;
            }
            .text-container {
              width: 100%;
              margin: 40px 0 0 0;
            }
            .certification-item img {
              max-width: 80%;
              text-align: center;
              margin: 0 auto;
            }
            .full {
              margin: 30px 0 0 0;
              padding: 0 12px 20px;
              display: block;
              .acertification-item {
                width: 45%;
                margin: 0 5% 30px 0;
                &:nth-child(even) {
                  margin: 0 0 30px 5%;
                }
              }
            }
          }
        }
        &.temoignages {
          padding-top: 80px;

          figure {
            left: 80%;
            width: 20vw;
          }

          & > div {
            h2 {
              width: 70%;
            }

            .slider-wrapper {
              .slider-temoignages {
                .controls {
                  width: 15%;
                }
              }
            }
          }
        }
      }
    }
  }

  .page,
  .single-post,
  .single-job_offer {
    section {
      &.row {
        .text-container {
          h2,
          h3 {
            width: 100%;
            &.is-style-design-heading {
              font-size: 45px;
            }
          }

          h4 {
            padding: 3px 20px;
          }

          ul,
          ol {
            // padding: 30px 50px;

            li {
              &::before {
                left: -28px;
              }
            }
          }

          .bloc-postuler {
            margin: 0 15px 40px 30px;
            padding: 30px 50px;
            p {
              font-size: 18px;
            }
          }
          .details-job {
            margin: 0 15px 40px 30px;

            .content {
              padding: 30px;
            }
            .content > div {
              display: block;
              padding: 15px 25px 15px 55px;
              margin-bottom: 15px;
            }
          }

          .social-wrapper {
            h3 {
              width: 100%;
            }

            .links {
              width: 100%;
              text-align: left;
              margin: 0 0 20px 0;
            }
          }
        }

        .wp-block-media-text {
          figure,
          .wp-block-media-text__content {
            width: 100%;
          }

          figure {
            margin: 0 0 30px 0;

            &::before {
              width: 50vw;
              right: 50%;
            }
          }

          &.has-media-on-the-right {
            figure {
              &::before {
                left: 50%;
              }
            }
          }
        }

        .figures-container {
          padding: 0;

          figure,
          .text-container {
            width: 100%;
          }
        }

        .video-container {
          margin: 0 calc(-10% - 14px);
        }

        .certifications-container {
          .full {
            padding: 0 0 70px 0;
            display: block;
            margin: 60px 0 0;

            a {
              width: 45%;
              margin: 0 2% 20px;
            }
          }
        }

        .chiffres-cles-container {
          margin-top: 80px;
          &.chiffres-cles-styles {
            padding-top: 15px;
            padding-bottom: 15px;
            // iframe {
            // 	display: block;
            // 	margin: 50px auto 0;
            // 	max-height: 312px; //!! A ajuster
            // }
            .liste-chiffres-cles {
              padding-right: 0;
            }
          }
        }

        .timeline-container {
          .slider-wrapper {
            .swiper-container {
              .controls {
                .prev {
                  left: 0;
                }

                .next {
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .post-type-archive-coworker {
    header {
      &.row {
        h1 {
          width: 100%;
        }
      }
    }

    section {
      &.row {
        & > div,
        & > div:not([class*="text-styles"]) {
          figure {
            width: calc(100% / 12 * 4);
            margin-bottom: 40px !important;
            max-width: 100%;
            figcaption {
              font-size: 1.7rem;
              span {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
  }

  .page-template-metiers {
    header {
      &.row {
        .title {
          margin: 0 calc(100% / 12 * 0.5);
          width: calc(100% / 12 * 11);
        }
      }
    }

    section {
      &.row {
        padding-top: 40px;

        .pictos {
          display: block;
          margin-top: 0;
          padding: 0;

          figure {
            width: 45%;
            margin: 50px 4% 0 0 !important;

            &:nth-child(even) {
              margin: 50px 0 0 4% !important;
            }
          }
        }

        .push {
          padding: 50px 0 0 0;

          .text-container {
            width: 100%;
            margin: 0 0 30px 0;
          }

          .img {
            position: relative;
            left: calc(-10% - 13px);
            top: auto;
            height: auto;
            width: 100vw;

            &::before {
              content: none;
            }

            img {
              position: relative;
            }
          }
        }

        .clients {
          h2 {
            padding: 50px 0;
          }

          .clients-wrapper {
            padding: 30px 0 20px 0;

            a {
              width: 40%;
              margin: 0 5% 40px 5% !important;
            }
          }
        }
      }
    }
  }

  .page-template-expertise {
    // #quick-nav {
    // 	margin-bottom: 50px;
    // 	ul {
    // 		margin: 0; padding-left: 0 $i;
    // 		justify-content: space-between;
    // 		li {
    // 			margin: 0 0 10px 0;
    // 			text-align: center;
    // 			a {
    // 				font-size: 15px;
    // 				padding: 0;
    // 			}
    // 		}
    // 	}
    // }
    &.page section.row {
      .text-container {
        h2:not(.chapeau):not(.is-style-chapeau) {
          font-size: 30px;
          margin-top: 0;
        }
      }
    }
  }

  .blog,
  .category {
    header {
      &.row {
        padding-bottom: 50px;
        h1 {
          margin-top: 30px;
        }
      }
    }

    section {
      &.row {
        > div {
          .news {
            width: 100%;
            flex: 1 1 100%;
            margin: 0 auto 30px;

            .img {
              position: relative;
              width: 100%;
              height: auto;

              &::before {
                content: "";
                display: block;
                padding-top: 50%;
              }
            }

            .text-container {
              width: 100%;
              margin: 0;
              background: cc_getColor(blanc);
            }
          }
        }
      }
    }
  }

  .post-type-archive-event {
    section {
      &.row {
        .event {
          .img {
            position: relative;
            width: 100%;
            height: auto;

            &::before {
              content: "";
              display: block;
              padding-top: 50%;
            }
          }

          .text-container {
            width: 100%;
            margin: 0;
            background: cc_getColor(blanc);
          }
        }
      }
    }
  }

  .post-type-archive-job_offer {
    section {
      &.row {
        &.row-1200 {
          max-width: 100%;
          margin: 0 auto;
        }
        h1 {
          margin-top: 50px;
          margin-bottom: 50px;
        }

        .zone-intro-jobs {
          flex-direction: column;
          padding-left: 20px;
          padding-right: 20px;
          aside {
            margin-top: 50px;
            h2 {
              font-size: 17px;
            }
          }
        }

        .jobs {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          flex-direction: column;
          margin-top: 20px;
          margin-bottom: 50px;
        }

        .job {
          flex: 1 1 100%;
          margin: 20px 0 20px;

          .text-container {
            width: 100%;
            height: 100%;
            padding: 40px;

            h2 {
              margin: 0 0 15px 0;
              font-size: 2.2rem;
            }

            .cta {
              font-size: 1.4rem;
            }
          }
        }
        .pagination {
          margin: 50px 0;
        }
      }
    }
  }

  .page-template-contact {
    section {
      &.row {
        .form {
          fieldset {
            label {
              margin: 15px 0 10px 0;
            }
          }
        }

        .map-container {
          .address {
            padding: 60px 20px;

            h2,
            .wrapper {
              width: 100%;
            }

            h2 {
              margin: 0 0 20px 0;
            }
          }
        }
      }
    }
  }

  .single-job_offer {
    section {
      &.row {
        padding-top: 15px; //60px;
        .zone-header {
          order: 1;
        }
        .zone-main-content {
          order: 3;
        }
        .zone-blocs-de-droite {
          padding: 0 15px;
          margin-bottom: 20px;
          order: 2;
        }
        .zone-candidature {
          order: 4;
        }
        .text-container {
          .bloc-postuler {
            margin-left: auto;
            margin-right: auto;
          }
          .details-job {
            float: none;
            width: 100%;
            margin: 0 auto 30px;

            .content {
              padding: 25px 35px;
            }
          }
          a.is-style-bouton-outline {
            padding: 12px 15px;
          }

          .social-wrapper {
            h3,
            .links {
              width: 100%;
            }

            .links {
              text-align: left;
              margin: 0 0 30px 0;
            }
          }
        }
      }
    }
  }

  .post-type-archive-capability {
    section {
      &.row {
        &.recherche {
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .breadcrumb {
    display: none;
  }
  .page:not(.home),
  .blog,
  .category,
  .archive,
  .single-post,
  .single-job_offer {
    h1.main-title:not(.wp-block-heading),
    h1:not(.wp-block-heading) {
      font-size: 35px;
      // small {}
    }
  }
  .page header {
    &.with-figure {
      &::before {
        padding-top: 40%;
      }
    }
  }

	.page-template-rejoindre,
  .post-type-archive-job_offer {
    header {
      &.with-figure {
        min-height: 400px;
      }
    }
		.join-us {
      .content {
        border-radius: 20px 20px 200px 20px;
        width: 100%;
        .titre {
          font-size: 36px;
          line-height: 50px;
        }
        .sous-titre {
          font-size: 24px;
        }
        .cta-rounded {
          font-size: 18px;
          .icon {
            width: 45px;
            height: 45px;
            svg {
              width: 20px;
            }
          }
        }

      }
    }
	}

  section {
    &.row h1 {
      margin-top: 30px;
    }
    &.services {
      .wrapper {
        max-width: 220px;
        &.left-cta {
          left: 5px;
        }
        &.right-cta {
          right: 5px;
        }
        @media (max-width: 550px) {
          &.left-cta {
            left: 0;
            top: 25px;
          }
          &.right-cta {
            right: 0;
          }
        }
      }
    }
  }
  /*.page header.with-figure + section {
        &.row {
            padding-top: 50px;
        }
    }
	.page header + section.row h1 {
		margin-top: 20px;
		margin-bottom: 50px;
	}*/

  .page-template-expertise {
    #quick-nav {
      display: none;
      ul {
        // justify-content: center;
        // flex-direction: column;
      }
    }
    &.page section.row {
      .text-container {
        h2:not(.chapeau):not(.is-style-chapeau) {
          font-size: 28px;
        }
      }
    }
  }

  .page,
  .single {
    section.row .text-container {
      iframe {
        max-height: 220px;
      }
    }
  }
  .post-type-archive-coworker {
    section {
      &.row {
        & > div,
        & > div:not([class*="text-styles"]) {
          figure {
            width: calc(100% / 12 * 5);
            margin-bottom: 40px !important;
          }
        }
      }
    }
  }
  
	.block--convictions {
		&.position-left {
			.visuel-container {
				border-radius: 150px 30px 30px 30px;
			}
		}
		&.position-right {
			.visuel-container {
				border-radius: 30px 150px 30px 30px;
			}
		}
		.visuel-container {
			.prllx-v {
				width: 150px;
			}
		}
	}
  
  .block--services {
    gap: 20px;
    justify-content: center;
    .services {
      width: 100%;
      border-radius: 20px;
      max-width: 300px;
      .title {
        font-size: 20px;
        padding-right: 60px;
      }
      ul li {
        font-size: 18px;
      }
    }
  }
  
  .block--metier {
    margin-bottom: 50px;
    padding: 0;
    .metier {
      flex-wrap: wrap;
      .media-container {
        padding: 30px;
        p {
          font-size: 20px;
        }
      }
      .texte-container {
        padding: 30px;
        .title {
          font-size: 26px;
        }
  
      }
      &.position-right {
        .texte-container {
          padding: 30px;
        }
      } 
    }
  }
}

@media only screen and (max-width: 480px) {
  .titre {
    font-size: 2rem;
    margin-bottom: 2rem;
    &.spaced {
      letter-spacing: 5px;
    }
  }
  .page:not(.home),
  .blog,
  .category,
  .archive,
  .single-post,
  .single-job_offer {
    h1.main-title:not(.wp-block-heading),
    h1:not(.wp-block-heading) {
      font-size: 30px;
      // small {}
    }
  }
  .page header {
    &.with-figure {
      h1 {
        margin: 0;
      }
      &::before {
        padding-top: 45%;
      }
    }
  }

  .recherche form {
    fieldset {
      input {
        padding: 14px 14px 14px 45px;
        width: calc(100% - 72px);
      }
      button {
        padding: 17px 15px;
        padding: 16.5px 15px;
      }
    }
    .reset {
      &.cta {
        font-size: 13px;
        padding: 12px 15px 12px 45px;
        &:before {
          top: 10px;
          left: 17px;
        }
      }
    }
  }

  .cta-service {
    font-size: 17px;
    padding: 20px 70px 20px 28px;
    &:after {
      font-size: 42px;
      right: 14px;
      height: 20px;
      width: 20px;
      padding: 7px 10px 12px;
    }
    &.service--2 {
      max-width: 260px;
      padding: 20px 70px 20px 28px;
    }
  }

  .page,
  .single-post,
  .single-job_offer {
    section {
      &.row {
        .chiffres-cles-container {
          &.chiffres-cles-styles {
            margin-top: 50px;
            &:after {
              background-image: unset;
            }
            .liste-chiffres-cles {
              p {
                width: 100%;
                padding: 0;
                margin: 0 0 30px 0;
                span.chiffre {
                  font-size: 32px;
                }
                span.texte {
                  font-size: 26px;
                  line-height: 3rem;
                }
                br {
                  display: none;
                }
              }
            }
          }
          .timeline {
            margin-top: 50px;
          }
        }
      }
    }
  }

  .post-type-archive-job_offer {
    section {
      &.row {
        h1 {
          margin-top: 60px;
          margin-bottom: 40px;
        }

        .zone-intro-jobs {
          padding-left: 5px;
          padding-right: 5px;
          aside {
            margin-top: 50px;
            h2 {
              font-size: 17px;
            }
          }
        }

        .jobs {
          margin-top: 20px;
          margin-bottom: 50px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .job {
          max-width: 350px;
          margin-left: auto;
          margin-right: auto;
          .text-container {
            padding: 28px;
          }
          .infos .wrapper > div {
            padding: 15px 25px 15px 48px;
          }
        }
      }
    }
  }

	.page-template-rejoindre,
  .post-type-archive-job_offer {
    header {
      &.with-figure {
        .absolute-title {
          top: 70%;
          p,
          h1 {
            font-size: 50px;
            line-height: 1.1;
          }
        }
      }
    }
		.join-us {
      .content {
        padding: 35px;
        border-radius: 20px 20px 100px 20px;
      }
    }
	}
  
  .bloc-mediaTextQuotes {
    .mediaTextQuote {
      .visuel-container {
        width: 100%;
        border-radius: 200px 50px 50px 50px;
      }
      .content-container {
        .content {
          padding: 35px;
          .titre {
            font-size: 40px;
            line-height: 40px;
          }
          .sous-titre {
            font-size: 22px;
          }
          p:not(.titre, .sous-titre) {
            font-size: 18px;
          }
        }
        .quote {
          padding: 30px 30px 30px 70px;
          p {
            font-size: 18px;
          }
          img {
            max-width: 25px;
          }
        }
      }
      &:nth-child(odd) {
        .visuel-container {
          border-radius: 200px 50px 50px 50px;
        }
        .content-container {
          right: 0;
        }
      }
    }
  }

  .home {
    /*.bandeau-principal {
			height: calc(100vh - 100px);
			.zone-titre {
				padding-bottom: 180px;
				.titre-principal {
					font-size: 24px;
					padding-left: 5px;
					padding-right: 80px;
					// font-size: 30px;
					// line-height: normal;
					// padding-left: 30px;
					// padding-right: 100px;
					&:after {
						bottom: -30px;
						width: 80px;
					}
				}
			}
			#layers-bp-hp {
				width: 180%;
				.masks-layers {
					flex: 1;
					#mask-bp {
						height: 100%;
						width: 215px;
						right: -109px;
					}
					&:before {
						bottom: 35px;
						background-size: contain;
						background-position: unset;
						height: 135px;
						width: 130px;
					}
					#blue-circle-svg {
						top: 45px;
						right: -45px;
						width: 71px;
						height: auto;
						// display: none;
					}
					#tirets-decoratifs {
						right: -55px;
						width: 50%;
					}
				}
				.visuel-bp-hp {
					flex: 1;
					img { max-width: 150%; width: 130%; transform: translateX(-50px); }
					&:before {
						background-repeat: no-repeat;
						max-width: 100%;
						// left: 50%; transform: translateX(-50%); // !! responsive ?
						right: 50px;
						width: 629px;
						height: 393px;
					}
				}
			}
		}*/
    .bandeau-principal {
      .zone-titre {
        padding: 15px 10px 70px;
        .titre-principal {
          font-size: 21px;
          &:after {
            content: $rounded-mask-mobile;
            bottom: -30px;
          }
        }
      }
      #layers-bp-hp {
        position: relative;
        order: -1;
        width: 100%;
        flex-wrap: wrap;
        .masks-layers {
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          &:after {
            content: $rounded-mask-mobile;
            position: absolute;
            top: unset;
            bottom: -8px;
            left: 0;
            right: 0;
            height: unset;
            width: 100%;
          }
          &:before {
            display: none;
          }
          #blue-circle-svg {
            display: block;
            top: unset;
            bottom: 10px;
            right: 10px;
            width: 50px;
          }
          #tirets-decoratifs {
            display: block;
            right: unset;
            width: 120px;
            bottom: -20px;
            left: 3px;
          }
        }
        .visuel-bp-hp {
          flex: 1;
          max-height: 35vh;
          overflow: hidden;
          img {
            width: 150%;
            transform: translate(0, -25%);
          }
          &:before {
            background-repeat: no-repeat;
            max-width: 100%;
            right: 50px;
            width: 629px;
            height: 393px;
          }
          &:after {
            content: none;
          }
        }
      }
    }
    section {
      &.services {
        .left-side,
        .right-side {
          max-width: 50%;
        }
        .wrapper {
          bottom: 25px;
          max-width: 220px; //!! 12345
          &.left-cta {
            left: 0;
            top: 25px;
          }
          &.right-cta {
            right: 0;
          }
        }
        [class$="-shape"] {
          height: 290px;
          width: 150px;
        }
        .left-shape {
          border-radius: 204px 0 0 204px;
          margin-bottom: 20px;
        }
        .right-shape {
          border-radius: 0 204px 204px 0;
          margin-top: 20px;
        }
      }
      &.chiffres-cles {
        padding-top: 50px;
        padding-bottom: 50px;
        &::after {
          background-image: unset;
        }
        h2.titre {
          font-size: 2.25rem;
          margin-bottom: 50px;
        }
        iframe {
          margin: 30px auto 0;
          max-height: 200px;
        }
        &.chiffres-cles-styles .liste-chiffres-cles {
          padding-left: 20px;
          padding-right: 20px;
          p {
            margin-top: 0;
            margin-bottom: 30px;
            line-height: normal;
            text-shadow: unset;
            span.chiffre {
              font-size: 30px;
              display: block; /*margin-right: 10px;*/
            }
            span.texte {
              font-size: 25px;
              display: inline-block;
              line-height: 3rem;
            }
            &.left {
              padding-right: 0;
            }
            &.right {
              padding-left: 0;
            }
            br {
              display: none;
            }
          }
        }
      }
      &.actualites {
        // &:after { content: none; }
        .slider-actualites {
          margin-bottom: 20px;
          .wrapper {
            max-width: 100%;
            margin: 0;
            .content .text-container h3 {
              font-size: 1.75rem;
            }
          }
          .swiper-wrapper {
            justify-content: unset;
            gap: unset;
          }
          .controls {
            .prev {
              left: 10%;
            }
            .next {
              left: 85%;
              margin-top: 10px;
            }
          }
          .swiper-slide {
            padding: 0 5px;
            margin-left: 0;
            margin-right: 0;
          }
        }
        hr.separator {
          margin-top: 50px;
        }
      }
    }
  }
  .footer {
    .prefooter {
      .intro {
        width: 100%;
        text-align: center;
        padding-left: 0;
      }
      .intro p {
        font-size: 15px;
      }
      &:after {
        background-image: unset;
      }
    }
  }
}
