.page-template-expertise {
    #quick-nav {
        margin-bottom: 80px;
        ul {
            padding: 0; margin: 0;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-around;
            li {
                margin-left: 30px;
                margin-right: 30px;
                a {
                    color: cc_getColor(content, texte);
                    text-transform: uppercase;
                    font-weight: 300;
                    font-size: 19px;
                    letter-spacing: 1px;
                    border-radius: 30px;
                    transition: $trans;
                    padding: 12px 20px;
                }
            }
        }
    }
    &.page section.row {
        .text-container {
            h2:not(.chapeau):not(.is-style-chapeau) {
               font-size: 38px; 
            }
            
        }
    }
    .content-zone {
        position: relative;
        &.row {
            max-width: unset;
            padding: 0;
        }
    }
    .prllx-v {
        z-index: -1;
        &.deco-top {
            right: -80px;
            top: 5%;
        }
        &.deco-mid {
            left: 0;
            bottom: 25%;
        }
    }
}