.page-template-metiers {

    header {

        &.row {
            padding-top: 0;
            padding-bottom: 0;

            &::before {
                content: '';
                display: block;
                padding-top: 35%; 
            }

            figure {
                position: absolute;
                left: 50%;
                top: 0;
                height: 100%;
                width: 100vw;
                transform: translate(-50%, 0);

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                }
            }

            .breadcrumb {
                position: absolute;
                left: 6px;
                top: 0;
            }

            .title {
                position: relative;
                z-index: 1;
                background: cc_getColor( blanc );

                h1 {
                    text-transform: uppercase;
                    margin: 40px 0 40px 20%;
                }
            }
        }
    }

    section {

        &.row {
            padding-top: 135px;

            .pictos {
                font-size: 0;
                margin: 100px calc(-100% / 8) 0;
                display: flex;
                justify-content: space-around;
                position: relative;
                padding: 0 0 60px 0;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    height: 100px;
                    width: 100vw;
                    transform: translate(-50%, 0);
                    background: cc_getColor( background, fonce );
                    z-index: -1;
                }

                figure {
                    display: inline-block;
                    vertical-align: top;
                    width: 18%;
                    margin: 0 0 0 9.3%;

                    &:first-child {
                        margin: 0;
                    }

                    figcaption {
                        text-align: center;
                        font-weight: 500;
                        font-size: $sub;
                        letter-spacing: .7px;
                        line-height: 1.43em;
                        margin: 0 0 40px 0;
                    }

                    & > div {
                        background: cc_getColor( blanc );
                        border-radius: 50%;
                        box-shadow: -2px 6px 17px 0 rgba(15, 38, 92, 0.15);
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            padding-top: 100%;
                        }

                        img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            max-height: 55%;
                            max-width: 55%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            .savoir-faire {
                @extend .background-fonce;

                h2 {
                    @extend .outline;
                    padding: 100px 0;
                    font-family: $serif;
                }

                .savoir-container {
                    font-size: 0;
                    position: relative;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, 0);
                    width: 100vw;

                    .savoir {
                        display: inline-block;
                        vertical-align: top;
                        width: 50%;
                        position: relative;
                        overflow: hidden;

                        &::before {
                            content: '';
                            display: block;
                            padding-top: 63%;
                        }

                        .wrapper {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            padding: 120px calc(100% / 6);
                            width: 100%;
                            z-index: 1;

                            .cpt {
                                font-weight: 200;
                                font-size: $paragraphe;
                                color: cc_getColor( blanc );
                                letter-spacing: 4px;
                            }

                            h3 {
                                font-size: $push;
                                letter-spacing: 1.1px;
                                color: cc_getColor( blanc );
                                font-weight: 300;
                                margin: 0 0 30px 0;
                                cursor: pointer;
                            }

                            .more {
                                width: 40px;
                                height: 40px;
                                position: relative;
                                margin: 0 0 0 -10px;
                                cursor: pointer;

                                &::before {
                                    content: '';
                                    display: block;
                                    border: 1px solid cc_getColor( blanc );
                                    border-radius: 50%;
                                    height: 20px;
                                    width: 20px;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                &::after {
                                    content: '';
                                    display: block;
                                    border-bottom: 1px solid cc_getColor( blanc );
                                    border-right: 1px solid cc_getColor( blanc );
                                    height: 5px;
                                    width: 5px;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -70%) rotate(45deg);
                                }
                            }
                        }

                        .img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 50vw;
                            z-index: 0;

                            img {
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                font-family: 'object-fit: cover;';
                                transition: transform .5s ease-out;
                                transform: scale(1);
                            }
                        }

                        &:first-child {

                            .img {
                                left: auto;
                                right: 0;
                            }
                        }

                        &.active {

                            .wrapper {

                                .more {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        &:not(.active) {

                            .wrapper {

                                .more {
                                    animation: scrollDownTwo 6s linear infinite;

                                    @keyframes scrollDownTwo {
                                        0% {
                                            transform: translate(0, 0);
                                        }
                                        80% {
                                            transform: translate(0, 0);
                                        }
                                        85% {
                                            transform: translate(0, 4px);
                                        }
                                        90% {
                                            transform: translate(0, 0);
                                        }
                                        95% {
                                            transform: translate(0, 4px);
                                        }
                                        100% {
                                            transform: translate(0, 0);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .savoir:first-child:nth-last-child(3),
                    .savoir:first-child:nth-last-child(3) ~ .savoir {
                        width: calc(100% / 3);

                        &::before {
                            padding-top: 94.5%;
                        }
                    }
                }

                .savoir-content {
                    color: cc_getColor( blanc );
                    padding: 80px 10% 50px;
                    display: none;

                    & > * {
                        opacity: 0;
                        transform: translate(0, 50px);
                        transition: all 1.3s cubic-bezier(0.27, 0.91, 0, 1);

                        &.visible {
                            opacity: 1;
                            transform: translate(0, 0);
                        }
                    }

                    h2 {
                        padding: 0;
                        text-align: left;
                        font-family: inherit;
                        position: relative;
                        width: 100%;
                        margin: 0 0 50px 0;
                        font-weight: 300;
                                
                        .cpt {
                            position: absolute;
                            left: calc(-100% / 8);
                            top: 3px;
                            font-weight: 200;
                            font-family: $serif;
                            font-size: $paragraphe;
                        }
                    }

                    h3 {
                        background: cc_getColor( blanc );
                        color: cc_getColor( content, texte );
                        padding: 15px;
                        text-decoration: none;
                        font-weight: 500;
                        text-align: center;
                        margin: 60px calc(-100% / 8) 10px;
                        width: auto;
                    }

                    h4 {
                        font-size: $paragraphe;
                        font-weight: 500;
                        line-height: 1.63em;
                        letter-spacing: 0.8px;
                        border-bottom: 1px solid cc_getColor( blanc );
                        padding: 10px;
                        text-transform: none;
                        width: 100%;
                        margin: 30px 0 0 0;
                    }

                    ul {
                        background: transparent;
                        box-shadow: none;
                        // margin: 0 calc(-100% / 8);
                        margin: 0;
                        padding: 15px 0 30px;

                        &::after {
                            content: none;
                        }

                        li {
                            display: inline-block;
                            vertical-align: top;
                            width: 49%;
                            margin: 15px 0 0 0;
                            padding: 0 0 0 30px;

                            &::before {
                                background: cc_getColor( blanc );
                                left: 5px;
                            }
                        }
                    }

                    .accordion {

                        h4 {
                            cursor: pointer;
                            position: relative;

                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                right: 10px;
                                transform: translate(0, -50%) rotate(45deg);
                                height: 7px;
                                width: 7px;
                                border-right: 1px solid cc_getColor( blanc );
                                border-bottom: 1px solid cc_getColor( blanc );
                            }

                            & + ul {
                                display: none;
                            }

                            &.open {

                                &::after {
                                    transform: translate(0, -50%) rotate(-135deg);
                                }

                                & + ul {
                                    display: block;
                                }
                            }

                            &:only-child {

                                &::after {
                                    content: none;
                                }
                            }
                        }
                        
                        ul {
                            // padding: 15px 25% 30px;
                            margin: 0;
                            padding: 15px 0 30px;
                        }
                    }

                    table {
                        background: cc_getColor( blanc );
                        border-collapse: collapse;
                        border-spacing: 0;
                        box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, .2);
                        margin: 30px 0 0 0;
                        width: 100%;
                        color: cc_getColor( content, texte );

                        tr {

                            &:nth-child(odd) {
                                background: cc_getColor( background, soft );
                            }

                            td {
                                text-align: center;
                                padding: 25px 10px;
                                font-size: $sub;
                                position: relative;

                                &:not(:first-child) {

                                    &::before {
                                        content: '';
                                        display: block;
                                        width: 1px;
                                        height: calc(100% - 20px);
                                        position: absolute;
                                        left: 0;
                                        top: 10px;
                                        background: cc_getColor( background, clair );
                                    }
                                }
                            }
                        }

                        thead {
                            box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, .2);

                            tr {
                                background: cc_getColor( blanc ) !important;

                                th, td {
                                    padding: 10px;
                                    font-weight: 300;
                                    font-size: $paragraphe;
                                    letter-spacing: .8px;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .slider-savoir-detail {
                        margin: 30px 0 0 0;

                        .swiper-pagination-bullet {
                            background: transparent;
                            border: 1px solid cc_getColor( blanc );
                            opacity: 1;

                            &.swiper-pagination-bullet-active {
                                background: cc_getColor( blanc );
                            }
                        }
                    }

                    figure {
                        position: relative;
                        z-index: 1;
                        width: 100% !important;

                        &::before {
                            content: '';
                            display: block;
                            padding-top: 65%;
                        }

                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            font-family: 'object-fit: cover;';
                        }

                        figcaption {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 100vw;
                            transform: translate(-50%, -50%);
                            z-index: -1;
                            opacity: .5;
                            white-space: nowrap;
                            text-align: center;

                            span {
                                display: inline-block;
                                vertical-align: top;

                                &::before, &::after {
                                    content: attr(data-text);
                                    display: inline-block;
                                    vertical-align: top;
                                    font-size: $titre1;
                                    font-family: $serif;
                                    font-weight: 700;
                                    padding: 0 85px 0 0;
                                    line-height: 1em;
                                    color: cc_getColor( background, fonce );
                                    text-shadow: -.5px -.5px 0 cc_getColor( blanc ), .5px -.5px 0 cc_getColor( blanc ), -.5px .5px 0 cc_getColor( blanc ), .5px .5px 0 cc_getColor( blanc );
                                    letter-spacing: 6px;
                                }

                                &::after {
                                    left: 30%;
                                }
                            }
                        }
                    }

                    .up {
                        margin: 85px 0 0 0;
                        font-size: $label;
                        cursor: pointer;
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            border: 1px solid cc_getColor( blanc );
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            position: absolute;
                            left: calc(-100% / 8);
                            top: 50%;
                            transform: translate(0, -50%);
                        }

                        &::after {
                            content: '';
                            display: block;
                            border-left: 1px solid cc_getColor( blanc );
                            border-top: 1px solid cc_getColor( blanc );
                            height: 5px;
                            width: 5px;
                            position: absolute;
                            left: calc(-100% / 8 + 8px);
                            top: 50%;
                            transform: translate(0, -25%) rotate(45deg);
                        }
                    }
                }
            }

            .push {
                padding: 100px 0;
                position: relative;

                .text-container {
                    width: 40%;
                    margin: 0 10% 0 0;
                    padding: 0;

                    h2 {
                        width: 100%;
                    }
                }

                .img {
                    position: absolute;
                    left: 50%;
                    top: 100px;
                    height: calc(100% - 200px);
                    width: 50%;
                    z-index: 1;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -100px;
                        left: 20%;
                        height: calc(100% + 200px);
                        width: calc((100vw - 200%) / 2 + 80%);
                        background: cc_getColor( background, clair );
                        z-index: -1;
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        font-family: 'object-fit: cover;';
                    }
                }
            }

            .clients {
                @extend .background-soft;

                h2 {
                    @extend .outline-soft;
                    padding: 100px 0;
                    font-family: $serif;
                }

                .clients-wrapper {
                    font-size: 0;
                    padding: 30px 0 80px 0;
                    text-align: center;

                    a, span {
                        display: inline-block;
                        vertical-align: middle;
                        width: 20%;
                        margin: 0 0 40px 0;

                        &:nth-child(3n + 2) {
                            margin: 0 20% 40px;
                        }

                        figure {
        
                            img {
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}