.archive,
.blog,
.category {
  header {
    &.row {
      // h1 {
      // 	font-size: $titre1;
      // 	margin: 0 0 40px 0;
      // 	letter-spacing: 2px;
      // 	text-transform: uppercase;
      // }
    }
  }
}

.post-type-archive-coworker {
  header {
    &.row {
      h1 {
        // width: 40%;
        width: 100%;
        margin-bottom: 45px;
      }
    }
  }

  section {
    &.row {
      .text-styles {
        margin-bottom: 40px; /*80*/
      }
      & > div:not([class*="text-styles"]) {
        font-size: 0;
        margin-bottom: 160px;

        figure {
          @extend .col-3;
          display: inline-block;
          vertical-align: top;
          margin: 0 0 90px 0;

          &:nth-child(3n + 2) {
            margin: 0 calc(100% / 12 * 1.5) 90px;
            transform: translate(0, 40%);
          }

          & > div {
            position: relative;

            &::before {
              content: "";
              display: block;
              padding-top: 130%;
            }

            img {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              object-fit: contain; //cover;
              // ?? ('cover' : visuels rectangulaire en longueur, 'contain' pour images arrondies)
              // font-family: 'object-fit: cover;';
              font-family: "object-fit: contain;";
            }
          }

          figcaption {
            text-transform: uppercase;
            font-size: $titre3;
            letter-spacing: 2.5px;
            position: relative;
            // padding: 25px 0 0 0; //?? rectangles
            padding: 5px 0 0 0; //?? arrondies
            text-align: center;

            span {
              display: block;
              font-family: $serif;
              letter-spacing: 0.5px;
              font-style: italic;
              font-weight: 400;
              text-transform: none;
              margin: 5px 0 0 0;
            }

            a {
              display: block;
              position: absolute;
              top: 0;
              right: 20px;
              transform: translate(0, -50%);
              height: 40px;
              width: 40px;
              border-radius: 50%;
              background: cc_getColor(background, fonce);

              &::before {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 18px;
                height: 13px;
                border: 1px solid cc_getColor(blanc);
                transform: translate(-50%, -50%);
              }

              &::after {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 12px;
                height: 12px;
                border-top: 1px solid cc_getColor(blanc);
                border-right: 1px solid cc_getColor(blanc);
                transform: translate(-50%, -100%) rotate(135deg);
              }
            }
          }
        }
      }
    }
  }
}

.post-type-archive-event {
  section {
    &.row {
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 100vw;
        height: 100%;
        background: cc_getColor(background, soft);
        z-index: -1;
        transform: translate(-50%, 0);
      }

      .event {
        position: relative;
        background: cc_getColor(blanc);
        box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);
        margin: 0 0 80px 0;
        z-index: 1;

        .outline {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-55%, -50%) rotate(-90deg);
          z-index: -1;
        }

        .img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 30%;
          background: cc_getColor(background, fonce);
          overflow: hidden;

          img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: "object-fit: cover;";
            transition: transform 0.5s ease-out;
            transform: scale(1);
          }
        }

        .text-container {
          margin: 0 0 0 30%;
          padding: 50px 10%;

          .time {
            display: block;
            font-size: $sub;
            letter-spacing: 3px;
            margin: 0 0 10px 0;
          }

          h2 {
            font-size: $titre2;
            font-weight: 500;
            letter-spacing: 0.5px;
          }

          .location {
            font-style: italic;
            font-family: $serif;
            font-size: $sub;
            letter-spacing: 1px;
            display: block;
            margin: 0 0 20px 0;
          }
        }

        &:first-child {
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            bottom: 50%;
            width: 100vw;
            height: 55%;
            background: cc_getColor(blanc);
            z-index: -2;
            transform: translate(-50%, 0);
          }
        }
      }

      .pagination {
        margin: 110px 0 160px 0;
      }

      .no-event {
        padding: 100px 0;
        text-align: center;

        p {
          font-size: $titre3;
          letter-spacing: 0.4px;
          line-height: 1.78em;
        }
      }
    }
  }
}

.blog,
.category {
  section {
    &.row {
      // @extend .background-clair;

      &::after {
        height: calc(100% - 170px);
        top: 170px;
      }
      .news-list {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center; //space-around
      }
      & > div {
        font-size: 0;

        .news {
          @extend .actualite;
          display: inline-block;
          vertical-align: top;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
          // width: 33%;
          flex: 1 1 33%;
          max-width: 380px;
          gap: 30px;
          margin: 0 15px 30px 15px;

          &:nth-child(odd) {
            // margin: 0 20px 60px 0;
          }

          // .content {

          // 	figure {

          // 		figcaption {
          // 			position: absolute;
          // 			left: 0;
          // 			top: 0;
          // 			height: 100%;
          // 		    width: calc(100% / 5 * .5);
          // 	        z-index: 1;
          // 			transform: translate(-100%, 0);

          // 			time {
          // 			    letter-spacing: 3px;
          // 			    font-size: $sub;
          // 			    white-space: nowrap;
          // 			    position: absolute;
          // 			    left: 50%;
          // 			    bottom: -18px;
          // 		        transform: rotate(-90deg) translate(0%, -50%);
          // 				transform-origin: 0 0;
          // 			}
          // 		}
          // 	}
          // }
        }
      }
      .pagination {
        margin: 50px 0 100px;
        display: block;
        flex: 1;
        width: 100%;

        span,
        a {
          color: cc_getColor(content, texte);

          &::before,
          &::after {
            background: cc_getColor(content, texte);
          }
        }
      }
    }
  }
}

.post-type-archive-job_offer {
  header {
    &.with-figure {
      padding-top: 0;
      padding-bottom: 0;
      max-width: 100%;
      position: relative;
      min-height: 550px;
      &::before {
        padding-top: 30%;
      }
      &:after {
        content: inherit;
      }
      .absolute-title {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        z-index: 1;
        h1 {
          font-size: 90px;
          line-height: 90px;
          letter-spacing: -2px;
          font-family: "market_ot";
          font-weight: 400;
          color: cc_getColor(bleu_sombre);
          text-align: left;
          text-shadow: none;
        }
      }
      figure {
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        width: 100vw;
        transform: translate(-50%, 0);

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          font-family: "object-fit: cover;";
        }
      }
      .row-breadcrumb {
        position: absolute;
        left: 50%; //6px;
        transform: translateX(-50%);
        top: 0;
        z-index: 1;
        opacity: 0.8;
        .breadcrumb span,
        .breadcrumb a {
          color: cc_getColor(content, texte);
        }
      }
    }
  }
  #menu-menu-rejoindre {
      width: 100%;
      display: inline-flex;
      background-color: cc_getColor(rouge);
      padding: 30px 0;
      justify-content: center;
      list-style: none;
      li {
          padding: 0 40px;
          a {
              color: cc_getColor(blanc);
              font-size: 20px;
              font-weight: 700;
              position: relative;
              text-transform: uppercase;
          }
          &.active {
            a {
              &::before {
                  content: '';
                  position: absolute;
                  bottom: -8px;
                  left: 0;
                  width: 100%;
                  height: 2px;
                  background-color: cc_getColor(blanc);
              }
            }
          }
      }
  }
  section {
    &.row {
      h1 {
        margin-bottom: 80px;
      }

      // &::after {
      // 	height: calc(100% - 170px);
      // 	top: 170px;
      // }

      .zone-intro-jobs {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        aside {
          width: 100%;
          max-width: 300px;
          padding: 30px;
          background: cc_getColor(background, soft);
          box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);
          margin-left: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h2 {
            font-size: 17px;
          }
          .cta {
            margin-bottom: 0;
          }
        }
      }

      .jobs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 80px;
      }

      .job {
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.13); //2px 6px 13px 0 rgba(15, 38, 92, 0.2);
        background: cc_getColor(blanc); //cc_getColor( background, soft );
        font-size: 0;

        display: flex;
        flex-direction: column;
        max-width: calc(33.333% - 40px);
        gap: 30px;
        margin: 0 20px 30px;

        .text-container {
          display: inline-block;
          vertical-align: top;
          width: 100%; //calc(100% / 12 * 7);
          height: 100%;
          padding: 40px; //calc(100% / 12);

          h2 {
            margin: 0 0 15px 0;
            font-weight: 500;
            font-size: 2.2rem;
            color: cc_getColor(rouge);
          }

          .cta {
            font-size: 1.4rem;
          }
        }

        .infos {
          display: inline-block;
          vertical-align: top;
          width: 100%; //calc(100% / 12 * 4);
          // background: cc_getColor( background, soft );

          .wrapper {
            position: relative;
            margin-top: 15px;
            // top: 50%;
            // transform: translate(0, -50%);

            & > div {
              font-weight: 700;
              letter-spacing: 0.3px;
              display: block;
              padding: 15px 25px 15px 55px; // calc(100% / 4);
              font-size: $sub;
              color: cc_getColor(content, texte);
              position: relative;

              &::before {
                @extend .sprite;
                content: "";
                display: block;
                position: absolute;
                left: 0px; //calc(100% / 8);
                top: 50%;
                transform: translate(0, -50%);
                height: 20px;
                width: 20px;
                background-repeat: no-repeat;
                background-position: center center;
              }

              &.localisation {
                &::before {
                  width: 21px;
                  height: 27px;
                  background-position: -202px -20px;
                }
              }

              &.contract {
                &::before {
                  width: 22px;
                  height: 27px;
                  background-position: -160px -20px;
                }
              }

              &.ressources {
                &::before {
                  width: 28px;
                  height: 23px;
                  background-position: -104px -20px;
                }
              }
            }
          }
        }

        .time {
          display: inline-block;
          vertical-align: top;
          // width: 100%; //calc(100% / 12);
          position: relative;

          time {
            font-size: $sub;
            letter-spacing: 1px;
            // white-space: nowrap;
          }
        }
      }

      .pagination {
        margin: 110px 0 160px;

        span,
        a {
          color: cc_getColor(blanc);

          &::before,
          &::after {
            background: cc_getColor(blanc);
          }
        }
      }
    }
  }
}

.category-actualites,
.category-news {
  .breadcrumb {
    margin-left: 20px;
  }
  .prllx-v {
    right: 0;
    top: 80%; //50% without visuel
  }
}
