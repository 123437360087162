.home {
    padding-top: 110px;
    .bandeau-principal {
        position: relative;
        height: calc(100vh - 110px);
        width: 100vw;
        overflow: hidden;
        z-index: 0;
        .zone-titre {
            height: calc(100% - 180px);
            padding-bottom: 180px;
            align-items: center;
            justify-content: flex-start;
            z-index: 4;
            .titre-principal {
                text-align: left;
                position: relative;
                font-size: 40px;
                line-height: 52px;
                font-weight: 300;

                opacity: 0;
                -webkit-animation: fadeInUpCustom 1s ease both;
                animation: fadeInUpCustom 1s ease both;
                @media (min-width: 1800px) {
                    font-size: 45px;
                    line-height: 55px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -50px;
                    background-color: $orange;
                    height: 4px;
                    width: 153px;

                    opacity: 0;
                    -webkit-animation: fadeInUpCustom 1s ease both;
                    animation: fadeInUpCustom 1s ease both;
                    animation-delay: 0.2s;
                }
            }
        }
        #layers-bp-hp {
            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;
            background-color: cc_getColor(blanc);
            z-index: -1;
            &.align-items {
                display: flex;
            }
            .masks-layers {
                position: relative;
                flex: 1;
                &:after {
                    content: $rounded-mask-desktop;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 215px;
                    right: -202px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    // left: 0;
                    bottom: 80px;
                    background-image: url('../../img/decoratifs/element-bp-left.png');
                    background-repeat: no-repeat;
                    background-position: 0 32px;
                    height: 303px;
                    width: 291px;

                    opacity: 0;
                    -webkit-animation: fadeInLeftCustom 0.8s ease-in-out both;
                    animation: fadeInLeftCustom 0.8s ease-in-out both;
                    animation-delay: 0.2s;
                }
                #blue-circle-svg {
                    position: absolute;
                    top: 80px;
                    right: -150px;
                    z-index: 2;

                    opacity: 0;
                    -webkit-animation: fadeInCustom 0.8s ease-in-out both;
                    animation: fadeInCustom 0.8s ease-in-out both;
                    animation-delay: 0.5s;
                }
                #tirets-decoratifs{
                    position: absolute;
                    bottom: 80px;
                    right: -162px;
                    z-index: 2;
                    rect:first-of-type {
                        opacity: 0;
                        -webkit-animation: fadeInCustom 0.8s ease-in-out both;
                        animation: fadeInCustom 0.8s ease-in-out both;
                        animation-delay: 0.2s;
                    }
                    rect:last-of-type {
                        opacity: 0;
                        -webkit-animation: fadeInRightCustom 0.8s ease-in-out both;
                        animation: fadeInRightCustom 0.8s ease-in-out both;
                        animation-delay: 0.2s;
                    }
                }
            }
            .visuel-bp-hp {
                position: relative;
                z-index: -2;
                &:before, &:after {
                    content:'';
                    position: absolute;
                    background-size: contain;
                    z-index: 0;
                }
                &:before {
                    top: 0;
                    right: 50px;
                    width: 629px;
                    height: 393px;
                    background-image: url(../../img/decoratifs/element-dotted-tobottom-midtop.png);
                }
                &:after {
                    bottom: 0; right: 0;
                    width: 333px;
                    height: 413px;
                    background-image: url(../../img/decoratifs/element-dotted-totop-corderright.png);
                }
            }
        }
    }

    section {
        &.services {
            background-color: #f6f6f6;
            background-image: url('../../img/backgrounds/bg-nuages.jpg');
            background-position: center bottom;
            background-repeat: no-repeat;
            padding-top: 70px;
            padding-bottom: 100px;
            position: relative;
            .prllx-v {
                left: -105px;
                bottom: -50px;
                z-index: 0;
            }
            // &:after {
            //     content: '';
            //     position: absolute;
            //     background-image: url('../../img/decoratifs/element-services.png');
            //     background-repeat: no-repeat;
            //     height: 547px;
            //     width: 362px;
            //     left: -105px;
            //     bottom: -95px;
            //     z-index: 0;
            // }
            .titre {
                margin-bottom: 50px;
                color: cc_getColor(content,texte);
            }
            .content-zone {
                position: relative;
                width: auto;
            }
            .wrapper {
                position: absolute;
                bottom: 120px;
                max-width: 400px;
                z-index: 2;
                &.left-cta { 
                    left: 25%;
                    transform: translateX(-25%);
                    @media (min-width: 1200px) {
                        transform: translateX(calc(-25% - 40px));
                    }
                }
                &.right-cta { 
                    right: 25%;
                    transform: translateX(25%);
                 }
            }
            [class$="-shape"] {
                height: 607px;
                width: 304px;
                display: block;
                position: relative;
                overflow: hidden;
                // transition: all 0.3s ease-in-out;
                z-index: 1;
                img {
                    position: absolute;
                    top: 0; bottom:0;
                    left: 0; right: 0;   
                }
            }
            .left-shape {                
                border-radius: 304px 0 0 304px;
                margin-bottom: 50px;
                transition: transform 0.4s ease-in-out;
                // transition-delay: 1s;
                // transition: transform 0.5s ease-in-out 0.3s;
            }
            .right-shape {
                border-radius: 0 304px 304px 0;
                display: block;
                margin-top: 50px;
                // transition: transform 0.5s ease-in-out 0.3s;
                transition: transform 0.4s ease-in-out;
                transition-delay: 0.1s;
            }
            .cta-service.service--1 { box-shadow: 3px 20px 100px rgba(0,0,0,0.4); }
            .cta-service.service--2 { box-shadow: 3px 15px 100px rgba(0,0,0,0.4); }
        }
        &.chiffres-cles {
            // position: relative;
            padding-top: 50px;
            padding-bottom: 180px;
            h2.titre { font-size: 3.3rem; margin-bottom: 100px; color: cc_getColor(blanc); }
            iframe {
                max-width: 100%;
                // width: 672px;
                // height: 378px;
            }
            &.chiffres-cles-styles {
                &::after {
                    background-color: cc_getColor(background,fonce);
                    background-image: var(--bg-cc-hp);
                }
                .liste-chiffres-cles {
                    padding-left: 120px;
                    padding-right: 180px;
                    p {
                        text-shadow: 0 0 15px rgba(0,0,0,0.9); //0 0 15px rgba(0,0,0,0.5);
    
                        // ?? Couleur du texte
                        &.blanc { color: cc_getColor(blanc); } //fs:41
    
                        // ?? Petite police pour longs textes
                        // &.small-font { font-size: 32px; }
                    }
                }
            }

        }
        &.actualites {
            padding-top: 50px;
            // padding-bottom: 80px;
            text-align: center;
            position: relative;
            .prllx-v {
                right: 0;
                bottom: -100px;
                z-index: -1;
            }
            &:after {
                /*content: '';
                position: absolute;
                background-image: url('../../img/decoratifs/element-mid.png');
                background-repeat: no-repeat;
                height: 706px;
                width: 272px;
                right: 0;
                bottom: -100px;
                z-index: -1;*/
            }
            // @extend .background-clair; //!! 123

            h2 {
                // @extend .outline-clair;
                text-transform: uppercase;
            }

            hr.separator {
                margin-top: 80px;
                margin-bottom: 0;
            }

            .slider-actualites {
                position: relative;
                left: 50%;
                top: 0;
                // transform: translate(-50%, 0);
                transform: translate(-50%, 0) translate(0) scale(1) !important; // ! aos
                // width: 100vw;
                margin: 30px 0 50px 0;

                .controls {
                    .prev, .next {
                        position: absolute;
                        left: 25%;
                        top: 50%;
                        // background-image: $sprite;
                        // transform: translate(-50%, -50%) rotate(180deg);
                        //    background-position: -17px -15px;
                        display: none;
                        cursor: pointer;
                        width: 33px;
                        height: 19px;
                        z-index: 3;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            height: 1px;
                            width: 100%;
                            background: cc_getColor(blanc);
                        }

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%) rotate(-135deg);
                            border-right: 1px solid cc_getColor(blanc);
                            border-top: 1px solid cc_getColor(blanc);
                            height: 9px;
                            width: 9px;
                        }
                    }
                    .next {
                        left: 75%;
                        transform: translate(-50%, -50%) rotate(0deg);

                        &::after {
                            left: auto;
                            right: 0;
                            transform: translate(0, -50%) rotate(45deg);
                        }
                    }
                }

                .swiper-wrapper {
                    transition-timing-function: cubic-bezier(0.43, 0.72, 0, 1);
                    gap: 30px;
                    justify-content: space-around;
                }

                .swiper-slide {
                    // width: 33%;
                    max-width: 380px;
                    // max-width: 33%;
                    height: auto;
                    // &:first-of-type .wrapper { margin-left: 0; }
                    // &:last-of-type .wrapper { margin-right: 0; }
                }

                .wrapper {
                    @extend .actualite;
                    width: 380px;
                    // max-width: 33%;
                    // margin: 0 calc(100% / 3);
                    height: 100%;
                    h3 { text-decoration: none; }
                    // .content {
                    // 	figure {
                    // 		figcaption {
                    // 			// position: absolute;
                    // 			// left: 20px;
                    // 			// bottom: -25px;
                    // 	        // z-index: 1;
                    //             // border-radius: 50px;
                    //             // padding: 8px 10px;
                    //             // color: white;
                    //             // background-color: $orange;
                    // 			time {
                    // 			    font-size: 16px;
                    //                 font-weight: 500;
                    // 			}
                    // 		}
                    // 	}
                    // }
                }
            }

            .more {
                color: cc_getColor(blanc);
            }
        }
        &.row {

            &.recherche {

                form {

                    .reset {
                        display: none;
                    }
                }
            }

            &.certifications-wrapper {
                padding-top: 80px;
                padding-bottom: 80px;

                & > div {
                    @extend .certifications;
                }
            }

            &.temoignages {
                padding-top: 130px;
                padding-bottom: 80px;
                position: relative;
                @extend .background-soft;

                figure {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    height: 100%;
                    width: 50vw;
                    z-index: 0;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        font-family: 'object-fit: cover;';
                    }
                }

                & > div {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    @extend .testimonies;

                    .more {
                        margin: 0 0 0 10%;
                    }
                }
            }
        }
    }

    footer {

        &.row {

            &::before {
                content: none;
            }
        }
    }
}
