// Low resolution

@media only screen and (max-width: 1024px) {

}

// Tablette

@media only screen and (max-width: 1023px) {

	html.overflow {
		overflow: hidden;
	}

	body {
		padding-top: 82px; //100px;
	}

	.breadcrumb {
		margin: 10px 0 65px 0;
	}

	.page:not(.home), 
	.blog, .category, .archive,
	.single-post, .single-job_offer  {
		h1.main-title:not(.wp-block-heading), h1:not(.wp-block-heading) {
			font-size: 40px;
			small {
				font-weight: 300;
			}
		}
	}

	.page, .single-post, .single-job_offer  {
		section.row {
			.chiffres-cles-container {
				margin-left: 0;
				margin-right: 0;
				&.chiffres-cles-styles {
					.liste-chiffres-cles {
						padding-right: 80px;
					}
				}               
				.timeline {
					margin-top: 80px;
					.time-point {
						.infos {
							transform: translateY(-15px);
						}
					}
				}
			}
		}
	}

	.page-template-rejoindre,
	.post-type-archive-job_offer {
		header {
			&.with-figure {
				.absolute-title {
					p, h1 {
						font-size: 60px;
						text-align: center;
					}
				}
			}
		}
		#menu-menu-rejoindre {
			flex-wrap: wrap;
			row-gap: 15px;
		}
	}

	.single-job_offer {
		section {

			&.row {
				.text-container {
					.bloc-postuler,
					.details-job {
						margin-left: 20px;
					}
				}
			}
		}
	}

	.cta-service {
		font-size: 20px;
		padding: 20px 70px 20px 28px;
		&:after {
			font-size: 50px;
			right: 18px;
			height: 30px;
			width: 30px;
			padding: 7px 10px 12px;
		}
		&.service--2 {
			max-width: 300px;
			padding: 20px 70px 20px 28px;
		}
	}

	.home, .category-actualites, .category-news, .page-template-expertise, .page-template-contact {
		.prllx-v { display: none; }
	}

	nav {
		&.row  {
			height: 88px;
			&.small {
				height: 75px;
				.nav {
					height: calc(100vh - 75px);
					top: 75px;
				}
				.logo {
					a {
						height: auto;
						width: 220px;
						img {
							height: 100%;
							width: auto;
						}
					}
				}
			}
			.logo {
				text-align: left;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0;
				a {
					margin: 0;
					width: 220px;
				}
			}
			.nav {
				position: fixed;
			    // left: 0;
				right: 0;
			    top: 88px;
			    width: 500px; // 40vw // !! 100% (mobile only)
			    height: calc(100vh - 88px);
			    background: cc_getColor(background, fonce);
			    overflow: auto;
			    transform: translate(500px, 0); // 40vw // !! translate(100%, 0) (mobile only)
			    transition: transform .75s cubic-bezier(0.4, 0.81, 0, 1);

				&::before {
					content: none;
				}
				.header-top {
					justify-content: space-between;
					width: 100%;
					padding-left: calc(100% / 12);
					padding-right: calc(100% / 12);
					margin-top: 30px;
					margin-bottom: 30px;
					ul {
						justify-content: space-between;
						&> li.pll-parent-menu-item {
							position: relative;
							transform: none;
							& > a {
								color: cc_getColor(blanc);
								&:after { filter: brightness(0) invert(1); }
							}
							a {
								margin: 0;
								&::after {
									border-top-color: cc_getColor( content, langue );
								}
							}
	
							ul {
								padding: 0;
								right: auto;
								left: 0;
								top: 30px; //85
								margin: 0;
								left: calc(100% / 12);
							}
						}
					}
				}
				.header-main {
					justify-content: flex-start;
					width: 60%;
				
					ul {
						display: block;
						width: 100%;
						&#menu-menu-principal {
						  padding-bottom: 70px;
						}
						li {
							a {
								color: cc_getColor(blanc);
							}
							&.menu-item-has-children {
								&:after {
									content: $chevron-down;
									position: absolute;
									right: 25px; top: 25px;
									filter: brightness(0) invert(1);
								}
							}
						}

						& > li {
							&.menu-item-has-children:after { right: 25px; top: 25px; }

							a {
								padding: 25px calc(100% / 12);
							}

							ul {
								position: relative;
								top: auto;
								padding: 0 0 0 50px;
								margin: -30px calc(100% / 12) 0;
								display: none;
								transform: none;
								visibility: visible;
								opacity: 1;
								z-index: 1;

								li {
									&.menu-item-has-children:after { right: 25px; top: 15px; }

									a {
										padding: 15px 0;
										font-size: $label;
										white-space: normal;
									}
								}

								&.visible {
									display: block;
									margin-top: 0;
								}
							}
						}
					}
				}

				&.visible {
				    transform: translate(0, 0%);
					overflow: hidden;
					overflow-y: scroll;
				}
			}

			.burger {
				display: block;
			}
		}
	}

	.recherche {

		.close {
			right: 4px;
			z-index: 3;
		}

	}

	.certifications {

		.full {

			a {
				width: calc(100% / 12 * 3);
			}
		}
	}

	aside {

		&.recherche {
			top: 122px;
		}
	}

	.footer {
		.prefooter {
			&:after {
				background-position: 0 101%;
			}
		}
		.news {
			margin-top: 50px;
			.logo { justify-content: center; }
			p { text-align: center; }
		}
	}

	.home {
		padding-top: 82px;
		.bandeau-principal {
			height: calc(100vh - 100px);
			.zone-titre {
				height: 100%;
				padding-bottom: 180px;
				.titre-principal {
					font-size: 35px;
					line-height: normal;
					padding-left: 21px;
					padding-right: 50px;
					br:not(:first-of-type) { display: none; }
					&:after {
						bottom: -40px;
						width: 90px;
					}
				}
			}
			#layers-bp-hp {
				width: 135%;
				.masks-layers {
					flex: 1;
					#mask-bp {
						height: 100%;
						width: 215px;
						// right: -160px;
						right: -110px;
					}
					&:before {
						bottom: 35px;
						background-size: contain;
						background-position: unset;
						height: 135px;
						width: 130px;
					}
					#blue-circle-svg {
						top: 45px;
						right: -45px;
						width: 71px;
					}
					#tirets-decoratifs {
						right: -55px;
						width: 50%;
					}
				}
				.visuel-bp-hp {
					flex: 1;
					img { max-width: 100%; transform: translateX(-50px); }
					&:before {
						background-repeat: no-repeat;
						max-width: 100%;
						// left: 50%; transform: translateX(-50%); // !! responsive ?
						right: 50px;
						width: 629px;
						height: 393px;
					}
					&:after { content: none; }
				}
			}
		}
		
		section {
			&.services {
				&:after { content: none; }
				.line { flex-wrap: nowrap; }
				.wrapper {
					bottom: 30px;
					max-width: 320px; //!! 12345
					&.left-cta { 
						left: 20%;
						transform: translateX(-20%);
					 }
					&.right-cta { 
						right: 20%;
						transform: translateX(20%);
					 }
				}
				[class$="-shape"] {
					height: 457px;
					width: 250px;
				}
				.left-shape {
					border-radius: 250px 0 0 250px;
					// margin-bottom: 50px;
				}
				.right-shape {
					border-radius: 0 250px 250px 0;
					// margin-top: 50px;
				}
			}

			&.actualites {
				.slider-actualites {
					.controls {
						.prev { left: 15%; }
						.next { left: 85%; }
					}
					.swiper-slide {
						width: 50%;
						.wrapper { margin: 0 calc(100% / 12); }
					}
				}
			}
		}
	}

	.page {
		header {
			&.row {
				.title {
					h1:not(.wp-block-heading) {
						margin: 40px 10%;
					}
				}
			}
		}

		section {
			&.row {
			    // padding-top: 65px;
				.text-container {
					// padding: 0;
					ul, ol {
						margin: 0 0 40px;
					}
				}
			}
		}
	}

	.page-template-metiers {
		header {
			&.row {
				.title {
					h1 {
						margin: 40px 10%;
					}
				}
			}
		}

		section {
			&.row {
				.pictos {
				    margin: 100px calc(-100% / 8 * .5) 0;
				}
				.savoir-faire {
					.savoir-container {
						.savoir {
							width: 100% !important;
							&::before {	content: none;	}
							.img { width: 100vw; }

							.wrapper {
								position: relative;
								padding: 40px calc(100% / 12);
								h3 {
									margin: 0 0 10px 0;
								}
							}
						}
					}

					.savoir-content {
						padding: 80px 0 50px;

						h2 {

							.cpt {
								position: relative;
							    left: auto;
							    top: auto;
							    display: block;
							}
						}

						.up {
							margin: 85px 0 0 calc(10% + 6px);
						}
					}
				}
			}
		}
	}

	.page-template-contact {

		section {

			&.row {
				padding-top: 0;

				&::after {
	                content: none;
	            }

				.form {
					position: relative;
					// height: 240px;
					height: auto !important;
					// padding: 0 calc(100% / 12 + 6px);

					&::after {
		                content: '';
		                display: block;
		                position: absolute;
		                left: 0;
		                bottom: 0;
		                width: 100%;
		                height: 50px;
		                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
		                pointer-events: none;
		            }

		            form {
		            	// margin: 40px 0;
		            }
				}

				.map-container {

					&::before {
						left: -6px;
						width: 100vw;
					}

					.map {
						margin: 0 -12px;
						width: 100vw;
					}
				}
			}
		}
	}
	

	.post-type-archive-capability {
		padding-top: 82px;

		section {

			&.row {

				&.recherche {
				    padding-bottom: 80px;
				}

				.table-container {

					form {
						margin: -40px 0 0 0;
					    overflow-x: scroll;
					}
				}
			}
		}
	}

	.post-type-archive-coworker {

		section {

			&.row {

				& > div {
					margin-bottom: 0;

					figure {
						width: calc(100% / 12 * 4);
						margin: 0 calc(25% / 2) 50px !important;
						// margin: 0 calc(100% / 12 * 1.5) 90px !important;
						transform: none !important;
					}
				}
			}
		}
	}

	.swiper-pagination-bullets:not(.pagination-engagement, .pagination-slider-card) {
		width: 100%;
		text-align: center;
		margin-top: 40px;
		.swiper-pagination-bullet {
			background: transparent;
			border: 5px solid transparentize(cc_getColor( bleu_ciel ), 0.5);
			border-radius: 50%;
			margin: 0 6px;
			opacity: 1;
			transition: border-color 0.3s ease;
			&.swiper-pagination-bullet-active {
				border: 5px solid cc_getColor( content, texte );
			}
			&:hover {
				border-color: cc_getColor( bleu_ciel );
				cursor: pointer;
			}
		}
	}

  
  .bloc-mediaTextQuotes {
    .mediaTextQuote {
      .visuel-container {
        width: 85%;
      }
      .content-container {
        width: 60%;
      }
    }
  }
  .block--esprits {
    gap: 30px;
    .esprit {
      width: calc(50% - 15px);
    }
  }
  

	// .pagination {
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: 20px;
	// 	z-index: 2;
	// 	text-align: center;

	// 	span {
	// 		border: 1px solid cc_getColor(blanc);
	// 		background: transparent;
	// 		opacity: 1;
	// 		padding: 0;

	// 		&.swiper-pagination-bullet-active {
	// 			background: cc_getColor(blanc);
	// 		}
	// 	}
	// }
}

