.error404 {
	
	section {

		&.row {

			& > div {
				margin-top: 140px;
				margin-bottom: 100px;
				text-align: center;

				h1 {
					text-transform: uppercase;
					letter-spacing: 2px;
					font-size: $titre1;
					text-align: center;
					margin: 0 0 150px 0;
					position: relative;

					&::after {
				        content: attr(data-title);
				        display: block;
				        position: absolute;
				        left: 50%;
				        top: 50%;
				        transform: translate(-50%, -50%);
				        font-size: $outline;
				        font-family: $serif;
				        white-space: nowrap;
				        text-transform: uppercase;
				        z-index: -1;
				        font-weight: 500;
				        color: cc_getColor( blanc );
				        text-shadow: -1px -1px 0 cc_getColor( content, texte ),
					        1px -1px 0 cc_getColor( content, texte ),
					        -1px 1px 0 cc_getColor( content, texte ),
					        1px 1px 0 cc_getColor( content, texte );
				        opacity: .15;
				        letter-spacing: 15.5px;
				        font-size: 20rem;
				    }
				}

				.cta {
					@extend .cta-clair;
				}
			}
		}
	}    
}