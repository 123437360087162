@import 'initialize/variables';
@import 'initialize/reset';
@import 'initialize/grid';
@import 'initialize/fonts';
//Libs
@import "~choices.js/public/assets/styles/choices.css";
@import "~simplebar/dist/simplebar.css";
@import '~swiper/swiper.scss';
// Blocs
@import 'generic/keyframes';
@import 'generic/text-styles';
@import 'generic/common';
@import 'generic/utils';
@import 'generic/nav';
@import 'generic/header';
@import 'generic/footer';
// Templates
@import 'templates/rte';
@import 'templates/home';
@import 'templates/archive';
@import 'templates/capability';
@import 'templates/contact';
@import 'templates/metiers';
@import 'templates/expertise';
@import 'templates/404';
//// Responsive
@import 'generic/hover';
@import 'generic/laptop';
@import 'generic/tablet';
@import 'generic/mobile';

.no-aos {
    [data-aos] {
      opacity: 1 !important;
      transform: translate(0) scale(1) !important;
      pointer-events: all;
    }
  }