.page,
.single-post,
.single-job_offer {
  section {
    &.row {
      /*.prev-btn {
                float: right;
                font-size: $label;
                color: cc_getColor(content, texte);
                font-weight: 200;
                display: block;
                padding: 5px 5px 5px 70px;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    height: 0;
                    width: 0;
                    border-right: 6px solid cc_getColor(content, texte);
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    height: 1px;
                    width: 30px;
                    background: cc_getColor(content, texte);
                }
            }*/

      .main-content {
        margin-bottom: 50px;
      }
      .text-container {
        // padding: 0 10%;
        @extend .text-styles;
        .bloc-postuler {
          background: cc_getColor(background, soft);
          box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);
          margin: 0 15px 40px 50px;
          padding: 30px 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          p {
            text-align: center;
            font-size: 18px;
            font-weight: 400;
          }
          .cta {
            margin: 0;
          }
        }
        .details-job {
          background: cc_getColor(background, soft);
          // width: 50%;
          // margin: 0 calc(-100% / 8 * 2) 40px calc(100% / 8);
          margin: 0 15px 40px 50px;
          box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);

          .content {
            padding: 30px;
          }
          .content > div {
            font-weight: 400;
            strong {
              font-weight: 600;
            }
            // letter-spacing: .3px;
            display: block;
            padding: 15px 25px 15px 55px; // calc(100% / 4);
            margin-bottom: 20px;
            // font-size: $sub;
            position: relative;

            &::before {
              @extend .sprite;
              content: "";
              display: block;
              position: absolute;
              left: 0px; //calc(100% / 8);
              top: 50%;
              transform: translate(0, -50%);
              height: 20px;
              width: 20px;
              background-repeat: no-repeat;
              background-position: center center;
            }

            &.localisation {
              &::before {
                width: 21px;
                height: 27px;
                background-position: -202px -20px;
              }
            }

            &.contract {
              &::before {
                width: 22px;
                height: 27px;
                background-position: -160px -20px;
              }
            }

            &.ressources {
              &::before {
                width: 28px;
                height: 23px;
                background-position: -104px -20px;
              }
            }
          }

          /*& > a {
                        display: block;
                        color: cc_getColor(blanc);
                        background: cc_getColor(background, clair);
                        text-decoration: underline;
                        padding: 20px;
                        text-align: center;
                        font-size: $label;
                        font-family: $serif;
                        letter-spacing: .3px;

                        &::after {
                            @extend .sprite;
                            background-position: -242px -67px;
                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            width: 14px;
                            height: 15px;
                            margin: 0 0 0 20px;
                        }

                        &.share {
                            border-top: 1px solid cc_getColor(blanc);

                            &::after {
                                width: 13px;
                                height: 11px;
                                background-position: -280px -67px;
                            }
                        }
                    }*/
        }
        .prev-btn {
          svg {
            transform: rotate(90deg) translate(2px, 6px);
          }
        }

        /*h2 {
                    margin: 80px 0 50px 0;
                    font-family: $serif;
                    font-size: $titre2;
                    letter-spacing: .2px;
                    width: calc(100% / 8 * 4 - 6px);
                    font-weight: 400;
                }

                h3 {
                    font-size: $titre3;
                    letter-spacing: .6px;
                    line-height: 1.44em;
                    text-decoration: underline;
                    width: calc(100% / 8 * 3 - 6px);
                    margin: 0 0 40px 0;
                }

                h4 {
                    font-weight: 300;
                    text-transform: uppercase;
                    color: cc_getColor(blanc);
                    font-size: $paragraphe;
                    display: inline-block;
                    background: cc_getColor(background, fonce);
                    padding: 3px 80px;
                    line-height: 1.73em;
                    letter-spacing: 2px;
                    margin: 0 0 50px 0;
                }*/

        p {
          margin: 0 0 20px 0;

          a {
            @extend .more;
            font-weight: 500;
          }
        }

        /*ul, ol {
                    position: relative;
                    margin: 0 calc(-100% / 8) 40px;
                    padding: 60px 25%;
                    box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);
                    background: cc_getColor(blanc);
                    clear: both;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 60px;
                        transform: translate(-50%, 0);
                        width: 100vw;
                        height: calc(100% - 120px);
                        background: cc_getColor(background, clair);
                        z-index: -1;
                    }

                    li {
                        margin: 15px 0 0 0;
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: -10%;
                            top: 10px;
                            height: 8px;
                            width: 8px;
                            border-radius: 50%;
                            background: cc_getColor(background, clair);
                        }

                        &:first-child {
                            margin: 0;
                        }
                    }

                    & + ul, & + ol {

                        &::after {
                            top: -100px;
                            height: calc(100% + 40px);
                        }
                    }
                }

                ol {
                    counter-reset: cpt;

                    li {

                        &::before {
                            counter-increment: cpt;
                            content: counter(cpt);
                            height: auto;
                            width: auto;
                            font-size: $paragraphe;
                            font-weight: 200;
                            background: transparent;
                            top: 0;
                        }
                    }
                }*/

        .cta-wrapper {
          text-align: center;
          margin: 0 0 20px 0;

          .cta {
            @extend .cta-clair;
          }
        }

        .social-wrapper {
          font-size: 0;
          clear: both;
          padding: 80px 0 0 0;

          h3,
          .links {
            display: inline-block;
            vertical-align: top;
            width: 49.5%;
          }

          h3 {
            font-size: $titre2;
            text-decoration: none;
            font-family: $serif;
          }

          .links {
            text-align: right;

            div {
              @extend .sprite;
              background-position: -135px -67px;
              display: inline-block;
              height: 37px;
              width: 37px;
              margin: 0 0 0 33px;
              cursor: pointer;

              &:first-child {
                margin: 0;
              }

              &.twitter {
                background-position: -79px -67px;
              }

              &.facebook {
                background-position: -191px -67px;
              }
            }
          }
        }

        .push {
          @extend .background-soft;
          padding: 30px 0 80px;
          text-align: center;

          h2 {
            @extend .outline-soft;
            width: 100%;
            color: cc_getColor(content, texte);
            font-size: $titre2;
            // &:after { display: none; }
            &:after {
              left: 50% $i;
              transform: translateX(-50%);
            }
          }
        }

        &.is-style-two-columns {
          margin: 0 0 40px 0;
        }

        &.is-style-intro {
          padding: 0;
          margin: 0 0 40px 0;
        }
      }

      .wp-block-media-text {
        position: relative;
        margin: 40px 0;
        font-size: 0;

        .wp-block-media-text__content {
          display: inline-block;
          vertical-align: middle;
        }
        figure {
          display: inline-block;
          vertical-align: middle;
        }

        .wp-block-media-text__content {
          font-size: $paragraphe;
          /* line-height: 1.63em;
                    letter-spacing: .8px;*/
          padding: 0 5% 0 0;

          strong {
            font-weight: 500;
          }

          h2,
          h3 {
            width: auto;
          }

          .has-large-font-size {
            font-size: $titre3;
            font-weight: 500;
            /* letter-spacing: .5px; */
          }
        }

        // figure {
        // padding: 50px 5% 50px 0;
        // position: relative;

        /*&::before {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 20%;
                        top: 0;
                        height: 100%;
                        width: calc(50vw - 20%);
                        background: cc_getColor(background, soft);
                        z-index: -1;
                        transform: translate(-100%, 0);
                        transition: transform .5s ease-out;
                        will-change: transform;
                    }*/
        // }

        &.has-media-on-the-right {
          direction: rtl;

          .wp-block-media-text__content {
            direction: ltr;
            padding: 0 0 0 5%;
          }

          figure {
            direction: ltr;
            padding: 50px 0 50px 5%;

            /*&::before {
                            left: 20%;
                            right: auto;
                            transform: translate(100%, 0);
                        }*/
          }
        }

        &.visible {
          figure {
            /*&::before {
                            transform: translate(0%, 0);
                        }*/
          }
        }
      }

      .figures-container {
        font-size: 0;
        margin: 0 0 40px 0;
        padding: 0 10%;

        figure {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% / 8 * 1.5);
          border-radius: 50%;
          background: cc_getColor(blanc);
          box-shadow: -2px 6px 17px 0 rgba(15, 38, 92, 0.15);
          margin: 0 calc(100% / 8 * 0.5) 40px 0;
          position: relative;

          &::before {
            content: "";
            display: block;
            padding-top: 100%;
          }

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-height: 75%;
            max-width: 75%;
          }
        }

        .text-container {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% / 8 * 6);
          padding: 0;
        }
      }

      .wp-block-image {
        position: relative;
        // margin: 80px 0 110px 0;

        /*&::before {
                    content: '';
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    height: 100%;
                    width: 100vw;
                    transform: translate(-50%, 0);
                    z-index: -1;
                    background: cc_getColor(background, clair);
                }*/

        &.is-style-background-less::before {
          content: none;
        }

        // img { // !!123
        //     width: 100%;
        // }
      }

      .video-container {
        margin: 60px 0;

        &::before {
          content: "";
          display: block;
          padding-top: 56.25%;
        }

        img,
        .player {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          font-family: "object-fit: cover;";
          z-index: 1;
        }

        .player {
          z-index: 2;
        }

        .play {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          z-index: 2;
          cursor: pointer;

          & > div {
            @extend .sprite;
            background-position: -313px -67px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 68px;
            width: 68px;
          }
        }

        &.played {
          img,
          .play {
            opacity: 0;
            pointer-events: none;
          }
        }
      }

      .slider-container {
        margin: 80px 0 60px 0;

        .slider-rte {
          position: relative;

          .swiper-slide {
            .image-container {
              position: relative;

              &::before {
                content: "";
                display: block;
                padding-top: 56.25%;
              }

              img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                font-family: "object-fit: cover;";
              }
            }
          }

          .pagination {
            position: absolute;
            left: 0;
            bottom: 20px;
            z-index: 2;
            text-align: center;

            span {
              border: 1px solid cc_getColor(blanc);
              background: transparent;
              opacity: 1;
              padding: 0;

              &.swiper-pagination-bullet-active {
                background: cc_getColor(blanc);
              }
            }
          }
        }
      }

      .slider-container,
      .video-container {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 60px !important;
          transform: translate(-50%, 0);
          width: 100vw;
          height: calc(100% - 120px) !important;
          background: cc_getColor(background, fonce);
          z-index: -1;
        }

        & + .slider-container,
        & + .video-container {
          margin: 60px 0 120px;

          &::after {
            top: -140px;
            height: calc(100% + 200px);
          }
        }
      }

      .tabs-container {
        margin: 40px 0 65px;
        box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);
        overflow: hidden;

        ul {
          display: flex;
          justify-content: space-around;
          background: cc_getColor(background, soft);
          font-size: 0;
          padding: 0;
          margin: 0;

          li {
            display: block;
            flex-grow: 1;
            flex-basis: 0;
            font-size: $titre3;
            letter-spacing: 0.6px;
            line-height: 1.44em;
            padding: 10px 20px;
            position: relative;
            text-align: center;
            cursor: pointer;
            margin: 0;

            &::before {
              content: none;
            }

            &.active {
              background: cc_getColor(blanc);
              box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);
            }

            &:not(.active) + li:not(.active) {
              &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 10px;
                height: calc(100% - 20px);
                width: 1px;
                background: cc_getColor(background, fonce);
              }
            }
          }
        }

        .tabs {
          background: cc_getColor(blanc);
          position: relative;
          z-index: 1;
          padding: 80px 0;

          .text-container {
            display: none;

            &.active {
              display: block;
            }
          }
        }
      }

      .temoignages-container {
        padding-top: 80px;
        padding-bottom: 60px;
        position: relative;
        @extend .testimonies;
        @extend .background-soft;

        h2 {
          font-family: $serif;
          font-size: $titre2;
          text-transform: none;
          letter-spacing: 0.2px;
          margin: 0px 0 50px;
        }

        & > div {
          @extend .offset-1;
        }
      }
      .chiffres-cles-container {
        // padding: 80px 0;
        // margin: 40px 0;
        padding-top: 50px;
        padding-bottom: 180px;

        h2 {
          text-align: center;
          color: cc_getColor(content, texte);
          font-family: $serif;
          font-size: $titre2;
          letter-spacing: 0.2px;
          margin: 0 0 50px 0;
          width: 100%;
        }
        // h2.titre { font-size: 3.3rem; margin-bottom: 100px; color: cc_getColor(blanc); }
        &.chiffres-cles-styles {
          padding-top: 80px;
          padding-bottom: 80px;
          & > .line {
            align-items: center;
          }
          @media (min-width: 1600px) {
            margin-top: 80px;
            margin-left: -80px;
            margin-right: -80px;
          }
          &::after {
            background-color: cc_getColor(blanc);
            background-image: var(--bg-cc-pi);
          }
          .liste-chiffres-cles {
            padding-right: 130px;
            p {
              margin-top: -20px;
              margin-bottom: 0;
            }
          }
        }
        .timeline {
          position: relative;
          display: flex;
          flex-direction: column;
          .time-point {
            position: relative;
            // display: flex;
            // flex-wrap: nowrap;
            border-left: 10px solid lightgray;
            padding-bottom: 25px;
            padding-left: 30px;
            &:last-of-type {
              border-left: 10px solid transparent;
            }
            &::before {
              content: "";
              position: absolute;
              left: -17px;
              top: 0; //50%; transform: translateY(-50%);
              width: 12px;
              height: 12px;
              background-color: #fff;
              border: 6px solid $orange;
              border-radius: 50%;
            }
            .infos {
              transform: translateY(-8px);
              span {
                font-size: 28px;
                font-weight: 400;
              }
              p {
                margin: 0;
                font-size: 18px;
              }
            }
          }
        }
      }

      .timeline-container {
        padding: 80px 0;
        margin: 40px 0;
        @extend .background-clair;

        h2 {
          text-align: center;
          color: cc_getColor(blanc);
          font-family: $serif;
          font-size: $titre2;
          letter-spacing: 0.2px;
          margin: 0 0 50px 0;
          width: 100%;
        }

        .slider-wrapper {
          position: relative;
          left: 50%;
          top: 0;
          transform: translate(-50%, 0);
          width: 100vw;
          margin: 50px 0 0 0;

          .swiper-container {
            position: relative;

            &::before {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 35px;
              height: 1px;
              width: 100%;
              background: cc_getColor(blanc);
              z-index: 2;
              pointer-events: none;
            }

            .controls {
              .prev,
              .next {
                position: absolute;
                left: calc(100% / 4 * 1.5);
                top: 35px;
                z-index: 3;
                display: block;
                height: 40px;
                width: 40px;
                transform: translate(0, -50%);
                cursor: pointer;
                outline: none;

                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  height: 0;
                  width: 0;
                  border-top: 7px solid transparent;
                  border-bottom: 7px solid transparent;
                  border-right: 11px solid cc_getColor(blanc);
                }
              }

              .next {
                transform: translate(0, -50%) rotate(180deg);
                left: auto;
                right: calc(100% / 4 * 1.5);
              }
            }

            .swiper-slide {
              text-align: center;

              .year {
                display: block;
                font-size: $date;
                font-family: $serif;
                font-weight: 700;
                line-height: 1.3em;
                letter-spacing: 0.6px;
                color: cc_getColor(background, clair);
                text-shadow: -1px -1px 0 rgba(cc_getColor(background, soft), 0.2),
                  1px -1px 0 rgba(cc_getColor(background, soft), 0.2),
                  -1px 1px 0 rgba(cc_getColor(background, soft), 0.2),
                  1px 1px 0 rgba(cc_getColor(background, soft), 0.2);
                margin: 0 0 15px 0;
                position: relative;

                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  height: 8px;
                  width: 8px;
                  background: cc_getColor(blanc);
                  border-radius: 50%;
                }
              }

              p {
                font-size: $sub;
                color: cc_getColor(blanc);
                letter-spacing: 0.2px;
                width: 75%;
                margin: 0 auto;
              }
            }
          }
        }
      }

      .certifications-container {
        @extend .certifications;
      }
    }
  }

  &.blue-electric {
    section {
      &.row {
        .video-container,
        .slider-container,
        .figures-container {
          &::after {
            background-color: get_the_color(background, clair);
          }
        }
      }
    }
  }

  &.blue-light {
    section {
      &.row {
        .video-container,
        .slider-container,
        .figures-container {
          &::after {
            background-color: get_the_color(background, soft);
          }
        }
      }
    }
  }

  &.blue-dark {
    section {
      &.row {
        .video-container,
        .slider-container,
        .figures-container {
          &::after {
            background-color: get_the_color(background, fonce);
          }
        }
      }
    }
  }
}

.page,
.single,
.post-type-archive-coworker,
.post-type-archive-job_offer,
.blog,
.category,
.page-template-contact {
  header {
    &.with-figure {
      padding-top: 0;
      padding-bottom: 0;
      max-width: 100%;

      &::before {
        content: "";
        display: block;
        padding-top: 28%;
      }

      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 0;
      }
      .absolute-title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 1;
        h1 {
          color: cc_getColor(blanc);
          text-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
        }
      }

      figure {
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        width: 100vw;
        transform: translate(-50%, 0);

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          font-family: "object-fit: cover;";
        }
      }

      .row-breadcrumb {
        position: absolute;
        left: 50%; //6px;
        transform: translateX(-50%);
        top: 0;
        z-index: 1;
        opacity: 0.8;
        .breadcrumb span,
        .breadcrumb a {
          color: cc_getColor(blanc);
        }
      }
    }
  }

  header + section {
    &.row {
      padding-top: 135px;
      h1 {
        margin-bottom: 45px;
      }
      .text-container {
        h2 {
          margin: 0 0 50px;
        }
      }
    }
  }
  // section.row h1 {
  //     margin-bottom: 45px;
  // }
  header.with-figure + section,
  header.with-figure + #menu-menu-rejoindre + section {
    &.row {
      padding-top: 90px;
    }
  }
}

.page-template-rejoindre,
.post-type-archive-job_offer {
  font-weight: 400;
  font-size: 16px;
  header {
    &.with-figure {
      padding-top: 0;
      padding-bottom: 0;
      max-width: 100%;
      position: relative;
      min-height: 550px;
      &::before {
        padding-top: 30%;
      }
      &:after {
        content: inherit;
      }
      .absolute-title {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        text-align: left;
        z-index: 1;
        p, h1 {
          color: cc_getColor(content, texte);
          text-shadow: 0 0 10px rgba(255,255,255,0.9);
          font-size: 90px;
          line-height: 90px;
          letter-spacing: -2px;
          font-family: "market_ot";
        }
      }
      figure {
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        width: 100vw;
        transform: translate(-50%, 0);

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          font-family: "object-fit: cover;";
        }
      }
      .row-breadcrumb {
        position: absolute;
        left: 50%; //6px;
        transform: translateX(-50%);
        top: 0;
        z-index: 1;
        opacity: 0.8;
        .breadcrumb span,
        .breadcrumb a {
          color: cc_getColor(content, texte);
        }
      }
    }
  }
  #menu-menu-rejoindre {
    width: 100%;
    display: inline-flex;
    background-color: cc_getColor(rouge);
    padding: 30px 0;
    justify-content: center;
    list-style: none;
    li {
      padding: 0 40px;
      a {
        color: cc_getColor(blanc);
        font-size: 20px;
        font-weight: 700;
        position: relative;
        text-transform: uppercase;
      }
      &.active {
        a {
          &::before {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: cc_getColor(blanc);
          }
        }
      }
    }
  }
  // .deco-rejoindre {
  //     position: absolute;
  //     width: 100vw;
  //     left: 50%;
  //     top: 0;
  //     bottom: 0;
  //     transform: translateX(-50%);
  //     display: flex;
  //     flex-direction: column;
  //     align-content: space-around;
  //     img {

  //     }
  // }
  .text-container {
    .is-style-intro {
      font-size: 26px;
    }
    font-size: 22px;
  }
  .main-content {
    padding-top: 60px;
    h1 {
      text-align: left;
      position: relative;
      font-family: "montserrat_semi";
      font-size: 65px;
      &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 150px;
        background-color: cc_getColor(bleu);
        height: 6px;
        border-radius: 30px;

      }
      &.has-text-align-center {
        text-align: center;
        &::before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .join-us {
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: -1;
    }
    .content-container {
      padding: 100px 15px;
    }
    .content {
      background-color: rgba(cc_getColor(rouge), 0.85);
      border-radius: 20px 20px 300px 20px;
      padding: 50px;
      width: 65%;
      .titre {
        text-align: left;
        color: cc_getColor(blanc);
        font-weight: 400;
        font-family: "montserrat";
        font-size: 60px;
        text-transform: initial;
        letter-spacing: -2px;
        line-height: 60px;
      }
      .sous-titre {
        color: cc_getColor(blanc);
        font-size: 35px;
        font-weight: 400;
        font-family: "montserrat";
      }
      .cta-rounded {
        font-size: 24px;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 11px 11px 11px 35px;
        span {
          margin-right: 15px;
        }
        .icon {
          padding: 15px;
          background-color: cc_getColor(bleu_sombre);
          border-radius: 40px;
          width: 55px;
          height: 55px;
          position: relative;
          margin: 0;
        }
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 24px;
          height: auto;
          fill: cc_getColor(blanc);
          transition: $trans;
        }
        &:hover {
          svg {
            rect {
              fill: cc_getColor(bleu_ciel);
            }
            #Ellipse_30 {
              stroke: cc_getColor(bleu_ciel);
            }
          }
        }
      }
    }
  }
}

section.row h1 {
  margin-bottom: 45px;
}

.single-job_offer {
  #menu-menu-rejoindre {
    width: 100%;
    display: inline-flex;
    background-color: cc_getColor(rouge);
    padding: 30px 0;
    justify-content: center;
    list-style: none;
    li {
      padding: 0 40px;
      a {
        color: cc_getColor(blanc);
        font-size: 20px;
        font-weight: 700;
        position: relative;
        text-transform: uppercase;
      }
      &.active {
        a {
          &::before {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: cc_getColor(blanc);
          }
        }
      }
    }
  }
  section.row h1 {
    @media (min-width: 993px) {
      margin-bottom: 80px;
    }
  }
}
