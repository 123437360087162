
/* ------ ANIMATIONS ------ */
  
  @keyframes fadeInCustom {
    from {
        opacity: 0;
    }
    to {
        opacity: 1
    }
  }
  @-webkit-keyframes fadeInCustom {
    from {
        opacity: 0;
    }
    to {
        opacity: 1
    }
  }

  @keyframes fadeInLeftCustom {
    from {
        // transform: translate3d(0,0,40px);
        left: -100px;
    }
    to {
        // transform: translate3d(0,0,0);
        left: 0;
        opacity: 1;
    }
  }
  @-webkit-keyframes fadeInLeftCustom {
    from {
        transform: translate3d(0,0,40px);
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
  }

  @keyframes fadeInRightCustom {
    from {
        transform: translate3d(40px,0,0);
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }
  @-webkit-keyframes fadeInRightCustom {
    from {
        transform: translate3d(40px,0,0);
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }

  @keyframes fadeInUpCustom {
    from {
        transform: translate3d(0,40px,0);
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }
  @-webkit-keyframes fadeInUpCustom {
    from {
        transform: translate3d(0,40px,0);
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }
  
  
  // ?? ANIMATION MENU XXL
  
//   @-webkit-keyframes slide-in-bottom {
//     0% {
//       -webkit-transform: translateY(25px);
//               transform: translateY(25px);
//       opacity: 0;
//     }
//     100% {
//       -webkit-transform: translateY(0);
//               transform: translateY(0);
//       opacity: 1;
//     }
//   }
//   @keyframes slide-in-bottom {
//     0% {
//       -webkit-transform: translateY(25px);
//               transform: translateY(25px);
//       opacity: 0;
//     }
//     100% {
//       -webkit-transform: translateY(0);
//               transform: translateY(0);
//       opacity: 1;
//     }
//   }
  
//   @-webkit-keyframes fade-out {
//     0% {
//       -webkit-transform: translateY(0);
//               transform: translateY(0);
//       opacity: 1;
//     }
//     100% {
//       -webkit-transform: translateY(25px);
//       transform: translateY(25px);
//       opacity: 0;
//     }
//   }
//   @keyframes fade-out {
//     0% {
//       -webkit-transform: translateY(0);
//               transform: translateY(0);
//       opacity: 1;
//     }
//     100% {
//       -webkit-transform: translateY(25px);
//       transform: translateY(25px);
//       opacity: 0;
//     }
//   }
  