
body {
    padding-top: 115px; //150px;
}

.breadcrumb {
    margin: 10px 0 80px 0;
    font-size: $label;
    // letter-spacing: .8px;

    a, span {
        display: inline-block;
        vertical-align: top;
        color: cc_getColor(content, texte);
        margin: 0 10px;

        &:first-child {
            margin: 0 10px 0 0;
        }
    }
}

.sprite {
    background-image: $sprite;
    background-repeat: no-repeat;
    background-color: transparent;
}

.home, .category-actualites, .category-news, .page-template-expertise, .page-template-contact {
    @media (min-width: 1121px) {
        .div-prllx-v:after {
            top: -8px;
            bottom: unset;
            transform: translateY(var(--translateY));
            will-change: transform;
        }
        .prllx-v {
            position: absolute;
            transform: translateY(var(--translateY));
            will-change: transform;
        }
        .prllx-h {
            position: absolute;
            transform: translateX(var(--translateX));
            will-change: transform;
        }
    }
}

.recherche-aside {
    .sub-menu.sub-menu.level-2 {
        justify-content: center;
    }
    form {
        margin: 40px 0 40px 0;
        fieldset {

            input {
                background-image: url(../../img/sprite/picto-loupe-bleu.png);
                background-size: 22px 24px;
                background-position: 20px center;
                border: 1px solid cc_getColor(background, fonce);
                // padding: 15px 35px 15px 65px;
                padding: 16px 23px 15px 60px;
                color: cc_getColor(background, fonce);

                &::placeholder {
                    color: cc_getColor(background, fonce);
                }
            }

            button {
                background: cc_getColor(background, fonce);
                color: cc_getColor(blanc);
                border: 1px solid cc_getColor(background, fonce);
                border-left: unset;
                transition: all 0.3s ease-in-out;
                padding: 18px 25px;
                @-moz-document url-prefix() {
                    padding: 17px 25px;
                }
                &:hover {
                    cursor: pointer;
                    background: cc_getColor(blanc);
                    color: cc_getColor(background, fonce);
                }
            }

            .autocomplete {
                border: 1px solid cc_getColor(blanc);
                background: cc_getColor(background, fonce);
                padding: 30px 60px 0;
                color: cc_getColor(blanc);
            }
        }
    }

    p {
        font-size: 16px;
        color: cc_getColor(background, fonce);
    }

}
.recherche, .recherche-aside {
    position: relative;
    text-align: center;
    form {
        font-size: 0;
        position: relative;

        fieldset {
            position: relative;

            input {
                @extend .sprite;
                padding: 15px 35px 15px 65px;
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 101px);
                font-weight: 200;
                font-size: $paragraphe;
                font-family: 'IBM Plex Sans', sans-serif;
                z-index: 3;
                position: relative;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
            }

            .autocomplete {
                position: absolute;
                top: calc(100% - 1px);
                left: 0;
                width: calc(100% - 1px);
                z-index: 3;
                border: 1px solid cc_getColor(blanc);
                background: cc_getColor(background, fonce);
                padding: 30px 60px 0;
                color: cc_getColor(blanc);
                font-size: $paragraphe;
                text-align: left;
                max-height: 500px;
                overflow-y: auto;
                display: none;

                h3 {
                    font-weight: 500;
                }

                ul {
                    margin: 0 0 40px 0;

                    li {
                        margin: 20px 0 0 0;
                        cursor: pointer;
                    }
                }
            }
        }
        button {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
        }
    }
    background-position: -2px -143px;
    p {
        font-size: $paragraphe;
        font-weight: 500;
        // font-style: italic;
        // letter-spacing: .2px;
        color: cc_getColor(blanc);
        text-align: center;

        &.result {
            font-style: normal;
            font-size: $titre3;
        }
    }
}
.recherche {
    padding-top: 140px;
    padding-bottom: 130px;
    @extend .background-fonce;

    .close {
        position: absolute;
        top: 0;
        right: -2px;
        cursor: pointer;
        z-index: 3;

        p {
            text-transform: uppercase;
            font-size: $label;
            letter-spacing: 1.5px;
            font-style: normal;
            display: inline-block;
            vertical-align: middle;
        }

        & > div {
            display: inline-block;
            vertical-align: middle;
            height: 40px;
            width: 40px;
            position: relative;

            span {
                display: block;
                width: 20px;
                height: 1px;
                background: cc_getColor(blanc);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);

                &:first-child {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }

    h2 {
        @extend .outline;
        text-transform: uppercase;
    }

    form {
        margin: 80px 0 50px 0;

        fieldset {

            .overlay {
                position: fixed;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 2;
                cursor: pointer;
                display: none;
            }

            input {
                background-position: -2px -143px;
                border: 1px solid cc_getColor(blanc);
                padding: 15px 35px 15px 65px;
                color: cc_getColor(blanc);
                font-weight: 200;
                font-size: $paragraphe;
                z-index: 3;
                position: relative;

                &::placeholder {
                    color: cc_getColor(blanc);
                }
            }

            button {
                background: cc_getColor(blanc);
                padding: 18px 25px;

                border: 1px solid cc_getColor(blanc);
                border-left: unset;
                transition: all 0.3s ease-in-out;
                &:hover {
                    cursor: pointer;
                    background: cc_getColor(background, fonce);
                    color: cc_getColor(blanc);
                }
            }

            .autocomplete {
                position: absolute;
                top: calc(100% - 1px);
                left: 0;
                width: calc(100% - 1px);
                z-index: 3;
                border: 1px solid cc_getColor(blanc);
                background: cc_getColor(background, fonce);
                padding: 30px 60px 0;
                color: cc_getColor(blanc);
                font-size: $paragraphe;
                text-align: left;
                max-height: 500px;
                overflow-y: auto;
                display: none;

                h3 {
                    font-weight: 500;
                }

                ul {
                    margin: 0 0 40px 0;

                    li {
                        margin: 20px 0 0 0;
                        cursor: pointer;
                    }
                }
            }
        }

        .reset {
            position: absolute;
            right: -50%;
            top: 0;
            display: block;
            padding: 15px 25px 15px 55px;
            border: 1px solid cc_getColor(blanc);
            color: cc_getColor(blanc);
            // font-weight: 200;
            text-transform: none;
            font-size: $paragraphe;
            font-family: 'IBM Plex Sans', sans-serif;
            &::before {
                @extend .sprite;
                content: '';
                display: block;
                position: absolute;
                left: 19px;
                top: 14px;
                background-position: -67px -20px;
                width: 18px;
                height: 20px;
            }
            &.cta {
                margin-top: 0;
                &:hover {
                    background-color: cc_getColor(blanc);
                    color: cc_getColor(content, texte);
                    &:before {
                        filter: brightness(0.5);
                    }
                }
            }
        }
    }

    p {
        font-size: $paragraphe;
        font-weight: 200;
        font-style: italic;
        letter-spacing: .2px;
        color: cc_getColor(blanc);
        text-align: center;

        &.result {
            font-style: normal;
            font-size: $titre3;
        }
    }
}

/*aside.recherche {
    position: fixed;
    left: 50%;
    top: 150px;
    z-index: 9;
    transform: translate(-50%, -150%);
    transition: transform 1s cubic-bezier(0.32, 0.9, 0, 1);

    form {

        .reset {
            display: none;
        }
    }

    &.visible {
        transform: translate(-50%, 0%);
    }

    &.hidden {
        transform: translate(-50%, -150%);
    }
}*/

.chiffres-cles-styles {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 100%;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        z-index: -1;
    }
    .liste-chiffres-cles {
        padding-left: 15px;
        padding-right: 30px;
        p {
            width: 100%;
            text-shadow: 0 0 15px rgba(0,0,0,0.1);
            margin-top: -20px;
            margin-bottom: 0;
            span.chiffre { font-size: 40px; font-weight: 600; display: block; }
            span.texte { font-size: 32px; font-weight: 400; line-height: 3.25rem; }

            // ?? Couleur du texte
            &.blanc { color: cc_getColor(blanc); }
            &.orange { color: $orange; }
            &.bleu { color: cc_getColor(bleu_ciel); }

            // ?? Alignement du texte
            text-align: center;
            &.left { padding-right: 50%; }
            &.right { padding-left: 50%; }
        }
    }
}

hr, hr.separator, .wp-block-separator {
    opacity: 1;
    box-shadow: unset;
    border: none;
    height: 1px;
    position: relative;
    background-color: transparent;
    background-image: $hr-separator-bg;
    background-position: right center;
    background-repeat: repeat;
    margin-top: 50px;
    margin-bottom: 50px;
    @media (max-width: 1200px) {
        max-width: calc(100% - 80px);
    }
    @media (max-width: 768px) {
        max-width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        padding: 0;
    }
}

.cta, .cta-clair, .cta-rounded, .cta-soft, .cta-orange {
    display: inline-block;
    vertical-align: top;
    padding: 10px 30px;
    font-size: $paragraphe;
    border-radius: 30px;
    letter-spacing: .2px;
    font-weight: 500;
    text-transform: uppercase;
    color: cc_getColor(content, texte);
    margin: 25px 0 0 0;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    background-color: transparent;
    border: 1px solid cc_getColor(content, texte);

    overflow: hidden;

    // &::before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     height: 100%;
    //     width: 100%;
    //     background: transparent; //cc_getColor(background, cta);
    //     z-index: -2;
    // }

    // &::after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     height: 100%; //calc(100% - 2px);
    //     width: 0; //calc(100% - 2px);
    //     background: cc_getColor(content, texte);// cc_getColor(background, fonce);
    //     z-index: -1;
    //     transition: width 1s cubic-bezier(0.38, 0.82, 0, 1); //opacity
    // }
}

.cta-clair {
    color: cc_getColor(blanc); //cc_getColor(content, texte);
    border: 1px solid cc_getColor(blanc);
    transition: color 1s cubic-bezier(0.38, 0.82, 0, 1);
    &::before {
        // fonce // cc_getColor(background, ctaClair);
        background: transparent;
    }
    &::after {
        background: cc_getColor(background, cta); // cc_getColor(blanc);
    }
}

.cta-rounded {
    color: cc_getColor(blanc); //cc_getColor(content, texte);
    border: 1px solid cc_getColor(bleu_ciel);
    background-color: cc_getColor(bleu_ciel);
    border-radius: 50px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    &::before, &::after { display: none; }
}
.cta-orange {
    color: cc_getColor(blanc); //cc_getColor(content, texte);
    border: 1px solid cc_getColor(orange);
    background-color: cc_getColor(orange);
    border-radius: 50px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    &::before, &::after { display: none; }
}
.cta-service {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    color: cc_getColor(blanc);
    border-radius: 63px;
    padding: 35px 100px 35px 20px;
    font-size: 22px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;

    vertical-align: top;
    letter-spacing: .2px;
    text-transform: uppercase;
    // margin: 25px 0 0 0;

    overflow: hidden;
    &::before { display: none; }
    &::after {
        content: '+';
        position: absolute;
        right: 23px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-weight: 100;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        // line-height: 0;
        padding: 5px 10px 15px;
        color: cc_getColor(blanc);
        background-color: rgba(0,0,0,0.5);
        transition: all 0.3s ease-in-out;
    }
    &.service--1 {
        border: 1px solid $orange;
        background-color: $orange;
    }
    &.service--2 {
        max-width: 350px;
        padding: 35px 120px 35px 30px;
        border: 1px solid cc_getColor(bleu_ciel);
        background-color: cc_getColor(bleu_ciel);
    }
}

.cta-soft {
    color: cc_getColor(content, texte);
    &::before {
        background: cc_getColor(background, ctaClair);
    }
    &::after {
        background: cc_getColor(background, soft);
    }
}

.more {
    display: inline-block;
    color: cc_getColor(content, texte);
    text-decoration: underline;
    font-size: $paragraphe;
    transition: all .85s cubic-bezier(0.3, 0.83, 0, 1);
}

.background-fonce {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 100%;
        background-color: cc_getColor(background, fonce);
        z-index: -1;
    }
}

.background-clair {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 100%;
        background: cc_getColor(background, clair);
        z-index: -1;
    }
}

.background-soft {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: 100vw;
        height: 100%;
        background: cc_getColor(background, soft);
        z-index: -1;
    }
}

.titre {
    font-size: $titre1;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-weight: 300;
    position: relative;
    z-index: 1;
    &.spaced { letter-spacing: 8px; }
}
.outline, .outline-clair, .outline-soft {
    color: cc_getColor(blanc);
    font-size: $titre1;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 300;
    position: relative;
    z-index: 1;
    &::after {
        content: attr(data-title);
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: $outline;
        font-family: $serif;
        white-space: nowrap;
        text-transform: uppercase;
        z-index: -1;
        font-weight: 500;
        color: cc_getColor(background, fonce);
        text-shadow: -1px -1px 0 cc_getColor(blanc),
        1px -1px 0 cc_getColor(blanc),
        -1px 1px 0 cc_getColor(blanc),
        1px 1px 0 cc_getColor(blanc);
        opacity: .15;
        letter-spacing: 15.5px;
    }
}

.outline-clair {
    &::after {
        color: cc_getColor(background, clair);
    }
}

.outline-soft {
    color: cc_getColor( content, texte );
    &::after {
        color: cc_getColor( background, soft );
        text-shadow: -1px -1px 0 cc_getColor(content, texte),
        1px -1px 0 cc_getColor(content, texte),
        -1px 1px 0 cc_getColor(content, texte),
        1px 1px 0 cc_getColor(content, texte);
    }
}

.text-container {
    // @extend .text-styles;
    font-size: $paragraphe$i;
    line-height: normal;
    // line-height: 1.63em;
    // letter-spacing: .8px;
    strong {
        font-weight: 500;
    }
    &.is-style-two-columns, &.is-style-intro {
        column-count: 2;
        column-gap: 12px;
    }
}

.center-cta {
    text-align: center;
}

.testimonies {
    h2 {
        text-transform: uppercase;
        font-size: $titre1;
        letter-spacing: 2px;
        width: 40%;
        margin: 0 0 0 10%;
    }
    .slider-wrapper {
        margin: 40px 0;
        overflow: hidden;
        transition-timing-function: cubic-bezier(0.43, 0.72, 0, 1);

        .slider-temoignages {
            margin: 10px 0;
            position: relative;
            overflow: visible;
            background: cc_getColor( blanc );
            &::before, &::after {
                @extend .sprite;
                content: '';
                display: block;
                position: absolute;
                left: 15%;
                top: -9px;
                height: 18px;
                width: 25px;
                z-index: 2;
                background-position: -298px -20px;
            }
            &::after {
                left: auto;
                top: auto;
                right: 20%;
                bottom: -9px;
                transform: rotate(180deg);
            }

            .controls {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 10%;
                background: cc_getColor(background, fonce);
                z-index: 2;

                & > div {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    .prev, .next {
                        @extend .sprite;
                        background-position: -17px -15px;
                        display: block;
                        cursor: pointer;
                        width: 33px;
                        height: 19px;
                        margin: 10px auto;
                    }

                    .prev {
                        transform: rotate(180deg);
                    }
                }
            }

            .swiper-slide {
                background: cc_getColor(blanc);
                .wrapper {
                    position: relative;
                    padding: 70px 20% 70px 10%;

                    p {
                        font-size: $titre3;
                        letter-spacing: .2px;
                        line-height: 1.56em;
                        font-family: $serif;

                        &.auteur {
                            margin: 20px 0 0 0;
                            font-size: $paragraphe;
                            font-style: italic;
                            letter-spacing: .2px;

                            span {
                                font-weight: 400;
                                font-style: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}

.certifications {
    font-size: 0;
    .title {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
    .slider-certifications .swiper-wrapper {
        align-items: center;
        @media (min-width: 1026px) {
            overflow: hidden;
        }
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .center-cta .cta {
        margin-top: 45px;
        @media (min-width: 768px) and (max-width: 1200px) { margin-top: 30px; }
    }
}

.actualite {
    background: cc_getColor( blanc );
    position: relative;
    box-shadow: 0 0 15px rgba(0,0,0,0.16);
    // .time {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: calc(100% / 5 * .5);
    //     height: 100%;

    //     time {
    //         position: absolute;
    //         left: 50%;
    //         top: 50%;
    //         transform: translate(-50%, -50%) rotate(-90deg);
    //         letter-spacing: 3px;
    //         font-size: $sub;
    //         white-space: nowrap;
    //     }
    // }
    .content {
        // width: calc(100% / 5 * 4.5);
        // margin: 0 0 0 calc(100% / 5 * .5);
        // padding: 0 0 100px 0;
        transition: all 0.3s ease-in-out;
        figure {
            position: relative;
            margin: 0 0 25px 0;

            &::before {
                content: '';
                display: block;
                padding-top: 80%; //68
            }

            > div {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                overflow: hidden;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                    transition: transform .5s ease-out;
                    transform: scale(1);
                }
            }
            figcaption {
                position: absolute;
                left: 20px;
                bottom: -25px;
                z-index: 1;
                border-radius: 50px;
                padding: 8px 10px;
                color: #ffffff;
                background-color: $orange;
                // transition: all 0.3s ease-in-out;
                time {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .text-container {
            padding: 15px 30px 20px 20px;

            h3 {
                font-size: 2.1rem;
                font-weight: 500;
                margin: 0 0 5px 0;
                text-align: left;
                width: auto;
                line-height: normal;
            }

            p {
                font-size: $sub;
                letter-spacing: .4px;
                line-height: 1.71em;
                text-align: left;
            }
        }
        .global-link {
            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;
            z-index: 1;
        }
    }
}

.pagination {
    text-align: center;

    h2 {
        display: none;
    }

    span, a {
        display: inline-block;
        vertical-align: top;
        font-size: $sub;
        letter-spacing: .9px;
        font-weight: 400;
        padding: 10px;
        position: relative;
        color: cc_getColor( content, texte );
        opacity: .5;

        &::before, &::after {
            content: '';
            display: block;
            height: 2px;
            width: 2px;
            border-radius: 50%;
            background: cc_getColor( background, fonce );
            opacity: 0;
            margin: 5px auto;
        }

        &.prev, &.next {
            display: none;
        }

        &.current {
            opacity: 1;

            &::before, &::after {
                opacity: 1;
            }
        }
    }
}

.simplebar-track.simplebar-vertical {
    width: 10px;
}

.simplebar-scrollbar {
    width: 6px;

    &::before {
        background-color: cc_getColor(background, clair);
        border: 1px solid cc_getColor(background, fonce);
    }
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
}