/**
 * Get the color
 *
 * Get color from $color-variations map, inspired by WordPress and his the_title/get_the_title functions.
 * 
 * Thanks to Hugo Giraudel, as usual, master of Sass. ð

 * @access  public
 * @author 	JÃ©rÃ©my Levron <levronjeremy@19h47.fr>
 * @edited for Couleur Citron  Benjamin Costes <https://twitter.com/benjam1nC>
 */
@function get_the_color( $keys... ) { 
    
    $map: $color-variations;
    $color-base: map-get( $map, nth( $keys, 1 ) );
    $result: null;

    @if length( $keys ) == 1 {

        $result: $color-base;
    }

    @if length( $keys ) == 2 {

        $color-key: nth( $keys, 1 );
        $color-value: nth( $keys, 2 );

        $result: map-get( $color-base, $color-value );
    }

    @return $result;
}


/**
 * The color
 * 
 * Retrieve the color from $color-variations map
 *
 * @param  arglist      $keys
 * @return function     get_the_color()
 * @author JÃ©rÃ©my Levron<levronjeremy@19h47.fr>
 */
@function cc_getColor( $keys... ) {
    @return get_the_color( $keys... );
}