.post-type-archive-capability {
    padding-top: 110px;
	
	section {

		&.row {
			h1 {
				color: cc_getColor(blanc);
			}
			.table-container {
			    min-height: 200px;

				form {
					margin: -60px 0 0 0;
					position: relative;
					z-index: 1;
					text-align: center;

					table {
						background: cc_getColor( blanc );
						border-collapse: collapse;
    					border-spacing: 0;
    					box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, .2);
    					margin: 0 0 80px 0;
					    width: 100%;

						thead {
							box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, .2);

							tr {

								th {
									padding: 10px;
									font-weight: 300;
									font-size: $paragraphe;
									letter-spacing: .8px;
									text-align: center;
								}
							}
						}

						tbody {

							tr {

								&:nth-child(odd) {
									background: #f0f2f4 ; // cc_getColor( background, soft );
								}

								td {
									text-align: center;
									padding: 25px 10px;
									font-size: $sub;
									position: relative;

									&:not(:first-child) {
										text-transform: uppercase;

										&::before {
											content: '';
											display: block;
											width: 1px;
											height: calc(100% - 20px);
											position: absolute;
											left: 0;
											top: 10px;
											background: cc_getColor( background, fonce ); //cc_getColor( background, clair )
										}
									}

									label {
										display: block;
										height: 16px;
										width: 16px;
										border: 1px solid cc_getColor( background, fonce );
										border-radius: 4px;
										position: relative;

										&::before {
											content: '';
											display: block;
											position: absolute;
											left: 2px;
											top: 2px;
											height: 10px;
											width: 10px;
											background: cc_getColor( background, fonce );
											border-radius: 4px;
											opacity: 0;
											transform: scale(.8);
											transition: transform .2s ease-out, opacity .2s ease-out;
										}
									}

									input {
										position: absolute;
										left: 50%;
										top: 50%;
										transform: translate(-50%, -50%);
										opacity: 0;
										height: 16px;
										width: 16px;

										&:checked + label {

											&::before {
												opacity: 1;
												transform: scale(1);
											}
										}
									}
								}
							}
						}
					}

					.cta {
						// @extend .cta-clair;
						cursor: pointer;
						// outline: none;
					    margin: 25px 0 50px 0;
					}
				}

				.pagination {
					margin: 50px 0;
				}
			}
		}
	}
}