// Tablette

@media only screen and (max-width: 1600px) {
    nav .nav {
        &.col-8 {
            width: 75%;
        }
    }

	.recherche {

		.close {
			right: 4px;
			z-index: 3;
		}

		form {

			// .reset {
			// 	position: relative;
			//     right: auto;
			//     margin: 10px 0 0 0;
			// }
		}
	}

	.home {
		padding-top: 82px;
		.bandeau-principal {
			height: calc(100vh - 100px);
			.zone-titre {
				height: 100%;
				padding-bottom: 180px;
				.titre-principal {
					font-size: 40px;
					padding-left: 30px;
					// padding-right: 50px;
					// &:after {
					// 	bottom: -40px;
					// 	width: 90px;
					// }
				}
			}
			#layers-bp-hp {
				width: 110%;
				.masks-layers {
					flex: 1;
					&:after {
						height: 100%;
						width: 215px;
						right: -180px;
					}
					&:before {
						bottom: 35px;
						background-size: contain;
						background-position: unset;
						height: 165px;
						width: 160px;
					}
					#blue-circle-svg {
						top: 85px;
						right: -80px;
						width: 90px;
						height: auto;
					}
					#tirets-decoratifs {
                        right: -130px;
                        width: 34%;
					}
				}
				.visuel-bp-hp {
					flex: 1;
					img { max-width: 100%; transform: translate(-20px, -50px); }
					&:before {
						background-repeat: no-repeat;
						max-width: 100%;
						// left: 50%; transform: translateX(-50%); // !! responsive ?
						right: 50px;
						width: 629px;
						height: 393px;
					}
					&:after { content: none; }
				}
			}
		}
		
		section {
			&.services {
				// &:after { content: none; }
				.line { flex-wrap: nowrap; }
				// .wrapper {
				// 	bottom: 30px;
				// 	max-width: 320px; //!! 12345
				// 	&.left-cta { 
				// 		left: 20%;
				// 		transform: translateX(-20%);
				// 	 }
				// 	&.right-cta { 
				// 		right: 20%;
				// 		transform: translateX(20%);
				// 	 }
				// }
				// [class$="-shape"] {
				// 	height: 457px;
				// 	width: 250px;
				// }
				// .left-shape {
				// 	border-radius: 250px 0 0 250px;
				// 	// margin-bottom: 50px;
				// }
				// .right-shape {
				// 	border-radius: 0 250px 250px 0;
				// 	// margin-top: 50px;
				// }
			}
			&.chiffres-cles {
				padding-top: 50px;
				padding-bottom: 50px;
				// &::after {
					// background-size: 250%;
				// }
				h2.titre { font-size: 3.3rem; margin-bottom: 80px; }
				&.chiffres-cles-styles {
					.liste-chiffres-cles {
						padding-left: 50px;
						padding-right: 50px;
						p {
							margin-bottom: 1.25rem;
							&.left { padding-right: 40%; }
							&.right { padding-left: 40%; }
						}
					}
				}
			}
			&.actualites {
				.slider-actualites {
					.controls {
						.prev { left: 25%; }
						.next { left: 75%; }
					}
					.swiper-slide {
						width: 50%;
						.wrapper { margin: 0 calc(100% / 12); }
					}
				}
			}
		}
	}

	.page {
		header {
			&.row {
				.title {
					h1 {
						margin: 40px 10%;
					}
				}
			}
		}

		section {
			&.row {
			    // padding-top: 65px;
				.text-container {
					// padding: 0;
					ul, ol {
						margin: 0 0 40px;
					}
				}
			}
		}
	}

	.page-template-metiers {
		header {
			&.row {
				.title {
					h1 {
						margin: 40px 10%;
					}
				}
			}
		}

		section {
			&.row {
				.pictos {
				    margin: 100px calc(-100% / 8 * .5) 0;
				}
				.savoir-faire {
					.savoir-container {
						.savoir {
							width: 100% !important;
							&::before {	content: none;	}
							.img { width: 100vw; }

							.wrapper {
								position: relative;
								padding: 40px calc(100% / 12);
								h3 {
									margin: 0 0 10px 0;
								}
							}
						}
					}

					.savoir-content {
						padding: 80px 0 50px;

						h2 {

							.cpt {
								position: relative;
							    left: auto;
							    top: auto;
							    display: block;
							}
						}

						.up {
							margin: 85px 0 0 calc(10% + 6px);
						}
					}
				}
			}
		}
	}

	.page-template-contact {

		section {

			&.row {
				padding-top: 0;

				&::after {
	                content: none;
	            }

				.form {
					position: relative;
					// height: 240px;
					height: auto !important;
					// padding: 0 calc(100% / 12 + 6px);

					/*&::after {
		                content: '';
		                display: block;
		                position: absolute;
		                left: 0;
		                bottom: 0;
		                width: 100%;
		                height: 50px;
		                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
		                pointer-events: none;
		            }*/

		            form {
		            	margin: 10px 0 50px;
		            }
				}

				.map-container {

					&::before {
						left: -6px;
						width: 100vw;
					}

					.map {
						margin: 0 -12px;
						width: 100vw;
					}
				}
			}
		}
	}

	.page-template-rejoindre,
	.post-type-archive-job_offer {
		header {
			&.with-figure {
				.absolute-title {
					p, h1 {
						font-size: 90px;
					}
				}
			}
		}
		#menu-menu-rejoindre {
			li {
				padding: 0 30px;
				a {
					font-size: 18px;
				}
			}
		}
	}
	
	.post-type-archive-capability {
		padding-top: 82px;

		section {

			&.row {

				&.recherche {
				    padding-bottom: 80px;
				}

				.table-container {

					form {
						margin: -40px 0 0 0;
					    overflow-x: scroll;
					}
				}
			}
		}
	}

	.post-type-archive-coworker {

		section {

			&.row {

				& > div {
					margin-bottom: 0;

					figure {
						width: calc(100% / 12 * 4);
					}
				}
			}
		}
	}
  
  .block--mosaique {
    margin: 0;
  }

	.swiper-pagination-bullets:not(.pagination-engagement, .pagination-slider-card) {
		width: 100%;
		text-align: center;
		margin-top: 40px;
		.swiper-pagination-bullet {
			background: transparent;
			border: 5px solid transparentize(cc_getColor( bleu_ciel ), 0.5);
			border-radius: 50%;
			margin: 0 6px;
			opacity: 1;
			transition: border-color 0.3s ease;
			&.swiper-pagination-bullet-active {
				border: 5px solid cc_getColor( content, texte );
			}
			&:hover {
				border-color: cc_getColor( bleu_ciel );
				cursor: pointer;
			}
		}
	}
}

@media only screen and (max-width: 1400px) {

	.cta-service {
		font-size: 17px;
		padding: 20px 70px 20px 28px;
		&:after {
			font-size: 42px;
			right: 14px;
			height: 20px;
			width: 20px;
			padding: 7px 10px 12px;
		}
		&.service--2 {
			max-width: 260px;
			padding: 20px 70px 20px 28px;
		}
	}

	nav.row .nav ul > li a, nav.row nav.capabilites .nav ul > li a {
		padding: 20px 0;
		font-size: 1.4rem;
	}

	.home {
		.bandeau-principal {
			height: calc(100vh - 100px);
			.zone-titre {
				height: 100%;
				padding-bottom: 180px;
				.titre-principal {
					font-size: 31px;
					line-height: normal;
					padding-left: 21px;
					padding-right: 60px;
					br:not(:first-of-type) { display: none; }
					&:after {
						bottom: -30px;
						width: 80px;
					}
					@media (max-width: 550px) {
						font-size: 31px;
						padding-right: 150px;
					}
				}
			}
			#layers-bp-hp {
				width: 105%;
				.masks-layers {
					flex: 1;
					&:after {
						height: 100%;
						width: 215px;
						right: -150px;
					}
					&:before {
						bottom: 35px;
						background-size: contain;
						background-position: unset;
						height: 163px;
						width: 160px;
					}
					#blue-circle-svg {
						top: 90px;
						right: -70px;
						width: 71px;
					}
					#tirets-decoratifs {
						right: -55px;
						width: 230px;
					}
				}
				.visuel-bp-hp {
					flex: 1;
					img { max-width: 105%; width: 105%; transform: translateX(-50px); }
					&:before {
						background-repeat: no-repeat;
						max-width: 100%;
						right: 50px;
						width: 629px;
						height: 393px;
					}
					&:after { content: none; }
				}
			}
		}
		section {
			&.services {
				.wrapper {
					bottom: 30px;
					max-width: 290px;
				}
				[class$="-shape"] {
					height: 407px;
					width: 204px;
				}
				.left-shape {
					border-radius: 204px 0 0 204px;
				}
				.right-shape {
					border-radius: 0 204px 204px 0;
				}
			}
			&.chiffres-cles {
				padding-top: 50px;
				padding-bottom: 50px;
				&::after {
					// background-size: 250%;
					// background-position-y: 20px;
				}
				h2.titre { font-size: 3.3rem; margin-bottom: 80px; }
				iframe {
					display: block;
					margin: 50px auto 0;
					// max-height: 312px;
				}
				&.chiffres-cles-styles .liste-chiffres-cles {
					padding-left: 10px;
					padding-right: 10px;
					p {
						// font-size: 32px;
						margin-bottom: 1.25rem;
						// &.left { padding-right: 25%; }
						// &.center { text-align: center; }
						// &.right { padding-left: 25%; }
						&.left, &.center, &.right {
							// text-align: center;
							// padding: 0;
							// margin-bottom: 20px;
							// br { display: none; }
						}
					}
				}
			}
			&.actualites {
				&:after {
					bottom: -76px;
					width: 150px;
					height: 389px;
					background-size: contain;
				 }
				.slider-actualites {
					.swiper-wrapper {
						justify-content: unset;
						gap: unset;
					}
					.controls {
						.prev, .next {
							display: block;
						}
					}
					.swiper-slide {
						width: 100%;
						margin-left: 20px;
						margin-right: 20px;
						.wrapper {
							margin: 0;
						}
					}
				}
			}
		}
		.footer {
			.prefooter:after {
				background-position: 0 101%;
			}
			@media (min-width: 993px) and (max-width: 1300px) {
				.prefooter {
					padding-left: 50px;
					padding-right: 50px;
					p { font-size: 16px; }
				}
				.news .logo img {
					max-width: 200px;
    				transform: translate3d(0,0,0);
				}
			}
		}
	}
	.block--convictions {
		.visuel-container {
			width: 80%;
		}
		.convictions {
			width: 60%;
		}
		&.position-left {
			.visuel-container {
				border-radius: 350px 40px 40px 40px;
			}
		}
		&.position-right {
			.visuel-container {
				border-radius: 40px 350px 40px 40px;
			}
		}
	}
}

@media (max-width: 1300px) {
	nav {
		&.row {
		  .logo {
			a {
			  width: 220px;
			}
		  }
		}
	}
	nav.row .nav ul > li a, nav.row nav.capabilites .nav ul > li a {
		font-size: 1.4rem;
		padding: 20px 0;
		margin: 0 15px;
	}
	.page-template-rejoindre,
	.post-type-archive-job_offer {
	  .join-us {
      .content {
        border-radius: 20px 20px 250px 20px;
        width: 65%;
        .titre {
          font-size: 50px;
        }
        .sous-titre {
          font-size: 28px;
        }
      }
    }
  }

  .block--engagements{
    .engagement {
      >.titre {
        font-size: 16px;
        padding: 25px 15px;
      }
      .content {
        .titre {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }

  .bloc-mediaTextQuotes {
    .mediaTextQuote {
      display: flex;
      position: relative;
      justify-content: flex-end;
      margin-bottom: 70px;
      .visuel-container {
        overflow: hidden;
        z-index: 0;
        width: 75%;
        border-radius: 50px 350px 50px 50px;
      }
      .content-container {
        left: 30px;
        .content {
          padding: 35px;
          .titre {
            font-size: 45px;
            line-height: 45px;
          }
          .sous-titre {
            font-size: 25px;
          }
          p:not(.titre, .sous-titre) {
            font-size: 18px;
          }
        }
        .quote {
          padding: 30px 30px 30px 100px;
          p {
            font-size: 20px;
          }
        }
      }
      &:nth-child(even) {
        justify-content: flex-start;
        .visuel-container {
          border-radius: 350px 50px 50px 50px;
        }
        .content-container {
          right: 30px;
        }
      }
    }
  }
}

@media (max-width:1270px) {

	nav.row .nav ul > li a, nav.row nav.capabilites .nav ul > li a {
		padding: 20px 0;
		margin: 0 15px;
		font-size: 1.4rem;
	}
	nav.row .nav ul > li {
		& > .conteneur {
			left: 15px;
			width: 480px;
			padding: 30px 20px 40px;
			ul.sub-menu.level-2 {
				& > li:not(.menu-item-has-children) > a { 
					padding-bottom: 15px;
					margin-bottom: 15px;
					margin: 0 10px 15px 10px;
				}
				li {
					a {
						padding: 10px;
						font-size: 14px;
					}
				}
				ul.level-3 {
					li a {
						// font-size: 16px;
						// padding: 5px 10px;
					}
				}
			}
		}
    
    &.align-right {
      & > .conteneur {
        left: unset;
        right: 15px;
      }
    }
	}

	.blog, .category {
		header {
			&.row {
				// padding-bottom: 0;
			}
		}

		section {
			&.row {
				.news-list {
					justify-content: center; //space-around
				}
				> div {
					.news {
					    // width: 100%;
						width: 33%;
						flex: 1 1 31%;
						margin: 0 10px 30px;
					}
				}
			}
		}
	}

	.page, .single-post, .single-job_offer  {
		section.row {
			.chiffres-cles-container {
				margin-left: -50px;
				margin-right: -50px;
				&.chiffres-cles-styles {
					padding-top: 50px;
					padding-bottom: 50px;
					.liste-chiffres-cles {
						padding-right: 80px;
						p span.chiffre { font-size: 36px; }
						p span.texte { font-size: 26px; }
					}
				}               
				.timeline {
					.time-point {
						padding-bottom: 25px;
						padding-left: 30px;
						.infos {
							transform: translateY(-10px);
							span { font-size: 28px; }
							p { font-size: 18px; }
						}
					}
				}
			}
		}
	}

	.page-template-expertise {
		#quick-nav {
			margin-bottom: 50px;
			ul {
				margin: 0; padding-left: 0 $i;
				justify-content: space-between;
				li {
					margin: 0 0 10px 0;
					text-align: center;
				}
			}
		}
		&.page section.row {
			.text-container {
				h2:not(.chapeau):not(.is-style-chapeau) {
				   font-size: 34px;
				   margin-top: 0;
				}
				
			}
		}
	}

	.page-template-rejoindre,
	.post-type-archive-job_offer {
		header {
			&.with-figure {
				.absolute-title {
					p, h1 {
						font-size: 75px;
					}
				}
			}
		}
		#menu-menu-rejoindre {
			li {
				padding: 0 25px;
				a {
					font-size: 16px;
				}
			}
		}
	}
  
  .block--mosaique {
    .mosaique {
      justify-content: center;
      gap: 12px;
      .tile {
        width: calc(33.33% - 8px);
        max-width: 350px;
      }
    }
  }
	
}

@media (min-width: 1021px) and (max-width: 1600px) {

	  
	.footer .prefooter {
		.intro {
			@media (max-width: 1270px) {
				p { font-size: 16px; }
			}
			p:empty { display: none; }
		}
		&::after {
			background-image: none;
		}
	}

}