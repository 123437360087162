.page-template-contact, .page-template-contact-gutenberg {

	header.with-figure {
		&:before { padding-top: 21%; }
		&:after { background-color: cc_getColor(background, fonce); }
	}

    header {

        &.row {
            padding-bottom: 80px;

            &::before {
                content: none;
            }

            .breadcrumb {
                position: relative;
            }
        }
    }

    section {

        &.row {
            position: relative;
            padding-top: 0 !important;

            // &::before {
            //     content: '';
            //     display: block;
            //     position: absolute;
            //     left: 50%;
            //     top: 0;
            //     height: 100%;
            //     width: 100vw;
            //     transform: translate(-50%, 0);
            //     box-shadow: 2px 2px 15px 0 rgba(15, 38, 92, 0.2);
            // }

            // &::after {
            //     content: '';
            //     display: block;
            //     position: absolute;
            //     left: 0;
            //     bottom: 0;
            //     width: 50%;
            //     height: 50px;
            //     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
            //     pointer-events: none;
            // }

            .form, form.wpcf7-form {
                position: relative; //a
                left: 0;
                top: 0;
                height: 100%;
                // padding: 0 calc(100% / 12 + 6px);
                overflow-y: auto;

                padding: 0;
                &.wpcf7-form, form {
                    margin: 0 0 80px;

                    .validate {
                        font-size: $titre3;
                        font-weight: 700;
                    }
                }
                .form-detail {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                [class^="col-"] {
                    padding: 0 10px;
                }

                .hidden-field { display: none; }

                fieldset {
                    margin: 10px 0;
                    .select {
                        position: relative;

                        select {
                            appearance: none;
                            border: 1px solid cc_getColor(background, fonce);
                            padding: 10px 20px;
                            font-size: $sub;
                            letter-spacing: .3px;
                            border-radius: 0;
                            background: transparent;
                            color: cc_getColor(content, texte);
                            font-weight: 300;
                            width: 100%;

                            // [value=""], [value=""][selected="selected"] {
                            //     color: #757575;
                            // }
                            &::-ms-expand {
                                display: none;
                            }
                        }

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            height: 6px;
                            width: 6px;
                            border-bottom: 1px solid cc_getColor(background, fonce);
                            border-right: 1px solid cc_getColor(background, fonce);
                            transform: translate(0, -50%) rotate(45deg);
                        }
                    }

                    &.fieldset-file {
                        display: inline-flex;
                        flex-direction: column;
                        width: 100%;
                    }

                    label {
                        display: block;
                        font-weight: 700;
                        font-size: $sub;
                        letter-spacing: .3px;
                        margin: 0 0 10px 0;

                        &.file {
                            position: relative;
                            width: 100%;
                            margin: 0;
                            letter-spacing: 0;
                            // margin: 40px 0 0 0;

                            &::after {
                                // @extend .sprite;
                                background : url('../../img/sprite/upload.png') no-repeat 97% center;
                                // background-size: contain;
                                // background-position: right -18px top -152px;
                                content: attr(data-title);
                                display: block;
                                border: 1px solid cc_getColor(background, fonce);
                                padding: 10px 40px 10px 20px;
                                font-size: $sub;
                                // font-style: italic;
                                font-weight: 400;
                                // margin: 10px 0 0 0;
                                cursor: pointer;
                            }

                            & + input {
                                width: 1px;
                                height: 1px;
                                padding: 0;
                                border: none;
                                opacity: 0;
                                position: relative;
                                top: -14px;
                                left: 21px;
                            }
                        }
                    }

                    input, textarea {
                        border: 1px solid cc_getColor(background, fonce);
                        padding: 10px 20px;
                        font-size: $sub;
                        width: 100%;
                        font-weight: 300;
                        &::placeholder {
                            color: cc_getColor(content, texte);
                        }
                    }

                    .choices__inner {
                        border-color: cc_getColor(background, fonce);
                        background-color: transparent;
                        border-radius: 0;
                        padding: 10px 20px;
                    }

                    .choices__input {
                        width: auto $i;
                        border: none;
                        padding: 0;
                        background-color: transparent;
                        margin: 0px 0 0 5px;
                        vertical-align: middle;
                    }

                    .choices__list--multiple .choices__item {
                        background-color: cc_getColor(background, fonce);
                        border: none;
                        margin-bottom: 0;
                        position: relative;
                        padding: 4px 25px 4px 10px;
                        margin: 0 5px 5px 0;
                    }

                    .choices__button {
                        border: none;
                        position: absolute;
                        top: 50%;
                        right: 12px; // 10px;
                        padding: 0;
                        transform: translate(0, -50%);

                        &::before, &::after {
                            content: none;
                        }
                    }

                    textarea {
                        min-height: 130px;
                        font-family: Arial;
                    }

                    &.header {

                        label {
                            font-size: $titre3;
                            font-family: $serif;
                            letter-spacing: .2px;
                            font-weight: 400;
                        }
                    }
                }

                .rgpd .wpcf7-radio .wpcf7-list-item {
                    width: 100%;
                    // display: flex;
                    // flex-direction: column;
                }

                .form-footer {
                    margin: 30px 0 0 0;

                    fieldset {
                        position: relative;
                        padding: 0 0 0 40px;
                        font-size: $label;

                        label {
                            font-weight: 300;
                            font-size: $label;
                            margin: 0;

                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 16px;
                                width: 16px;
                                border: 1px solid cc_getColor(background, fonce);
                                border-radius: 4px;
                            }

                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 3px;
                                top: 3px;
                                height: 12px;
                                width: 12px;
                                background: cc_getColor(background, fonce);
                                border-radius: 4px;
                                opacity: 0;
                                transform: scale(.8);
                                transition: transform .2s ease-out, opacity .2s ease-out;
                            }

                            a {
                                color: cc_getColor(content, texte);
                            }
                        }

                        input {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 16px;
                            width: 16px;
                            opacity: 0;

                            &:checked + label {

                                &::after {
                                    opacity: 1;
                                    transform: scale(1);
                                }
                            }
                        }

                        p {
                            font-style: italic;
                            margin: 10px 0 0 0;

                            a {
                                color: cc_getColor(content, texte);
                            }
                        }
                    }

                    p, p a {
                        font-size: $label;
                    }

                    &.hidden {
                        display: none;
                    }
                }

                .g-recaptcha {
                    margin: 30px 0 40px 0;
                }

                button:not(.choices__button), .bouton input {
                    @include bouton(cc_getColor(blanc),
                    $orange,
                    $orange,
                    transparent);
                    border: 1px solid $orange;
                    font-size: 15px;

                    cursor: pointer;
                    outline: none;
                    margin: 0 auto 20px;
                    display: block;

                    & + p {
                        font-weight: 500;
                        font-style: italic;
                        font-size: $label;
                    }
                }
            }

            .map-container {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 50vw;
                    background: cc_getColor(background, soft);
                    z-index: -1;
                }

                .map {
                    position: relative;
                    margin-left: -6px;
                    width: 50vw;

                    &::before {
                        content: '';
                        display: block;
                        padding-top: 80%;
                    }

                    > div {
                        position: absolute !important;
                        top: 0;
                        left: 0;
                    }
                }

                .address {
                    padding: 60px calc(100% / 6);
                    font-size: 0;

                    h2 {
                        display: inline-block;
                        vertical-align: top;
                        text-transform: uppercase;
                        font-size: $titre3;
                        line-height: 1.33em;
                        letter-spacing: 1px;
                        width: 50%;
                    }

                    .wrapper {
                        display: inline-block;
                        vertical-align: top;
                        width: 50%;

                        address {
                            font-size: $sub;
                            line-height: 1.43em;
                            letter-spacing: .2px;
                            margin: 0 0 20px 0;
                            font-style: normal;
                        }

                        .phone {
                            font-size: $sub;
                            font-family: $serif;
                            color: cc_getColor(content, texte);
                            font-weight: 400;
                            letter-spacing: .2px;
                            line-height: 1.57em;
                        }
                    }
                }
            }
        }
    }

    footer {

        &.row {

            &::before {
                content: none;
            }
        }
    }
}

.page-template-contact {
    position: relative;
    .prllx-v {
        z-index: 0;
        &.deco-top {
            right: -80px;
            top: 5%;
        }
        &.deco-mid {
            left: 0;
            top: 15%;
        }
    }
}