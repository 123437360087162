header {
    
    &.row {
        // padding-top: 110px;
        padding-bottom: 80px;

    }
}

.page:not(.home), 
.blog, .category, .archive,
.single-post, .single-job_offer  {
    // ?? Style du titre principal, voir rte pour condition avec ou sans visuel
    h1.main-title, h1 {
        font-size: 45px;
        letter-spacing: 0; //2
        text-align: center;
        font-weight: 600;
        small {
            font-weight: 300;
            display: block;
            margin-top: 5px;
        }
    }
}