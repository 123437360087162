nav {
  &.row {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    max-width: none;
    box-shadow: 2px 16px 19px 0 rgba(15, 38, 92, 0.1);
    background: cc_getColor(blanc);
    transition: transform 0.3s ease-out;

    .logo,
    .nav,
    .langue {
      vertical-align: middle;
    }

    .logo {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 0;
      a {
        display: inline-block;
        vertical-align: top;
        margin: 0 50px 0 0;
        transition: all 0.3s ease-out;
        width: 300px;
      }
    }

    .nav {
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        width: 100vw;
        background: cc_getColor(blanc);
        z-index: 0;
        transform: translate(-50%, 0);
      }
      .header-top {
        margin-top: 20px;
      }
      .header-main {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        ul.menu > li > a {
          position: relative;
          &:after {
            content: "";
            height: 8px;
            background-color: cc_getColor(bleu_ciel);
            position: absolute;
            transition: width 0.3s ease-in-out;
            width: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
      .menu > li > a {
        font-weight: 700;
      }

      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        & > li {
          position: relative;

          a {
            font-size: $paragraphe;
            color: cc_getColor(content, texte);
            letter-spacing: 0.6px;
            display: block;
            margin: 0 30px;
            padding: 20px 0;
            text-transform: uppercase;
            transition: all 0.3s ease-out;
          }
          a.cta {
            @extend .cta;
            font-size: 14px;
            padding: 10px 30px;
            margin-top: 0;
            margin-bottom: 0;
          }

          &.cta-rounded-inside {
            margin: 0;
            a {
              @extend .cta-rounded;
              color: cc_getColor(blanc);
              margin: 0;
              padding: 8px 18px;
            }
          }
          &:last-of-type a {
            padding-right: 0;
          }
          @media (min-width: 1020px) {
            & > .conteneur {
              position: absolute;
              left: 30px;
              width: 600px;
              // width: calc(100% + 30px);
              // right: -30px;
              top: 100%;
              padding: 40px 30px 50px;
              z-index: 10;
              opacity: 0;
              transform: translate(0, -100%);
              visibility: hidden;
              transition: transform 1s cubic-bezier(0.32, 0.9, 0, 1);

              z-index: -1;
              display: block;

              &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                height: 100%;
                // width: 100%; //200vw;
                left: 0;
                right: 0;
                background: cc_getColor(blanc);
                box-shadow: 0 10px 10px transparentize(#000000, 0.8);
              }
            }
            ul.sub-menu.level-2 {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-end; // baseline
              flex-direction: row;
              width: 100%;
              p {
                color: cc_getColor(content, texte);
              }
              & > li:not(.menu-item-has-children) > a {
                padding-bottom: 20px;
                margin-bottom: 20px;
                margin: 0 10px 20px 10px;
                border-bottom: 1px dashed #cacaca;
              }
              li {
                width: 50%;
                a {
                  color: cc_getColor(content, texte);
                  padding: 10px;
                  margin: 0;
                  font-size: 15px;
                  font-weight: 800;
                  transition: all 0.3s ease-in-out;
                  // white-space: nowrap;
                }
                &.menu-item-has-children {
                  flex: 1;
                }
                &.full {
                  width: 100%;
                }
              }
              ul.level-3 {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 0;
                padding: 0;
                text-align: left;
                li a {
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 0;
                  margin: 0;
                  padding: 5px 10px;
                  white-space: nowrap;
                  text-transform: none;
                }
              }
            }

            &.reorganize {
              & > .conteneur {
                ul.sub-menu.level-2 {
                  display: inline-block;
                  li {
                    float: left;
                  }
                  li:not(.menu-item-has-children) {
                    flex: 1;
                  }
                  li.menu-item-has-children {
                    flex: 1;
                  }
                }
              }
            }
            &.reorganize-2 {
              @media (min-width: 993px) {
                & > .conteneur {
                  ul.sub-menu.level-2 {
                    display: inline-block;
                    position: relative;
                    li.menu-item-has-children {
                      position: absolute;
                      top: 0;
                      right: 0;
                    }
                    & > li:not(.menu-item-has-children) > a {
                      margin: 0 10px 12px 10px;
                    }
                    & > li.menu-item-has-children {
                      padding-left: 15px;
                    }
                  }
                }
              }
            }
            &.submenu-with-picture {
              & > .conteneur {
                width: 600px;
                padding-bottom: 30px;
                ul.sub-menu.level-2 {
                  flex-wrap: nowrap;
                  position: relative;
                  padding-bottom: 65px;
                  gap: 8px;
                  .menu-item:not(.cta-rounded-inside) {
                    &:first-child {
                      a {
                        background-color: cc_getColor(bleu_sombre);
                      }
                    }
                    &:nth-child(2) {
                      a {
                        background-color: cc_getColor(rouge);
                      }
                    }
                    &:nth-child(3) {
                      a {
                        background-color: cc_getColor(bleu_ciel);
                      }
                    }
                    a {
                      padding: 10px;
                      position: relative;
                      border-bottom: 0;
                      border-radius: 10px;
                      overflow: hidden;
                      margin: 0;
                      height: 100px;
                      font-size: 18px;
                      line-height: 18px;
                      font-weight: 200;
                      text-transform: inherit;
                      color: cc_getColor(blanc);
                      text-shadow: 0 0 5px rgba(cc_getColor(noir), .6);
                      display: flex;
                      justify-content: center;
                      align-items: flex-end;
                      text-align: center;
                      font-family: 'montserrat_semi';
                      z-index: 1;
                      &::before {
                        content: '';
                        position: absolute;
                        width: 50px;
                        height: 100px;
                        top: 25%;
                        background-image: url('../../img/decoratifs/element-pilps-left.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        transform: translateY(-50%) rotateY(180deg);
                        right: 0;
                        z-index: 0;
                      }
                    }

                  }
                  .cta-rounded-inside {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: fit-content;
                    a {
                      border-bottom: 1px solid cc_getColor(bleu_ciel);
                      margin: 0;
                      padding: 15px;
                      color: cc_getColor(blanc);
                    }
                  }
                }
              }
            }
            &.align-right {
              & > .conteneur {
                left: unset;
                right: 30px;
              }
            }
            &.align-center {
              & > .conteneur {
                left: 50%;
                transform: translateX(-50%) !important;
                right: unset;
              }
            }
          }
          @media (min-width: 1070px) {
            &.no-link > a {
              pointer-events: none;
            }
          }
          @media (max-width: 1019px) {
            &.submenu-with-picture {
              >ul.sub-menu {
                .with-picture {
                  a {
                    .menu-media {
                      display: none;
                    }
                    .title-inside {
                      svg {
                        display: none;
                      }
                    }

                  }
                }
                .cta-rounded-inside {
                  a {
                    border-bottom: 1px solid cc_getColor(bleu_ciel);
                    margin: 0;
                    padding: 15px;
                    color: cc_getColor(blanc);
                  }
                }
              }
            }
          }
          &.pll-parent-menu-item {
            // position: absolute;
            right: 0;
            top: 0;
            // transform: translate(100%, 0);
            text-transform: uppercase;
            font-weight: 500;

            a {
              display: block;
              color: cc_getColor(
                content,
                texte
              ); //cc_getColor( content, langue );
              position: relative;
              padding: 0 0 0 10px;
              font-weight: 500;
            }

            & > a {
              // margin: 0 0 0 50px;
              margin-left: 15px;

              &::after {
                content: $chevron-down;
                display: inline-block;
                vertical-align: middle;
                // height: 0; width: 0;
                // border-left: 7px solid transparent;
                // border-right: 7px solid transparent;
                // border-top: 7px solid cc_getColor( background, fonce );
                margin: 0 0 0 5px;
              }
            }

            ul {
              position: absolute;
              right: 0;
              left: auto;
              top: calc(50% + 20px);
              z-index: 10;
              display: none;
              transform: none;
              opacity: 1;
              visibility: visible;
              background: cc_getColor(blanc);
              box-shadow: 2px 6px 13px 0 rgba(15, 38, 92, 0.2);

              &::before {
                content: none;
              }

              li {
                a {
                  padding: 10px 28px;
                }
              }

              &.visible {
                display: block;
              }
            }
          }
        }
      }
    }

    .burger {
      position: absolute;
      top: 0;
      right: 20px;
      padding: 17px 12px 16px;
      cursor: pointer;
      z-index: 3;
      display: none;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      span {
        height: 1px;
        width: 50px;
        display: block;
        background: cc_getColor(background, fonce);
        margin: 12px 0;
        transition: transform 0.75s cubic-bezier(0.4, 0.81, 0, 1);

        &:nth-child(3) {
          margin: -13px 0 12px 0;
        }
      }

      &.open {
        span {
          &:nth-child(1) {
            transform: translate(-150%, 0);
          }

          &:nth-child(4) {
            transform: translate(150%, 0);
          }

          &:nth-child(2) {
            transform: rotate(45deg);
            transition-delay: 0.2s;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
            transition-delay: 0.2s;
          }
        }
      }
    }

    .capabilites {
      @extend .row;
      @extend .background-fonce;
      cursor: pointer;

      p {
        font-size: $paragraphe;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        color: cc_getColor(blanc);
        padding: 10px 0;
        text-align: right;

        span {
          @extend .sprite;
          background-position: -343px -20px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0 0 65px;
          width: 15px;
          height: 17px;
        }
      }
    }

    &.small {
      transform: translate(0, 0%);
      padding: 5px 0;
      .logo {
        a {
          height: auto;
          width: 220px;
          img {
            height: 100%;
            width: auto;
          }
        }
      }

      .nav {
        @media (min-width: 1023px) {
          .header-top {
            height: 0;
            display: none;
          }
        }
        .header-main ul {
          & > li {
            a:not([class^="cta"]) {
              padding: 20px 0; //15px 10px;
            }
          }
        }
      }

      .burger {
        padding: 13px 12px;
      }

      & + aside.recherche {
        top: 90px;
      }
    }

    &.hidden {
      transform: translate(0, -100%);
    }
  }
}

.admin-bar nav.row {
  top: 32px;
}
