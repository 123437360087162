@mixin bouton($text_color, $hover_color, $background_color, $hover_background_color){
    position: relative;
    display: inline-block;
    text-align: center;
    font-size: 14px; //15
    padding: 12px 25px;
    border : none;
    border-radius: 60px;
    min-width: 150px;
    box-sizing: border-box;
    color: $text_color;
    background-color: $background_color;
    transition : $trans;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 0.2px;
    @media(max-width: 1200px){
      font-size: 15px;
    }
    @media(max-width: 992px){
      font-size: 15px;
      max-width: 300px;
    }
    @media(max-width: 768px){
      width: 100%;
    }
    // ?? picto
    i {
      font-weight: 100;
      vertical-align: text-top;
      padding-right: 10px;
    //   &.fas.fa-phone-volume {
    //     transform: rotate(-45deg) translate(-3px, -5px);
    //     vertical-align: initial !important;
    //     font-size: 17px;
    //     padding: 0;
    //   }
    }
    // ?? animations
    // ?? hover
    &:active, &:hover, &:focus {
      outline: 0!important;
      outline-offset: 0;
    }
    &:hover{
      background-color: $hover_background_color; //darken($hover_color,10%);
      color: $hover_color;
      text-decoration: none;
    }
    @media(max-width:768px){}
}

.wp-core-ui #editor .edit-post-visual-editor__content-area,
.wp-core-ui #editor .block-editor-block-styles__item-preview,
.text-styles {
  /*span.link-without-rollover a {
    font-family: 'Ubuntu Medium', 'Ubuntu', sans-serif;
    font-size: 25px;
    margin-bottom: 21px;
    display: block;
    border: none;
    transition: $trans;
    &:hover {
      text-decoration: none;
      // color: $texte;
    }
    @media(max-width:1400px) {
      font-size: 22px;
    }
    @media(max-width:1200px) {
      font-size: 19px;
    }
    @media(max-width:992px) {
      font-size: 18px;
    }
  }*/
  h2:not(.chapeau):not(.is-style-chapeau):not(.is-style-design-heading) {
    text-transform: uppercase;
    margin: 50px 0 30px 0;
    font-size: $titre2;
    font-weight: 600;
    &:not(.classic, .is-style-simple-heading) {
        position: relative;
        padding-bottom: 25px;
        &:after {
            content: '';
            position: absolute;
            background-color: cc_getColor(bleu_ciel);
            height: 6px;
            width: 40px;
            border-radius: 30px;
            border-top: 1px solid cc_getColor(bleu_ciel);
            border-bottom: 1px solid cc_getColor(bleu_ciel);
            top: unset;
            bottom: 0;
            left: 0;
        }
        strong {
          font-weight: 200;
        }
    }
    &.is-style-red-heading {
        &:after {
          background-color: cc_getColor(rouge);
          border-top: 1px solid cc_getColor(rouge);
          border-bottom: 1px solid cc_getColor(rouge);
        }
      
    }
    &.is-style-orange-heading {
        &:after {
            background-color: $orange;
            border-top: 1px solid $orange;
            border-bottom: 1px solid $orange;
        }
    
    }
  }
  h2.is-style-design-heading {
    font-family: 'montserrat_semi';
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 50px;
    letter-spacing: -1px;
  }

  h3:not(.titre-bloc) {
      font-size: 25px;
      margin: 0 0 30px 0;
      font-weight: 600;
  }

  h4 {
      font-weight: 500;
      color: cc_getColor(bleu_ciel);
      font-size: 20px;
      display: inline-block;
      margin: 0 0 10px 0;
      &.is-style-red-heading {
        color: cc_getColor(rouge);
      }
      &.is-style-orange-heading {
          color: $orange;
      }
  }
  p.description-equipements {
    font-size: 18px;
    letter-spacing: 1px;
    @media (min-width: 993px) {
      padding-right: 25%;
    }
  }
  p,
  span:not([class^="bouton"]):not([class^="link-without-rollover"]),
  strong,
  div {
    >a[class=""],
    >a:not([class]) {
      color: cc_getColor(bleu_ciel);
      position: relative;
      transition: $trans;
      transition: 0.5s;
      text-decoration: underline;
      &:hover {
        color: $orange;
      }
    }
  }
  ul:not(.slick-dots):not(#menu--cat-mag):not(.is-style-spaced-list),
  ul.is-style-standard-list,
  ul.is-style-red-list {
    padding-left: 22px; //30px
    margin-bottom: 1rem;
    position: relative;
    li {
      color: cc_getColor(content, texte);
      &, ul li, ul li ul li { margin-bottom: 8px; }
      ul { margin-top: 8px; }
      &:before {color: cc_getColor(bleu_ciel);}
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  ul.is-style-red-list {
    list-style: none;
    li::before {
      content: "â¢";
      color: cc_getColor(bleu_ciel);
      display: inline-block;
      font-size: 30px;
      position: absolute;
      left: 0;
    }
  }
  ol {
    list-style: none;
    counter-reset: steps;
    padding-left: 30px;
    margin-bottom: 1rem;
    li {
      counter-increment: steps;
      margin-bottom: 5px;
      list-style-type: none;
      color: cc_getColor(content, texte);
      &:last-of-type {
        margin-bottom: 0;
      }
      &:before {
        content: counter(steps) '.';
        color: cc_get;
        font-weight: 600;
        display: inline-block;
        margin-top: -1px;
        margin-right: 10px;
        margin-left: -20px;
        float: left;
      }
    }
    ol {
      li {
        &:before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 1px;
          background: cc_getColor(bleu_ciel);
          margin-right: 10px;
          margin-left: -20px;
          margin-top: 14px;
          float: left;
        }
      }
    }
  }
  .wp-block-separator.is-style-dots {
    background-image: url('../../../assets/img/backgrounds/hr.png')$i;
    height: 1px;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    &::before {
      content: inherit;
    }
  }
  .visuel-principal {
    margin-bottom: 20px;
    @media(max-width: 992px) {
      margin-bottom: 15px;
    }
  }
  .chapeau,
  .is-style-chapeau, .is-style-intro {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    color: cc_getColor(content, texte);
    margin-bottom: 30px;
    margin-top: 10px;
    @media(max-width: 1600px) {
      font-size: 21px;
    }
    @media(max-width:1400px) {
      font-size: 20px;
    }
    @media(max-width:992px) {
      font-size: 20px;
      margin-bottom: 15px;
    }
    @media(max-width:768px) {
      font-size: 18px;
    }
  }
  // Images
  figure.is-style-image-rounded {
    height: 250px; //333px;
    width: 250px;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
    img {
      position: absolute;
      top: 0; bottom:0;
      left: 0; right: 0;
    }
    @media (max-width: 1270px) {
      height: 180px;
      width: 180px;
    }
  }
  figure.is-style-image-fullwidth img {
    width: 100%;
  }
  figure.hide-mobile {
    @media(max-width:992px) {
      display: none;
    }
  }
  .wp-block-image {
    margin-bottom: 0;
  }
  .wp-block-image figure.size-full img {
    width: auto;
  }
  .wp-block-image figure.aligncenter,
  img.aligncenter {
    margin: 20px auto;
    // display: block;
  }
  img.alignright {
    margin: 20px 0 20px 20px;
  }
  img.alignleft {
    margin: 20px 20px 20px 0;
  }

  .citation,
  blockquote,
  blockquote.wp-block-quote {
    border: none;
    position: relative;
    display: block;
    width: 100%;
    font-style: italic;
    padding: 0;
    font-size: 18px;
    line-height: 28px;
    margin: 20px auto 30px;
    p:first-of-type {
      position: relative;
      text-align: left;
      padding-left: 180px;
      font-weight: 300;
      color: cc_getColor(content, texte);
      &::before {
        content: '';
        background: none;
        line-height: 0;
        letter-spacing: 0;
        color: cc_getColor(gris);
        position: absolute;
        line-height: 0;
        background: url('../../img/sprite/quotes-icon.svg') no-repeat center center;
        background-size: contain;
        width: 130px;
        height: 120px;
        left: 0; top: 0;
      }
    }
    cite,
    .wp-block-quote__citation {
      display: block;
      text-align: left;
      font-size: $paragraphe;
      font-style: normal;
      margin-left: 180px;
    }
    @media(max-width: 992px) {
      font-size: 18px;
      margin: 25px 0 30px;
      background-size: 50px;
    }
    @media(max-width:768px) {
      margin: 15px 0 20px;
      font-size: 16px;
      line-height: 23px;
      background-size: 30px;
      p:first-of-type {
        padding-left: 130px;
        &:before {
          width: 110px;
          height: 100px;
        }
      }
      cite,
      .wp-block-quote__citation {
        margin-left: 130px;
        font-size: 15px;
      }
    }
    @media(max-width:580px) {
      p:first-of-type {
        padding-left: 115px;
        &:before {
          width: 90px;
          height: 80px;
        }
      }
      cite,
      .wp-block-quote__citation {
        margin-left: 115px;
      }
    }
    @media(max-width:420px) {
      p:first-of-type {
        padding-left: 0;
        padding-top: 100px;
        text-align: center;
        &:before {
          width: 90px;
          height: 80px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      cite,
      .wp-block-quote__citation {
        margin-left: 0;
        text-align: center;
      }
    }
  }
  
  .mis-en-avant,
  .is-style-mis-en-avant {
    display: block;
    color: cc_getColor(blanc);
    position: relative;
    width: 100%;
    background: cc_getColor(content, texte);
    padding: 22px 25px;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    margin: 30px 0 30px;
    border-radius: 20px;
    >a[class=""],
    >a:not([class]) {
      color: cc_getColor(blanc);
      &:hover {
        color: cc_getColor(bleu_ciel);
      }
    }
    @media(max-width: 992px) {
      font-size: 16px;
      padding: 22px;
      margin: 30px 0 40px;
      &:before {
        left: 20px;
      }
    }
    @media(max-width:768px) {
      font-size: 15px;
      margin: 20px 0 30px;
    }
  }
  .mis-en-avant-2,
  .is-style-mis-en-avant-2 {
    display: block;
    color: cc_getColor(content, texte);
    position: relative;
    width: 100%;
    padding: 5px 25px 5px 35px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin: 30px 0 30px;
    border-radius: 20px;
    &:before {
        content: '';
        height: 100%;
        width: 15px;
        position: absolute;
        left: 0; top: 0; bottom: 0;
        border-radius: 30px;
        background-color: cc_getColor(bleu_ciel);
    }
    >a[class=""],
    >a:not([class]) {
      color: cc_getColor(blanc);
      &:hover {
        color: cc_getColor(bleu_ciel);
      }
    }
    @media(max-width: 992px) {
      font-size: 16px;
      padding: 0 0 0 30px;
      margin: 30px 0 40px;
    }
    @media(max-width:768px) {
      font-size: 15px;
      margin: 0 0 30px;
    }
  }
  .custom-accordeon,
  .is-style-custom-accordeon {
    display: block;
    color: cc_getColor(content, texte);
    position: relative;
    width: 100%;
    padding: 5px 25px 5px 35px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin: 30px 0 30px;
    border-radius: 20px;
    // &:before {
    //     content: '';
    //     height: 100%;
    //     width: 15px;
    //     position: absolute;
    //     left: 0; top: 0; bottom: 0;
    //     border-radius: 30px;
    //     background-color: cc_getColor(bleu_ciel);
    // }
    >a[class=""],
    >a:not([class]) {
      color: cc_getColor(blanc);
      &:hover {
        color: cc_getColor(bleu_ciel);
      }
    }
    @media(max-width: 992px) {
      font-size: 16px;
      padding: 0 0 0 30px;
      margin: 30px 0 40px;
    }
    @media(max-width:768px) {
      font-size: 15px;
      margin: 0 0 30px;
    }
  }
  .uppercase-small {
    text-transform: uppercase;
    font-size: .875em;
  }
  // Boutons
  a.is-style-classic,
  .wp-block-button.is-style-classic .wp-block-button__link,
  .wp-block-button:not([class^="is-style-"]) .wp-block-button__link {
    @include bouton(cc_getColor(blanc),
    $orange,
    $orange,
    transparent);
    border: 1px solid $orange;
    @media(max-width: 992px) {
      margin: 20px auto;
    }
  }
  a.is-style-bouton-bleu,
  .wp-block-button.is-style-bouton-bleu .wp-block-button__link {
    @include bouton(cc_getColor(blanc),
    cc_getColor(bleu_ciel),
    cc_getColor(bleu_ciel),
    transparent);
    border: 1px solid cc_getColor(bleu_ciel);
    @media(max-width: 992px) {
      margin: 20px auto;
    }
  }
  a.is-style-bouton-rouge,
  .wp-block-button.is-style-bouton-rouge .wp-block-button__link {
    @include bouton(cc_getColor(blanc),
    cc_getColor(rouge),
    cc_getColor(rouge),
    transparent);
    border: 1px solid cc_getColor(rouge);
    @media(max-width: 992px) {
      margin: 20px auto;
    }
    
  }
  a.is-style-bouton-outline,
  .wp-block-button.is-style-bouton-outline .wp-block-button__link {
    @include bouton(cc_getColor(gris),
    cc_getColor(blanc),
    transparent,
    cc_getColor(gris));
    border: 1px solid cc_getColor(gris);
    @media(max-width: 992px) {
      margin: 20px auto;
    }
  }
  a.is-style-bouton-dwnld,
  .wp-block-button.is-style-bouton-dwnld .wp-block-button__link {
      @include bouton(cc_getColor(blanc),
      $orange,
      $orange,
      transparent);
      border: 1px solid $orange;
      &:after {
          content: $dwnld-icon-orange;
          display: inline-block;
          width: 19px;
          filter: brightness(0) invert(1);
          margin-left: 10px;
          transition: all 0.3s cubic-bezier(1, -0.39, 0, 0.95);
          margin-top: -5px;
          transform: translateY(2px);
          line-height: 0;
      }
      @media(max-width: 992px) {
          margin: 20px auto;
      }
      &:hover:after {
          filter: brightness(1) invert(0);
          // filter: unset;
      }
  }
  a.is-style-bouton-dwnld-bleu,
  .wp-block-button.is-style-bouton-dwnld-bleu .wp-block-button__link {
      @include bouton(cc_getColor(blanc),
      cc_getColor(bleu_ciel),
      cc_getColor(bleu_ciel),
      transparent);
      border: 1px solid cc_getColor(bleu_ciel);
      position: relative;
      &:after {
          content: $dwnld-icon-orange;
          display: inline-block;
      }
      @media(max-width: 992px) {
          margin: 20px auto;
      }
  }
  span.bouton a,
  a.bouton {
    @include bouton(cc_getColor(blanc),
    cc_getColor(bleu_ciel),
    cc_getColor(bleu_ciel),
    cc_getColor(blanc));
    border: 2px solid cc_getColor(bleu_ciel);
    @media(max-width: 992px) {
      margin: 20px auto;
    }
  }
  /*
  span.bouton-fullwidth a,
  a.bouton-fullwidth {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  span.bouton-general a,
  a.bouton-general {
    @include bouton(cc_getColor(blanc),
    cc_getColor(bleu_ciel),
    cc_getColor(bleu_ciel),
    cc_getColor(blanc));
    border: 2px solid cc_getColor(bleu_ciel);
    font-size: 20px;
    padding: 10px 15px;
    @media(max-width: 992px) {
      margin: 20px auto;
    }
  }
  span.bouton-couleur a,
  a.bouton-couleur {
    @include bouton(cc_getColor(blanc),
    cc_getColor(bleu_ciel),
    cc_getColor(bleu_ciel),
    cc_getColor(blanc));
    border: 2px solid cc_getColor(bleu_ciel);
    border: 1px solid transparent;
    background-color: cc_getColor(bleu_ciel);
    transition: $trans;
    @media(max-width: 992px) {
      margin: 20px auto;
    }
    &:hover {
      color: cc_getColor(bleu_ciel);
      border: 1px solid cc_getColor(bleu_ciel);
      background-color: cc_getColor(blanc);
      // border: 1px solid transparent;
      // filter: opacity(0.9);
    }
  }
  span.bouton-dl {
    display: flex;
  }
  span.bouton-dl a,
  a.bouton-dl {
    position: relative;
    padding-left: 60px;
    transition: $trans;
    min-height: 40px;
    display: flex;
    align-items: center;
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      width: 40px;
      height: 40px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: $trans;
    }
    &::before {
      background: cc_getColor(blanc);
      border-radius: 10px;
      box-shadow: 0px 2px 10px rgba($noir, 0.08);
    }
    &::after {
      background-image: url('../../images/icon_download.png');
      background-repeat: no-repeat;
      background-position: 12px 7px;
    }
    &:hover {
      text-decoration: none;
      color: cc_getColor(bleu_ciel);
      &::before {
        background: cc_getColor(bleu_ciel);
      }
      &::after {
        background-image: url('../../images/icon_download_hover.png');
      }
    }
  }*/
  
  .legend {
    font-size: 17px;
    color: #b1b1b1;
    font-weight: 400;
    display: block;
    line-height: normal;
  }
  // Tableau
  .wp-block-table {
    table {
      @media (max-width: 768px) {
        width: 640px;
      }
    }
    figcaption {
      margin-top: 10px;
    }
  }
  // iframe
  iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #e1e1e1;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  // Custom Columns
  .wp-block-columns.small-column-centered {
    width: 80%;
    margin: 0 auto;
    @media (max-width: 992px) {
        width: 100%;
    }
  }
  // .wp-block-columns.custom-columns-center,
  // .wp-block-columns.custom-columns-center .wp-block-column {
  //   display: flex;
  //   justify-content: center;
  // }
  /*.wp-block-columns.custom-columns-red,
  .wp-block-columns.custom-columns-black {
    .wp-block-column {
      padding: 30px 15px;
      text-align: center;
      margin: 0;
      >div {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .wp-block-columns.custom-columns-red {
    .wp-block-column {
      background: cc_getColor(bleu_ciel);
      a {
        &:hover {
          color: cc_getColor(bleu_ciel);
        }
      }
    }
  }
  .wp-block-columns.custom-columns-black {
    .wp-block-column {
      background: cc_getColor(content, texte);
    }
  }*/
  // Colonnes avec images
  // .wp-block-media-text {
  //   grid-template-columns: 1fr 50% $i;
  //   .wp-block-media-text__content {
  //     padding-right: 0;
  //     @media (max-width: 768px) {
  //       padding: 15px 5px;
  //     }
  //   }
  //   &.has-media-on-the-right {
  //     grid-template-columns: 1fr 50% $i;
  //     .wp-block-media-text__content {
  //       padding-left: 0;
  //       padding-right: 8%;
  //     }
  //   }
  // }
  /* ----- Gestion media/texte ----- */
  // @media (max-width: 768px) {
  //   .wp-block-media-text.is-stacked-on-mobile {
  //     -ms-grid-columns: 100%!important;
  //     grid-template-columns: 100%!important;
  //   }
  //   .wp-block-media-text {
  //     display: grid;
  //     -ms-grid-columns: 100%!important;
  //     grid-template-columns: 100%!important;
  //     .wp-block-media-text__media {
  //       grid-column: 1;
  //       grid-row: 1;
  //     }
  //     .wp-block-media-text__content {
  //       grid-column: 1;
  //       grid-row: 2;
  //     }
  //   }
    // .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    //   -ms-grid-column: 1;
    //   grid-column: 1;
    //   -ms-grid-row: 1;
    //   grid-row: 1;
    // }
  // }
  /* ---- Gestion du colonnage ----- */
  // Comportement standard
  // @media (max-width: 992px) {
  //   .wp-block-columns:not(.custom-columns-padding) .wp-block-column {
  //     min-width: 40%;
  //   }
  // }
}

.block--bloc-contenu {
    box-shadow: 0 0px 15px rgba(0,0,0,0.2);
    max-width: 333px; //398px;
    height: 100%;
    .zone-visuel {
        position: relative;
        img {
          width: 100%;
        }
    }
    .title-tag {
        padding: 12px 25px 12px 15px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        .titre-bloc {
            font-size: 18px;
            font-weight: 600;
            margin: 0; padding: 0;
        }
    }
    .texte { margin-bottom: 0; }
    .texte p { margin-bottom: 0 $i; }
    p:empty { display: none; }

    // ?? Avec ou sans visuel
    &.with-img {
        .title-tag {
            position: absolute;
            left: 0; bottom: 25px;
            .titre-bloc {
                color: cc_getColor(blanc);
            }
        }
        .texte {
            padding: 20px;
        }
    }
    &.without-img {
        padding-top: 15px;
        padding-bottom: 15px;
        .title-tag {
            margin-top: 20px;
            background-color: cc_getColor(blanc);
            width: fit-content;
            .titre-bloc { color: cc_getColor(content, texte); }
        }
        .texte {
            padding: 20px;
        }
        .texte p { 
            color: cc_getColor(blanc);
        }
    }
    // ?? Univers de couleur du bloc
    &.univers-bleu {
        &.with-img {
            .title-tag {
                background-color: cc_getColor(bleu_ciel_bg);
            }
        }
        &.without-img {
            background-color: cc_getColor(bleu_ciel_bg);
            // .title-tag .titre-bloc { color: cc_getColor(bleu_ciel_bg); }
        }
    }
    &.univers-orange {
        &.with-img {
            .title-tag {
                background-color: $orange;
            }
        }
        &.without-img {
            background-color: $orange;
        }
    }
}

// ?? Bloc de contenu en accordÃ©on
.block--accordeon {
  margin-top: 20px;
  margin-bottom: 20px;
  .zone-titre {
    margin-top: 20px;
    border-bottom: 1px solid lightgray;
    &:first-of-type {
        margin-top: 0;
    }
    &:last-of-type {
        border: none;
    }
    &.custom-collapse {
      background-color: cc_getColor(content, texte);
      color: cc_getColor(blanc);
      border-radius: 10px;
    }
  }


  .collapse-button {
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 15px 25px; //15px 15px 15px 0;
    .navbar-toggler {
      transition: $trans;
      color: cc_getColor(blanc);
      border: none;
      background-color: transparent;
      padding: 0 $i;
      &:hover {
        cursor: pointer;
      }
      svg {
        transition: 0.2s linear;
        transform: translate(3px,0) rotate(-90deg);
        transform-origin: 50% 50%;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &.active{
      transition: $trans;
      & > .navbar-toggler svg {
          transform: translate(2px,-2px) rotate(0deg);
          transform-origin: 50% 50%;
      }
    }
  }
  .collapse-content {
    position: relative;
    background: none;
    max-height: none;
    box-shadow: none;
    top: auto;

    display: none;
    transition: $trans;

    .colmun-text {
      width: 33%;
      flex: 1;
      padding: 15px 30px 0;
      margin-bottom: 30px;
      border-right: 1px solid rgba(255,255,255,0.2);
      
      &:last-of-type {
        border-right: none;
      }
    }

    @media(max-width: 768px){
      font-size: 15px;
    }
    &.visible {
      height: auto;
      display: flex;
    }
  }

  .accordion-body {
    h3 {
      font-size: 18px $i;
      margin-bottom: 10px $i;
      color: cc_getColor(bleu_ciel);
    }
    p:empty { display: none; }
    h4,h5,h6 {
      font-size: inherit;
    }
    ul li { color: cc_getColor(blanc) $i; }
  }

}

.block--convictions {
  position: relative;
  display: flex;
  padding: 50px 0;
  width: 100%;
  margin-top: 120px;
  .visuel-container {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    width: 70%;
    .prllx-v {
      position: absolute;
      width: auto;
    }
    .visuel {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .convictions {
    position: relative;
    width: 50%;
    z-index: 1;
    .conviction {
      padding: 25px 30px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(cc_getColor(noir), .25);
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &:first-child {
        background-color: cc_getColor(rouge);
      }
      &:nth-child(2) {
        background-color: cc_getColor(bleu_ciel);
      }
      &:last-child {
        background-color: cc_getColor(bleu_sombre);
      }
      .titre {
        text-transform: initial;
        text-align: left;
        font-family: 'market_ot';
        font-size: 50px;
        color: cc_getColor(blanc);
        margin-bottom: 10px;
      }
      .conviction-content {
        p {
          color: cc_getColor(blanc);
          font-size: 18px;
          font-weight: 400;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.position-left {
    justify-content: flex-end;
    .visuel-container {
      left: 0;
      border-radius: 400px 40px 40px 40px;
      .prllx-v {
        left: 60px;
        bottom: -120px;
      }
    }
  }
  &.position-right {
    justify-content: flex-start;
    .visuel-container {
      right: 0;
      border-radius: 40px 400px 40px 40px;
      .prllx-v {
        right: 60px;
        bottom: -150px;
      }
    }
  }
}

.block--engagements {
  margin-top: 50px;
  .engagement-slide {
    height: auto;
  }
  .engagement {
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 5px 10px rgba(cc_getColor(noir), .25);
    background-color: cc_getColor(gris_100);
    max-width: 500px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    >.titre {
      text-transform: initial;
      font-weight: bold;
      padding: 30px;
      font-size: 20px;
      margin-bottom: 0$i;
      text-align: left;
      z-index: 1;
    }
    .content {
      position: absolute;
      opacity: 0;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 30px;
      background-color: cc_getColor(bleu_sombre);
      transition: $trans;
      z-index: 2;
      .titre {
        text-transform: initial;
        font-weight: bold;
        font-size: 20px;
        text-align: left;
        color: cc_getColor(blanc);
      }
      * {
        color: cc_getColor(blanc);
        font-size: 18px;
        font-weight: 400;
      }
    }
    &:hover {
      .content  {
        top: 0;
        opacity: 1;
      }
    }
  }
  .swiper-container {
    .swiper-navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      gap: 20px;
    }
    .swiper-button {
      width: 40px;
      height: 40px;
      padding: 5px; 
      border-radius: 50%;
      border: 3px solid cc_getColor(bleu_sombre);
      transition: $trans;
      svg {
        fill: cc_getColor(bleu_sombre);
        width: 100%;
        height: auto;
        transition: $trans;
      }
      &:not(.swiper-button-disabled):hover {
        background-color: cc_getColor(bleu_sombre);
        svg {
          fill: cc_getColor(blanc);
        }
      }
    }
    .pagination {
      line-height: 0;
      .swiper-pagination-bullet {
        padding: 0 5px;
        opacity: 1;
        &::before {
          width: 12px;
          height: 12px;
          background-color: rgba(cc_getColor(gris_400), .6);
          opacity: 1;
        }
        &::after {
          display: none;
        }
        &.swiper-pagination-bullet-active {
          &::before {
            background-color: cc_getColor(rouge);
          }
        }
      }
    }
  }
}

.block--esprits {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  .deco-rejoindre {
    img {
      right: 0;
      top: 50%;
      transform: translate(60%, -50%);
    }
  }
  .esprit {
    padding: 30px;
    border-radius: 20px;
    background-color: cc_getColor(bleu_sombre);
    width: calc(33.333% - 20px);
    .titre, .content * {
      color: cc_getColor(blanc);
    }
    .content {
      margin-bottom: 0;
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .titre {
    text-transform: initial;
    text-align: left;
    font-family: 'market_ot';
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 15px$i;
    position: relative;
    padding-bottom: 15px;
    margin-top: 0;
    &:after {
        content: '';
        position: absolute;
        background-color: cc_getColor(bleu);
        height: 6px;
        width: 80px;
        border-radius: 30px;
        top: unset;
        bottom: 0;
        left: 0;
    }
  }
}

.bloc-avantages {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 100px;
  .avantage {
    position: relative;
    display: flex;
    width: calc(33.333% - 20px);
    box-shadow: 0 3px 6px rgba(cc_getColor(noir), .16);
    border-radius: 10px;
    padding: 30px 30px;
    gap: 20px;
    text-align: left;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 1400px) {
      padding: 40px 15px;
      font-size: 20px;
    }
    @media(max-width: 992px) {
      line-height: 22px;
    }
    @media(max-width: 768px) {
      width: 100%;
    }
    img {
      max-width: 50px;
    }
  }
  .deco-rejoindre {
    img {
      left: 0;
      top: 0;
      transform: translate(-15%, -100%);
    }
  }
}

.block--cards {
  margin-top: 50px;
  margin-bottom: 100px;
  .card {
    display: flex;
    align-items: center;
    .slider {
      position: relative;
      width: 44%;
      .slider-card {
        position: relative;
        overflow: hidden;
        border-radius: 250px 50px 50px 50px;
        .visuel-card {
          .visuel {
            width: 100%;
          }
        }
      }
      .pagination-slider-card {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        display: flex;
        .swiper-pagination-bullet {
          padding: 0 5px;
          opacity: 1;
          &::before {
            content: '';
            width: 12px;
            height: 12px;
            display: block;
            border-radius: 10px;
            background-color: cc_getColor(gris_400);
            opacity: 1;
          }
          &::after {
            display: none;
          }
          &.swiper-pagination-bullet-active {
            &::before {
              background-color: cc_getColor(rouge);
            }
          }
        }
        &.unslider {
          display: none;
        }
      }
    }
    .content-container {
      width: 56%;
      padding-left: 60px;
      .titre {
        text-transform: initial;
        text-align: left;
        font-family: 'market_ot';
        font-size: 36px;
        margin: 0 0 20px$i;
        position: relative;
        padding-bottom: 10px;
        &:after {
            content: '';
            position: absolute;
            background-color: cc_getColor(rouge);
            height: 4px;
            width: 70px;
            border-radius: 30px;
            top: unset;
            bottom: 0;
            left: 0;
        }
      }
      .content p {
        font-size: 18px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .slider-card {
        border-radius: 50px 250px 50px 50px;
      }
      .content-container {
        text-align: right;
        padding-left: 0;
        padding-right: 60px;
        .titre {
          text-align: right;
          &:after {
            left: initial;
            right: 0;
          }
        }
      }
  
    }
  }
  .deco-rejoindre {
    img {
      &:first-child {
        top: 0;
        transform: translate(15%, -50%);
        right: 0;
      }
      &:last-child {
        left: 0;
        transform: translate(-15%, 50%);
        bottom: 0;
      }
    }
  }
}

.bloc-mediaTextQuotes {
  .mediaTextQuote {
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 70px;
    .visuel-container {
      overflow: hidden;
      z-index: 0;
      width: 60%;
      border-radius: 50px 350px 50px 50px;
      .prllx-v {
        position: absolute;
        width: auto;
      }
      .visuel {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-container {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%)$i;
      width: 50%;
      z-index: 2;
      .wrapper {
        background-color: cc_getColor(blanc);
        border-radius: 10px;
        box-shadow: 20px 20px 30px rgba(cc_getColor(noir), .16);
        overflow: hidden;
      }
      .content {
        background-color: cc_getColor(bleu_ciel);
        padding: 35px;
        .titre {
          text-transform: initial;
          text-align: left;
          font-family: 'market_ot';
          font-size: 50px;
          line-height: 50px;
          color: cc_getColor(blanc);
          margin-bottom: 0;
          letter-spacing: -1px;
        }
        .sous-titre {
          font-size: 28px;
          color: cc_getColor(blanc);
          position: relative;
          padding-bottom: 15px;
          font-weight: 300;
          &:after {
            content: '';
            position: absolute;
            background-color: cc_getColor(bleu_sombre);
            height: 5px;
            width: 50px;
            border-radius: 30px;
            top: unset;
            bottom: 0;
            left: 0;
          }
        }
        p:not(.titre, .sous-titre) {
          color: cc_getColor(blanc);
          margin-bottom: 0;
          font-size: 18px;
        }
      }
      .quote {
        padding: 30px 30px 30px 100px;
        position: relative;
        p {
          font-size: 24px;
          font-style: italic;
        }
        img {
          position: absolute;
          left: 30px;
          top: 30px;
          max-width: 40px;
        }
      }
    }
    &:nth-child(odd) {
      justify-content: flex-start;
      .visuel-container {
        border-radius: 350px 50px 50px 50px;
      }
      .content-container {
        left: initial;
        right: 30px;
      }
    }
  }
  .deco-rejoindre {
    img {
      right: 0;
      bottom: 0;
      transform: translate(60%, 50%);
    }
  }
}

.block--mosaique {
  padding: 50px 0 80px;
  position: relative;
  margin: 0 -30px;
  &:before {
    content: '';
    background-color: cc_getColor(gris_100);
    width: 200%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -2;
  }
  &:last-child {
    margin-bottom: -50px;
  }
  .mosaique {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .tile {
      width: calc(25% - 9px);
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      .titre {
        position: absolute;
        top: 100%;
        background-color: cc_getColor(bleu_sombre);
        text-transform: initial;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        transition: $trans;
        width: 100%;
        p {
          position: relative;
          font-size: 24px;
          color: cc_getColor(blanc);
          margin: 0;
          font-weight: 400;
          &:before, &::after {
            content: '';
            position: absolute;
            background-color: cc_getColor(bleu_ciel);
            height: 3px;
            width: 100px;
            border-radius: 30px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:before {
            top: -20px;
          }
          &::after {
            bottom: -20px;
          }

        }
      }
      &:hover {
        .titre {
          transform: translateY(-100%);
        }
      }
    }
  }
}

.block--services {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 70px;
  .services {
    width: calc(33.3333% - 26.6666px);
    box-shadow: 0 3px 6px rgba(cc_getColor(noir), .16);
    padding: 30px;
    border-radius: 20px;
    .title {
      border-bottom: 1px solid cc_getColor(bleu);
      font-size: 24px;
      font-weight: 500;
      padding-bottom: 15px;
      position: relative;
      padding-right: 100px;
      img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 50px;
      }
    }
    ul li {
      font-weight: 500;
      font-size: 22px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &::marker {
        color: cc_getColor(bleu);
      }
    }
  }
}

.block--metier {
  margin-bottom: 50px;
  padding: 0 50px;
  .metier {
    display: flex;
    box-shadow: 0 10px 10px rgba(cc_getColor(noir), .1);
    border-radius: 20px;
    background-color: cc_getColor(blanc);
    .media-container {
      width: 55%;
      background-color: cc_getColor(rouge);
      padding: 40px;
      color: cc_getColor(blanc)$i;
      border-radius: 20px 0 0 20px;
      .visuel {
        width: 100%;
        border-radius: 20px;
        max-height: 400px;
        margin-bottom: 30px;
      }
      p {
        font-size: 20px;
        font-weight: 700;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .texte-container {
      width: 45%;
      padding: 50px 50px 50px 80px;
      border: 1px solid cc_getColor(gris_300);
      border-left: 0;
      border-radius: 0 20px 20px 0;
      .title {
        margin: 0 0 30px 0;
        font-size: 32px;
        font-weight: 600;
        position: relative;
        padding-bottom: 25px;
        &:after {
          content: '';
          position: absolute;
          background-color: cc_getColor(rouge);
          height: 6px;
          width: 40px;
          border-radius: 30px;
          top: unset;
          bottom: 0;
          left: 0;
        }
      }
      .missions {
        padding-left: 0$i;
        margin-bottom: 0$i;
        li {
          font-size: 18px;
          font-weight: 400;
          padding-bottom: 8px;
          margin-bottom: 8px;
          &:not(:last-child) {
            border-bottom: 1px solid cc_getColor(gris_300);
          }
        }
      }
      .cta-orange {
        font-size: 16px;
        font-weight: 500;
        padding: 15px 25px;
        text-transform: inherit;
      }
    }
    &.position-right {
      flex-direction: row-reverse;
      .media-container {
        border-radius: 0 20px 20px 0;
      }
      .texte-container {
        padding: 50px 80px 50px 50px;
        border-radius: 20px 0 0 20px;
        border: 1px solid cc_getColor(gris_300);
        border-right: 0;
      }
    } 
    .bg-modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(cc_getColor(noir), .1);
      z-index: 1000;
      overflow: hidden;
    }
    .modal {
      position: fixed;
      width: 750px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1001;
      .btn.close {
        position: absolute;
        top: -50px;
        right: -50px;
        background-color: transparent;
        border: 0;
        padding: 0;
        z-index: 2;
        transition: $trans;
        svg {
          fill: cc_getColor(bleu);
          width: 40px;
          height: auto;
        }
        &:hover {
          svg {
            fill: cc_getColor(bleu_sombre);
          }
        }
      }
      .video { 
        overflow:hidden; 
        padding-bottom:56.25%; 
        position:relative; 
        height:0;
        width: 100%;
      }
      .video iframe {
        left:0; 
        top:0; 
        height:100%;
        width:100%;
        position:absolute;
        margin: 0;
      }
    }
    .bg-modal,
    .modal {
      display: none;
      &.open {
        display: block;
      }
    }
  }
  .deco-rejoindre {
    img {
      display: none;
    }
  }
  &:nth-child(1 of .block--metier) {
    .deco-rejoindre {
      img {
        &:first-child {
          bottom: 0;
          left: 50%;
          transform: translate(-160%, 35%);
          display: block;
        }
      }
    }
  }
  &:nth-last-child(1 of .block--metier) {
    .deco-rejoindre {
      img {
        &:last-child {
          top: 0;
          right: 0;
          transform: translateX(35%);
          display: block;
        }
      }
    }
  }
}

.block--steps {
  display: flex;
  flex-wrap: wrap;
  row-gap: 120px;
  column-gap: 25px;
  padding-top: 50px;
  margin-bottom: 100px;
  .step {
    position: relative;
    counter-increment: count 1;
    width: calc(33.33% - 16.66666px);
    padding: 80px 30px 30px;
    box-shadow: 0 3px 6px rgba(cc_getColor(noir), .16);
    border-radius: 20px;
    background-color: cc_getColor(blanc);
    &.full-size {
      width: 100%;
      padding: 60px 30px 30px;
      &:after {
        content: '';
        background-image: url('../../../assets/img/arrow.png')$i;
        width: 281px;
        height: 276px;
        position: absolute;
        top: -200px;
        right: 100px;
      }
      .title {
        text-align: left;
        min-height: inherit;
        display: block;
      }
  
      .content-container {
        display: flex;
        gap: 40px;
        .content {
          width: calc(50% - 20px);
          text-align: left;
        }
      }
    }
    &:before {
      content: counter(count, decimal);
      font-size: 50px;
      font-weight: 900;
      width: 115px;
      height: 115px;
      position: absolute;
      left: 50%;
      top: 0;
      background-color: cc_getColor(blanc);
      transform: translate(-50%, -50%);
      border: 10px solid cc_getColor(bleu);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      border-radius: 100px;
    }
    
    .title {
      text-transform: initial;
      text-align: center;
      font-family: 'market_ot';
      font-size: 36px;
      margin: 0 0 20px$i;
      position: relative;
      line-height: 42px;
      min-height: 136px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content-container {
      .content {
        text-align: center;
        font-size: 20px;
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

  }
  .deco-rejoindre {
    img {
      top: 20%;
      left: 0;
      transform: translateX(-20%);
    }
  }
}

.bloc-avantages,
.block--esprits,
.block--cards,
.bloc-mediaTextQuotes,
.block--metier,
.block--steps {
  position: relative;
}
  
.deco-rejoindre {
  position: absolute;
  width: 100vw;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  flex-direction: column;
  align-content: space-around;
  z-index: -1;
  img {
    position: absolute;
  }
}