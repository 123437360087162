.footer {
    // .row {
        // &::before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     left: 50%;
        //     top: 0;
        //     width: 100vw;
        //     height: 100%;
        //     z-index: -1;
        //     box-shadow: 2px -4px 19px 0 rgba(15, 38, 92, 0.1);
        //     transform: translate(-50%, 0);
        // }
	// }

	h3 {
		font-size: $titre3;
		margin: 0 0 40px 0;
	}

	p {
		font-size: 17px;
		color: cc_getColor( blanc );
		a {
			color: cc_getColor( blanc );
			font-weight: 400;
			margin: 15px auto 0;
			display: inline-block;
			&.cta { padding: 10px 30px; }
			&.more {
				font-weight: 500;
				font-size: $label;
			}
		}
	}
	.column-social {
		p { text-align: center; }
	}
	.social {
		text-align: center;
		a {
			@extend .sprite;
			background-position: -135px -67px;
			display: inline-block;
			height: 37px;
			width: 37px;
			margin: 0 0 0 10px;
			transition: all 0.2s ease;
			&:first-child {
				margin: 0;
			}
			&.twitter {	background-position: -79px -67px; }
			&.facebook { background-position: -191px -67px;	}
			&.youtube {	background-position: -22px -67px; }
			&:hover {
				// opacity: 0.7;
				filter: brightness(0.8);
				transform: translateY(-2px);
			}
		}
	}

	/*form {
		margin: 35px 0 50px 0;
		display: flex;
		input {
			border: 1px solid cc_getColor( background, fonce );
			padding: 12px 20px;
			font-size: $label;
			color: cc_getColor( content, texte );
			flex: 1 0 auto;

			&::placeholder {
				color: cc_getColor( content, texte );
				font-style: italic;
				font-weight: 300;        			
			}
		}

		button {
			background: cc_getColor( background, fonce );
			padding: 14px 22px;
			color: cc_getColor( blanc );
			cursor: pointer;
			outline: none;
		}
	}*/

	.prefooter {
		@extend .background-fonce;
		align-items: center;
		&:after {
			background-image: url('../../img/decoratifs/element-footer.png');
			background-repeat: no-repeat;
			background-position: 0 55px; //32px;
		}
		padding-top: 60px;
		padding-bottom: 60px;
		// @media (max-width: 992px) {
		// 	padding-top: 50px;
		// 	padding-bottom: 50px;
		// }
		strong { font-weight: 500; opacity: 1; }
		p { font-size: 18px; }
		p:not(:last-child) { margin-bottom: 15px; }
		p.contact { margin-bottom: 1rem; }
	}
	.nav {
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
		ul {
			li {
				display: inline-block;
				vertical-align: top;
				a {
					display: block;
					padding: 0 25px;
					color: #818181;
					font-size: 16px;
					position: relative;
					transition: all 0.3s ease;
					&::after {
						content: '';
						display: block;
						background: #818181; //cc_getColor( content, texte );
						height: 20px;
						width: 1px;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translate(0, -50%);
					}
				}
				&:last-child {
					a {
						padding: 0 0 0 25px;

						&::after {
							content: none;
						}
					}
				}
			}
		}
	}

	.news {
		.logo {
			text-align: right;
			display: flex;
			justify-content: flex-end;
			margin-bottom: 20px;
			img {
				width: 220px;
			}
		}
		p {
			text-align: right;
			a {
				display: inline-block;
				vertical-align: middle;
				margin: -5px 0 0 15px;
			}
			&.member {
				font-family: $serif;
				a {
					img {
						height: 43px;
						width: auto;
					}
				}
			}
		}
	}
}