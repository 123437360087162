// Hovers

@media only screen and (min-width: 1024px) {

	nav {

		&.row {

			.nav .header-main {
				
				ul {

					& > li {

						&:hover {
							& > a:after { width: 100%; }
							.conteneur {
								transform: translate(0, 0%);
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
				ul > li > .conteneur ul.sub-menu.level-2 li:not(.no-link):not(.menu-item-has-children) a:hover {
					color: $orange;
					transform: translateX(5px);
				}
				ul > li.submenu-with-picture > .conteneur ul.sub-menu.level-2 li:not(.no-link):not(.menu-item-has-children):not(.cta-rounded-inside) a:hover {
					color: cc_getColor(blanc);
					transform: translateX(5px);
				}
			}
			.nav {
				
				ul {

					& > li {

						&:hover {
							ul {
								transform: translate(0, 0%);
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
		}
	}

	.home {

		header {

			&.pushs {

				& > div {

					> a {

						&:hover {

							img {
								transform: scale(1.1);
							}
						}
					}
				}
			}
		}

		section {
			&.actualites {
				.slider-actualites {
					.wrapper {
						.content {
							&:hover {
								transform: translateY(-10px);
								figure {
									img {
										transform: scale(1.1);
									}
								}
								// figcaption {
								// 	bottom: -20px;
								// }
							}
						}
					}
				}
			}
		}
	}

	.certifications {

		.full {

			a {

				&:hover {
					
					.hover {
						opacity: 1 !important;

						& > div {

							&::before {
								transform: translate(0, 0%) !important;
							}
						}
					}
				}
			}
		}
	}

	.left-side:hover .left-shape { transform: translateY(30px); /*transition: transform 0.4s ease-in-out;*/ }
	.right-side:hover .right-shape { transform: translateY(-30px); /*transition: transform 0.4s ease-in-out;*/ }

	.cta {
		&:hover {
			color: cc_getColor(blanc);
			background-color: cc_getColor(content, texte);
			&::after {
				width: 100%; //calc(100% - 2px);
				// opacity: 0;
			}
		}
	}
	.cta-rounded {
		&:hover {
			color: cc_getColor(bleu_ciel);
			// border: 1px solid cc_getColor(blanc);
			background-color: cc_getColor(blanc);
		}
	}
	.cta-orange {
		&:hover {
			color: cc_getColor(orange);
			// border: 1px solid cc_getColor(blanc);
			background-color: cc_getColor(blanc);
		}
	}
	.left-side:hover .cta-service,
	.right-side:hover .cta-service,
	.cta-service:hover  {
		&.service--1 {
			color: $orange;
			// border-color: $orange;
			background-color: cc_getColor(blanc);
			&:after { background-color: transparentize($orange, 0.4); }
		}
		&.service--2 {
			color: cc_getColor(bleu_ciel);
			// border: 1px solid cc_getColor(bleu_ciel);
			background-color: cc_getColor(blanc);
			&:after { background-color: transparentize(cc_getColor(bleu_ciel), 0.4); }
		}
	}
	.cta-clair, .cta-soft {
		&:hover {
			color: cc_getColor(content, texte); //cc_getColor(blanc);
			&::after {
				width: 100%;
				// opacity: 0;
			}
		}
	}

	.more {

		&:hover {
		    // letter-spacing: .7px;
			color: $orange;
		}
	}

	.page-template-metiers section.row .savoir-faire .savoir-container .savoir:hover .img img,
	.blog section.row > div .news .content:hover figure img,
	.category section.row>div .news .content:hover figure img,
	.post-type-archive-event section.row .event:hover .img img {
		transform: scale(1.1);
	}
	.page-template-expertise {
		#quick-nav {
			ul {
				li {
					a:hover {
						color: cc_getColor(blanc);
						background-color: cc_getColor(bleu_ciel);
						&.orange-item { background-color: $orange; }
						&.red-item { background-color: cc_getColor(rouge); }
					}
				}
			}
		}
	}
}