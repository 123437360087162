@font-face {
    font-family: 'market_ot';
    src: url('../../fonts/MarketOT/MarketOT.eot');
    src: url('../../fonts/MarketOT/MarketOT.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/MarketOT/MarketOT.woff2') format('woff2'),
         url('../../fonts/MarketOT/MarketOT.woff') format('woff'),
         url('../../fonts/MarketOT/MarketOT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'market_ot_medium';
    src: url('../../fonts/MarketOT/MarketOT-CondMedium.eot');
    src: url('../../fonts/MarketOT/MarketOT-CondMedium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/MarketOT/MarketOT-CondMedium.woff2') format('woff2'),
         url('../../fonts/MarketOT/MarketOT-CondMedium.woff') format('woff'),
         url('../../fonts/MarketOT/MarketOT-CondMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'market_ot_bold';
    src: url('../../fonts/MarketOT/MarketOT-Bold.eot');
    src: url('../../fonts/MarketOT/MarketOT-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/MarketOT/MarketOT-Bold.woff2') format('woff2'),
         url('../../fonts/MarketOT/MarketOT-Bold.woff') format('woff'),
         url('../../fonts/MarketOT/MarketOT-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserrat';
    src: url('../../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat_semi';
    src: url('../../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat_bold';
    src: url('../../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



