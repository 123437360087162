@use 'sass:math';
.row {
  width: 100%;
  max-width: 1048px;
  margin: 0 auto;
  font-size: 0;
  padding: 0 (math.div($column-gap, 2));
}

.row-1200 {
  max-width: 1200px;
}

.row-1300 {
  max-width: 1300px;
}

.row-1400 {
  max-width: 1400px;
}

.row-1600 {
  max-width: 1600px;
}

.line {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

[class^="col-"] {
  display: inline-block;
  vertical-align: top;
  padding: 0 (math.div($column-gap, 2));
}

.col-0 {
  display: none;
}

@for $i from 0 through $column-count {
  .col-#{$i} {
    width: percentage(math.div($i, $column-count));
  }
  .offset-#{$i} {
    margin-left: percentage(math.div($i, $column-count));
  }
}

@for $i from 1 through $column-count {
  @each $key,
  $value in $breakpoints {
    @media only screen and (max-width: $value) {
      .#{$key}-col-#{$i} {
        width: percentage(math.div($i, $column-count));
      }
      .#{$key}-offset-#{$i} {
        margin-left: percentage(math.div($i, $column-count));
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .s-offset-0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .xs-offset-0 {
    margin-left: 0;
  }
}

#get-grid {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
}

#get-grid>div {
  margin: 0 (math.div($column-gap, 2));
  position: relative;
  width: calc(100% - #{$column-gap});
}

#get-grid>div>span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(239, 164, 84, 0.51);
  display: block;
}